import React, { useState, ReactNode, SetStateAction, useEffect } from 'react';
import { Row } from 'reactstrap';
import { globalStyles, Spacing } from 'rootstrap/global-styles';
import { buttonContents, Size, SteppedFormSuccessButton } from '../button/styles';
import { Stepper } from '../stepper/stepper';
import { FullscreenModal } from './fullscreen-modal';
import styled from 'styled-components';
import { devices } from 'rootstrap/global-styles/devices';
import { ProductModule } from 'product-modules/domain/product-module';
import { useSiteConfigContext } from 'style-context';
import { ApiError } from 'shared/api';
import { usePromise } from 'shared/hooks/promise';
import { useEmbedSessionContext } from '../tabs/hooks/embed-session-context';
import { PrefillValues } from 'shared/domain/prefill-values';
import { scrollTo } from 'rootstrap/components/forms/new-fields/utils';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { ManagementStepKeys } from 'policy-management/policy-management-scene';
import { ProgressBarPremiumDisplay } from '../progress-bar/progress-bar';
import { MixpanelStepNames } from 'context/mix-pannel-context';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

export enum DefaultStepKeys {
  ScreeningQuestions = 'Screening Questions',
  Quote = 'Quote',
  PrePersonalDetailsConsent = 'Pre personal details consent',
  PersonalDetails = 'Personal Details',
  AdditionalDetails = 'Additional details',
  Beneficiaries = 'Beneficiaries',
  PrePaymentDetailsConsent = 'Pre payment details consent',
  Payment = 'Payment',
  PolicyDetails = 'Policy details',
  PolicyView = 'Policy view',
  ManageBeneficiaries = 'Manage beneficiaries',
  ManageClaims = 'Manage claims',
  UpdatePolicy = 'Update policy',
  CancelPolicy = 'Cancel policy',
  ManagePaymentMethod = 'Manage payment method',
}

export interface Step<StepKey> {
  key: SetStateAction<StepKey | undefined>;
  stepName: DefaultStepKeys;
  onBackClick?: () => void | Promise<void>;
  onNextClick: () => void | Promise<void>;
  title?: string;
  description: string | undefined;
  secondaryButton?: {
    icon: ReactNode | undefined;
    enabled: boolean;
    onBackClicked: () => void;
  };
  continueButtonProps?: {
    disabled?: boolean;
    isLoading?: boolean;
    isNextButtonHidden?: boolean;
    nextButtonText?: string;
  };
  body: () => ReactNode;
}

export interface SteppedFullScreenModalComponentParams {
  prefillValues: PrefillValues | undefined;
  onNextCompleted: () => void;
}

export const SteppedFullscreenModal = <StepKey,>(params: {
  title: string;
  productModule?: ProductModule;
  onClose: () => void;
  getSteps: (params: {
    setCurrentStepKey: (currentStepKey: SetStateAction<StepKey | undefined>) => void;
    setPriorStepKey: (priorStepKey: SetStateAction<StepKey | undefined>) => void;
    getPriorStepKey: () => SetStateAction<StepKey | undefined>;
    onNextCompleted: (() => void | Promise<void>) | undefined;
    onBackCompleted: (() => void | Promise<void>) | undefined;
  }) => Step<StepKey>[];
  error?: ApiError | undefined;
  finalStepButtonContent: string;
  footer: (stepKey: StepKey | undefined) => JSX.Element;
  CustomActionBarContent: (step: SetStateAction<StepKey | undefined>) => JSX.Element;
  headingSplashSteps: IssuingSceneStepKeys[] | ManagementStepKeys[];
  quotePackageDisplaySteps: IssuingSceneStepKeys[] | ManagementStepKeys[];
  onNextClickedRef: React.MutableRefObject<any>;
  isLoading: boolean;
  currentStepKey?: SetStateAction<StepKey | undefined>;
  disableNavbar?: boolean;
  firstStep: IssuingSceneStepKeys | undefined;
  premiumDisplay: ProgressBarPremiumDisplay | undefined;
}) => {
  const { embedSessionId, loggedImpressions, setLoggedImpressions } = useEmbedSessionContext();

  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { environment } = embedParams;
  const {
    CustomActionBarContent,
    finalStepButtonContent,
    footer,
    getSteps,
    onClose,
    title,
    isLoading,
    onNextClickedRef,
    headingSplashSteps,
    quotePackageDisplaySteps,
    disableNavbar,
    firstStep,
    premiumDisplay,
  } = params;
  const [currentStepKeyState, setCurrentStepKey] = useState<StepKey>();
  const [priorStepKey, setPriorStepKey] = useState<SetStateAction<StepKey | undefined>>();

  const getPriorStepKey = () => priorStepKey;
  const onNextCompleted = async () => {
    scrollTo({
      scrollToId: 'form-overlay-content-wrapper',
      containerId: 'form-overlay-content-wrapper',
      duration: 0,
    });
    setPriorStepKey(steps[currentStepIndex].key);
    setCurrentStepKey(steps[currentStepIndex + 1].key);
  };

  useEffect(() => {
    setCurrentStepKey(params.currentStepKey);
  }, [params.currentStepKey]);

  const onBackCompleted = () => {
    scrollTo({
      scrollToId: 'form-overlay-content-wrapper',
      containerId: 'form-overlay-content-wrapper',
      duration: 300,
    });
    setPriorStepKey(steps[currentStepIndex].key);
    setCurrentStepKey(steps[currentStepIndex - 1].key);
  };

  const steps = getSteps({ setCurrentStepKey, setPriorStepKey, getPriorStepKey, onNextCompleted, onBackCompleted });

  steps.forEach((step) => {
    // do not leave like this
    if ((step as any).getHooks) {
      (step as any).getHooks();
    }
  });

  if (steps.length === 0) {
    throw new Error('Steps must have at least one element');
  }

  const currentStepKey = currentStepKeyState || steps[0].key;
  const currentStep = steps.find(({ key }) => currentStepKey === key);
  const currentStepIndex = steps.findIndex(({ key }) => currentStepKey === key);

  if (!currentStep) {
    throw new Error('Step not found');
  }

  const isNextButtonHidden = currentStep.continueButtonProps?.isNextButtonHidden;
  const Footer = footer(currentStepKeyState);

  const { continueButtonProps } = currentStep;

  return (
    <FullscreenModal
      currentStep={currentStep}
      headingSplashSteps={headingSplashSteps}
      quotePackageDisplaySteps={quotePackageDisplaySteps}
      title={title}
      onClose={onClose}
      leftBody={<LeftBody />}
      navBar={
        !disableNavbar && firstStep ? (
          <Stepper
            steps={steps.map((step) => ({
              title: step.title || '(No step title defined)',
              key: step.key,
              description: step.description || '',
            }))}
            firstStep={firstStep}
            setCurrentStepKey={(currentStepKey) => setCurrentStepKey(currentStepKey)}
            setPriorStepKey={(key) => setPriorStepKey(key)}
            activeStepKey={currentStep.key}
            priorStepKey={priorStepKey}
            premiumDisplay={premiumDisplay}
          />
        ) : undefined
      }
      body={<>{currentStep.body()}</>}
      actionBar={
        <>
          <Row style={{ width: '100%', marginLeft: 0, marginRight: 0 }}>{CustomActionBarContent(currentStepKey)}</Row>
          <Row
            style={{
              width: '100%',
              marginLeft: 0,
              marginRight: 0,
              marginTop: 8,
              marginBottom: isNextButtonHidden ? `calc(-${globalStyles.spacing.default} - 8px)` : 40,
            }}
          >
            <SuccessButtonWrapper>
              <div
                ref={onNextClickedRef}
                onClick={(e) => {
                  if (!continueButtonProps?.disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    currentStep.onNextClick();
                  }
                }}
              />
              <SteppedFormSuccessButton
                id='stepper-next'
                siteConfig={siteConfig}
                size={Size.lg}
                {...continueButtonProps}
                style={isNextButtonHidden ? { display: 'none' } : {}}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  if (!continueButtonProps?.disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    scrollTo({
                      scrollToId: 'form-overlay-content-wrapper',
                      containerId: 'form-overlay-content-wrapper',
                      duration: 0,
                    });
                    currentStep.onNextClick();
                  }
                }}
              >
                {buttonContents({
                  isLoading: continueButtonProps?.isLoading || isLoading,
                  children:
                    currentStepIndex === steps.length - 1
                      ? finalStepButtonContent
                      : continueButtonProps?.nextButtonText
                      ? continueButtonProps?.nextButtonText
                      : 'Next',
                })}
              </SteppedFormSuccessButton>
            </SuccessButtonWrapper>
          </Row>
        </>
      }
      footer={Footer}
    />
  );
};

const SuccessButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  margin-top: ${globalStyles.spacing.default};
`;

const LeftBody = styled.div`
  margin-left: 25px;
  padding-top: 20px;
`;

export const StageDescription = styled.p`
  font-size: 16px;
  margin-bottom: ${Spacing.default};

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const getMixpanelStepFromStepKeyMapping = (stepKey: IssuingSceneStepKeys) => {
  const mixpanelStepFromStepKeyMapping = {
    [IssuingSceneStepKeys.ScreeningQuestions]: MixpanelStepNames.ScreeningQuestions,
    [IssuingSceneStepKeys.QuoteInput]: MixpanelStepNames.QuoteStep,
    [IssuingSceneStepKeys.QuoteInputMultiStep1]: MixpanelStepNames.SplitQuoteStep1,
    [IssuingSceneStepKeys.QuoteInputMultiStep2]: MixpanelStepNames.SplitQuoteStep2,
    [IssuingSceneStepKeys.PrePersonalDetailsConsent]: MixpanelStepNames.ConsentPrePersonalDetails,
    [IssuingSceneStepKeys.PersonalDetails]: MixpanelStepNames.PersonalDetails,
    [IssuingSceneStepKeys.Application]: MixpanelStepNames.Application,
    [IssuingSceneStepKeys.Beneficiaries]: MixpanelStepNames.Beneficiaries,
    [IssuingSceneStepKeys.PrePaymentConsent]: MixpanelStepNames.ConsentPrePayment,
    [IssuingSceneStepKeys.Payment]: MixpanelStepNames.Payment,
  };

  return mixpanelStepFromStepKeyMapping[stepKey];
};
