import get from 'lodash/get';
import {
  PrefillAction,
  RootSchemaFormInputFieldDisplayProperties,
  StandAloneInputFieldDisplayProperties,
} from './input-field';
import { scroller } from 'react-scroll';
import { DeepMap, FieldError } from 'react-hook-form';
import { debounce } from 'utils';
import { JSONObject } from 'shared/utils';

interface GetFieldErrorParams {
  isTouched: boolean;
  errors: DeepMap<any, FieldError> | undefined;
  name: string;
}

export const getFieldError = (params: GetFieldErrorParams) => {
  const { errors, isTouched, name } = params;

  return isTouched ? get(errors, name)?.message : undefined;
};

export const validatePrefillValue = (params: {
  prefillValue: any;
  validate?: (i: any) => string | undefined;
  options: { label: string; value: any }[] | undefined;
}) => {
  const { prefillValue, validate, options } = params;
  if (!prefillValue) {
    return false;
  }

  const validationPassed = prefillValue && validate && validate(prefillValue) ? false : true;

  return options
    ? options.find((option: { value: any; label: string }) => option.value === prefillValue) && validationPassed
    : validationPassed;
};

export const doesValueRepresentBoolean = (prefillValue: any) => {
  return [true, false, 'true', 'false'].includes(prefillValue);
};

export const scrollTo = (params: { scrollToId: string; containerId: string; duration: number }) => {
  const { scrollToId, containerId, duration } = params;
  scroller.scrollTo(scrollToId, {
    duration,
    delay: 200,
    containerId: containerId, //'form-overlay-content-wrapper',
    smooth: false,
    offset: -60,
  });
};

export const setFormFocusOrBlur = (params: {
  scrollToId: string;
  disableScrollToElement?: boolean;
  isFirstElement?: boolean;
  activeElement: ActiveElement;
  nextButtonFocus?: boolean;
}) => {
  const { scrollToId, disableScrollToElement, isFirstElement, activeElement, nextButtonFocus } = params;

  const isActive = activeElement.elementId === scrollToId;
  const nextActiveElement = document.getElementById(scrollToId);

  if (!disableScrollToElement) {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    if (isActive && !disableScrollToElement && !isFirstElement) {
      debounce(
        'scroll-debounce',
        () => {
          scroller.scrollTo(`${scrollToId}-form-group`, {
            duration: 250,
            offset: !isMobile ? -200 : 0,
            delay: 0,
            containerId: 'form-overlay-content-wrapper',
            smooth: true,
          });
          if (nextButtonFocus) {
            return document.getElementById(`stepped-form-next-button-${scrollToId}`)?.focus();
          }
          return nextActiveElement?.focus();
        },
        isMobile ? 250 : 80,
      );
    }
    if (isActive && isFirstElement) {
      debounce(
        'scroll-debounce',
        () => {
          scrollTo({
            scrollToId: 'form-overlay-content-wrapper',
            containerId: 'form-overlay-content-wrapper',
            duration: 0,
          });

          if (nextButtonFocus) {
            return document.getElementById(`stepped-form-next-button-${scrollToId}`)?.focus();
          }
          return nextActiveElement?.focus();
        },
        isMobile ? 250 : 80,
      );
    }
  }
};

interface PrefillBehavior {
  prefillValue: boolean | string | number | undefined | JSONObject;
  validate: ((value: any | undefined) => string | undefined) | undefined;
  prefillAction: PrefillAction | undefined;
  options: { label: string; value: any }[] | undefined;
}
export const prefillBehavior = (params: PrefillBehavior) => {
  const { prefillValue, validate, prefillAction, options } = params;
  const prefillIsValid = validatePrefillValue({ prefillValue, validate, options });
  const disabledFromPrefill = prefillAction === 'disabled' && prefillIsValid ? true : false;
  const hiddenFromPrefill = prefillAction === 'hidden' && prefillIsValid ? { display: 'none' } : {};

  return {
    disabledFromPrefill,
    hiddenFromPrefill,
  };
};

export interface ActiveElement {
  elementId: string | undefined;
}

export const getNextComponentId = (
  params: RootSchemaFormInputFieldDisplayProperties | StandAloneInputFieldDisplayProperties,
) => {
  if (isRootSchemaFormInput(params)) {
    const { schema: getSchema, index, key } = params;
    const schema = (getSchema && getSchema()) || [];
    const indexInSchema = schema.findIndex((component) => component.key === key);
    return (getSchema && index !== undefined && (schema.slice(indexInSchema + 1)[0]?.key as string)) || '';
  }
  return params.nextComponentName;
};

function isRootSchemaFormInput(obj: any): obj is RootSchemaFormInputFieldDisplayProperties {
  return 'schema' in obj;
}

export const getNonBreakingString = (input: string) => input.split(' ').join('\u00A0');
