import {
  CollectionModulePaymentMethod,
  DebitOrderPaymentMethod,
  ExternalPaymentMethod,
  PaymentMethodAll,
} from 'payment-methods/domain/payment-method';
import { Policy } from 'policies/domain/policy';
import React from 'react';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { ManageExternalPaymentMethodCard } from './payment-method-display-cards/external-payment-method-card';
import { ManageStripePaymentMethodCard } from './payment-method-display-cards/stripe-payment-method-card';
import { ManageDebitOrderPaymentMethod } from './payment-method-display-cards/debit-order-payment-method-card';
import { CollectionModulePaymentMethodSummaryView } from './payment-method-display-cards/collection-module-payment-method-summary';

export const PaymentMethodCard = (props: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  paymentMethod: PaymentMethodAll;
  policy: Policy;
  refetchPolicy: () => void;
  setShowUpdateBillingDay: (showUpdateBillingDay: boolean) => void;
}) => {
  const { paymentMethod, siteConfig, policy, refetchPolicy, setShowUpdateBillingDay } = props;

  const stripePublishableKey = siteConfig?.settings.stripePublishableKey;

  if (paymentMethod instanceof DebitOrderPaymentMethod) {
    return (
      <ManageDebitOrderPaymentMethod
        paymentMethod={paymentMethod}
        siteConfig={siteConfig}
        policy={policy}
        setShowUpdateBillingDay={setShowUpdateBillingDay}
      />
    );
  }

  if (paymentMethod instanceof ExternalPaymentMethod) {
    return stripePublishableKey ? (
      <ManageStripePaymentMethodCard
        paymentMethod={paymentMethod}
        siteConfig={siteConfig}
        policy={policy}
        refetchPolicy={refetchPolicy}
      />
    ) : (
      <ManageExternalPaymentMethodCard
        paymentMethod={paymentMethod}
        siteConfig={siteConfig}
        policy={policy}
        setShowUpdateBillingDay={setShowUpdateBillingDay}
      />
    );
  }

  if (paymentMethod instanceof CollectionModulePaymentMethod) {
    return (
      <CollectionModulePaymentMethodSummaryView
        paymentMethod={paymentMethod}
        policy={policy}
        setShowUpdateBillingDay={setShowUpdateBillingDay}
      />
    );
  }

  return <p>Payment method type not yet supported</p>;
};
