import { IdentificationType, SelectableIdType } from 'policyholder/domain/policyholder-identification';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { CardGroup } from 'rootstrap/components/card';
import { IdTypeSelectField } from 'rootstrap/components/forms/new-fields/id-type-select-field';
import { InputField, InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { Col, Row } from 'rootstrap/components/grid/grid';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { getWording, getWordingForIdTypeHandlebars } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { PersonalDetailsInputs, PolicyholderPrefillValues } from './personal-details';
import { getIdType, getIdTypeValue, getIdTypes } from '../utils/helpers';
import { ProductModule } from 'product-modules/domain/product-module';
import { SteppedComponentsBehavior } from 'rootstrap/components-old/root-schema-form/utils/stepped-components-behavior';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { TextField } from 'rootstrap/components/forms/new-fields/extended-components/text-field';
import { PhoneNumberField } from 'rootstrap/components/forms/new-fields/phone-number-field';
import phone from 'phone';
import { BCP47ToIsoCountryCode } from 'rootstrap/components/forms/countries';
import { ContactDetailCellphone } from './contact-details';
import { Cellphone, Policyholder } from 'policyholder/domain/policyholder';
import { PolicyholderLookUpModal } from './policyholder-lookup-modal';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { CountrySelectField } from 'rootstrap/components/forms/new-fields/extended-components/country-select-field';
import { lookupUnauthenticatedPolicyholder } from 'policyholder/actions/lookup-unauthenticated-policyholder';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { getOptionalPolicyholderIdFields, isPolicyholderSectionComplete } from '../utils/is-policyholder-prefilled';
import { PersonalDetailsIdentificationData } from './identification';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

export interface PolicyholderLookUpFormData {
  idType?: SelectableIdType;
  identificationNumber?: string;
  passportNumber?: string;
  email?: string;
  cellphone?: ContactDetailCellphone;
  identificationCountry?: string;
}

interface Props {
  policyholderLookupFormData: PolicyholderLookUpFormData;
  setPolicyholderLookupFormData: (v: PolicyholderLookUpFormData) => void;
  productModule: ProductModule | undefined;
  isCompleted: boolean;
  foundPolicyholder: Policyholder | undefined;
  isLookupPolicyholderModalOpen: boolean;
  lookupPolicyholderError?: Error;
  activeElement: ActiveElement;
  policyholderPrefillValues: PolicyholderPrefillValues;
  createPolicyholderSubmitButtonRef: React.MutableRefObject<any>;
  policyholder: Policyholder | undefined;
  isLoading: boolean;
  setLookupPolicyholderModalOpen: (v: boolean) => void;
  setPolicyholder: (p: Policyholder) => void;
  setCurrentStepKey: (s: IssuingSceneStepKeys) => void;
  onNextCompleted: (() => void | Promise<void>) | undefined;
  setNewPersonalDetailsAccepted: (v: boolean) => void;
  setActiveElement: (params: ActiveElement) => void;
  setPolicyholderIsValid: (v: boolean) => void;
  resetPolicyholderData: () => void;
  setFoundPolicyholder: (policyholder: Policyholder) => void;
}

export const UnauthenticatedPolicyholderLookup = (props: Props) => {
  const {
    policyholderLookupFormData,
    productModule,
    foundPolicyholder,
    isLookupPolicyholderModalOpen,
    lookupPolicyholderError,
    policyholderPrefillValues,
    createPolicyholderSubmitButtonRef,
    policyholder,
    isLoading,
    setLookupPolicyholderModalOpen,
    setPolicyholderLookupFormData,
    setCurrentStepKey,
    setPolicyholder,
    onNextCompleted,
    setNewPersonalDetailsAccepted,
    setPolicyholderIsValid,
    resetPolicyholderData,
    setFoundPolicyholder,
  } = props;
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId } = embedParams;
  const { siteConfig } = useSiteConfigContext();
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: '',
  });

  const defaultCountryCodeFromBrowser = siteConfig?.settings?.defaultCountryCodeFromBrowser;
  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: policyholderLookupFormData,
      }),
      [],
    ),
  });

  const [steppedComponentsBehavior] = useState<SteppedComponentsBehavior>({
    disableNextButton: disableSteppedComponents,
    hideDivider: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents ? form.formState.isValid : true,
  });

  useEffect(() => {
    setPolicyholderIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  const { error: errorAutoSubmit, isLoading: isLoadingAutoSubmit } = usePromise(async () => {
    const skipOnPrefill = siteConfig?.personalDetails.displayOptionalSections.skipOnPrefill;

    if (!form.formState.isValid) {
      return setActiveElement({
        elementId: PersonalDetailsInputs.IdType,
      });
    }
    if (skipOnPrefill) {
      const optionalFields = getOptionalPolicyholderIdFields(policyholderPrefillValues?.identification?.type);

      if (policyholderPrefillValues.identification?.type === IdentificationType.Custom) {
        return setNewPersonalDetailsAccepted(true);
      }

      if (policyholderPrefillValues) {
        const isIdentificationSectionComplete = isPolicyholderSectionComplete<PersonalDetailsIdentificationData>(
          policyholderPrefillValues,
          [
            ...optionalFields,
            PersonalDetailsInputs.FirstName,
            PersonalDetailsInputs.LastName,
            PersonalDetailsInputs.AddressLine1,
            PersonalDetailsInputs.AddressLine2,
            PersonalDetailsInputs.AreaCode,
            PersonalDetailsInputs.City,
            PersonalDetailsInputs.Suburb,
            PersonalDetailsInputs.PolicyholderType,
            PersonalDetailsInputs.Gender,
            PersonalDetailsInputs.DateOfBirth,
          ],
        );

        if (isIdentificationSectionComplete) {
          try {
            const policyholder = await lookupUnauthenticatedPolicyholder({
              auth,
              data: {
                identificationNumber: policyholderPrefillValues.identification?.number,
                email: policyholderPrefillValues.email,
                cellphone: policyholderPrefillValues.cellphone && {
                  countryCode: policyholderPrefillValues.cellphone.country,
                  number: policyholderPrefillValues.cellphone.number,
                },
                idType: policyholderPrefillValues.identification?.type as any,
                passportNumber: policyholderPrefillValues.identification?.number,
                identificationCountry: policyholderPrefillValues.identification?.country,
              },
              environment,
              organizationId,
            });
            setFoundPolicyholder(policyholder);
            return setLookupPolicyholderModalOpen(true);
          } catch (error: any) {
            const errorMessage = error?.message;

            if (
              errorMessage !== 'Policyholder with active policy not found' &&
              errorMessage !== 'Policyholder not found'
            ) {
              throw error;
            }
          }
          setNewPersonalDetailsAccepted(true);
        }
      }
    }
  }, []);

  const {
    execute: submitForm,
    isLoading: isLoadingSubmitForm,
    error: submitFormError,
  } = usePromiseLazy(async () => {
    if (await form.trigger()) {
      await form.handleSubmit(async (data: PolicyholderLookUpFormData) => {
        setPolicyholderLookupFormData(data);

        try {
          const policyholder = await lookupUnauthenticatedPolicyholder({
            auth,
            data,
            environment,
            organizationId,
          });
          setFoundPolicyholder(policyholder);

          return setLookupPolicyholderModalOpen(true);
        } catch (error: any) {
          const errorMessage = error?.message;

          if (
            errorMessage !== 'Policyholder with active policy not found' &&
            errorMessage !== 'Policyholder not found'
          ) {
            throw error;
          }
        }

        // TODO - we need a better way of validating if the policyholder in memory is the Obfuscated policyholder
        if (policyholder?.firstName === '****') {
          resetPolicyholderData();
        }
        setNewPersonalDetailsAccepted(true);
      })();
    }
  }, []);

  if (!productModule || isLoading) {
    return (
      <FormDetailsWrapper siteConfig={siteConfig}>
        <LoadingLines />
      </FormDetailsWrapper>
    );
  }

  const idType = getIdType({
    formIdType: form.watch(PersonalDetailsInputs.IdType) as IdentificationType,
    productModule,
  });
  const idTypes = getIdTypes(productModule);

  const execute = () => {
    const formValues = form.getValues() as PolicyholderLookUpFormData;
    setPolicyholderLookupFormData(formValues);
  };

  return (
    <>
      {isLookupPolicyholderModalOpen && (
        <PolicyholderLookUpModal
          resetPolicyholderData={resetPolicyholderData}
          setLookupPolicyholderModalOpen={setLookupPolicyholderModalOpen}
          setNewPersonalDetailsAccepted={setNewPersonalDetailsAccepted}
          onNextCompleted={onNextCompleted}
          foundPolicyholder={foundPolicyholder}
          setCurrentStepKey={setCurrentStepKey}
          setPolicyholder={setPolicyholder}
        />
      )}
      <FormWrapperStyle>
        {isLoadingAutoSubmit && (
          <FormDetailsWrapper siteConfig={siteConfig}>
            <LoadingLines />
          </FormDetailsWrapper>
        )}
        <ErrorAlert error={lookupPolicyholderError || errorAutoSubmit || submitFormError} displayFieldError />
        <CardGroup>
          <form
            style={isLoadingAutoSubmit ? { display: 'none' } : {}}
            onBlur={() => execute()}
            onChange={() => execute()}
            onSubmit={form.handleSubmit((data: PolicyholderLookUpFormData) => {
              setPolicyholderLookupFormData(data);
            })}
          >
            <Row>
              <Col sm={12}>
                <IdTypeSelectField
                  clearable={true}
                  placeholder={undefined}
                  idTypes={idTypes}
                  defaultValue={getIdTypeValue({
                    identificationType:
                      policyholderLookupFormData.idType || policyholderPrefillValues.identification?.type,
                    idTypes,
                  })}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  name={PersonalDetailsInputs.IdType}
                  label={getWording({ wording: siteConfig?.inputFields.personalDetails.idType.label })}
                  prefillAction={siteConfig?.inputFields.personalDetails.idType.prefillAction}
                  form={form}
                  isDisabled={idTypes.length <= 1}
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: getNextComponentName(policyholderLookupFormData),
                    } as InputFieldDisplayProperties
                  }
                  {...steppedComponentsBehavior}
                />
              </Col>
            </Row>
            <Row>
              {idType === IdentificationType.Id && (
                <Col sm={12}>
                  <TextField
                    placeholder={undefined}
                    defaultValue={
                      policyholderLookupFormData?.identificationNumber ||
                      policyholderPrefillValues.identification?.number
                    }
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                      {
                        validation: {
                          type: ValidationTypes.ZAId,
                        },
                      },
                    ]}
                    name={PersonalDetailsInputs.IdentificationNumber}
                    label={getWordingForIdTypeHandlebars({
                      wording: siteConfig?.inputFields.personalDetails.identificationNumber.label,
                      identificationType: idType,
                    })}
                    form={form}
                    prefillAction={siteConfig?.inputFields.personalDetails.identificationNumber.prefillAction}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                      } as InputFieldDisplayProperties
                    }
                    {...steppedComponentsBehavior}
                  />
                </Col>
              )}
              {idType === IdentificationType.Passport && (
                <>
                  <Col sm={12}>
                    <TextField
                      placeholder={undefined}
                      defaultValue={
                        policyholderLookupFormData?.identificationNumber ||
                        policyholderPrefillValues.identification?.number
                      }
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      name={PersonalDetailsInputs.IdentificationNumber}
                      label={getWordingForIdTypeHandlebars({
                        wording: siteConfig?.inputFields.personalDetails.identificationNumber.label,
                        identificationType: idType,
                      })}
                      prefillAction={siteConfig?.inputFields.personalDetails.identificationNumber.prefillAction}
                      form={form}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: PersonalDetailsInputs.IdentificationCountry,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior}
                    />
                  </Col>
                  <Col sm={12}>
                    <CountrySelectField
                      clearable={true}
                      placeholder={undefined}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      form={form}
                      label={getWordingForIdTypeHandlebars({
                        wording: siteConfig?.inputFields.personalDetails.identificationCountry.label,
                        identificationType: idType,
                      })}
                      name={PersonalDetailsInputs.IdentificationCountry}
                      defaultValue={
                        policyholderLookupFormData.identificationCountry ||
                        policyholderPrefillValues.identification?.country ||
                        (defaultCountryCodeFromBrowser && BCP47ToIsoCountryCode()) ||
                        'ZA'
                      }
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior}
                    />
                  </Col>
                </>
              )}
              {idType === IdentificationType.Email && (
                <Col sm={12}>
                  <InputField
                    placeholder={undefined}
                    defaultValue={policyholderLookupFormData?.email || policyholderPrefillValues.email}
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                      {
                        validation: {
                          type: ValidationTypes.EMAIL,
                        },
                      },
                    ]}
                    name={PersonalDetailsInputs.Email}
                    label={getWording({ wording: siteConfig?.inputFields.personalDetails.email.label })}
                    prefillAction={siteConfig?.inputFields.personalDetails.email.prefillAction}
                    form={form}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                        // nextComponentName: displayCellphoneNumber
                        //   ? PersonalDetailsInputs.Cellphone
                        //   : PersonalDetailsInputs.AddressOptIn,
                      } as InputFieldDisplayProperties
                    }
                    {...steppedComponentsBehavior}
                  />
                </Col>
              )}
              {idType === IdentificationType.Cellphone && (
                <Col sm={12}>
                  <PhoneNumberField
                    placeholder={undefined}
                    defaultValue={defaultValues({
                      cellphone: policyholderLookupFormData.cellphone || policyholderPrefillValues.cellphone,
                      defaultCountryCodeFromBrowser,
                    })}
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                    ]}
                    name={PersonalDetailsInputs.Cellphone}
                    label={getWording({ wording: siteConfig?.inputFields.personalDetails.cellphone.label })}
                    prefillAction={siteConfig?.inputFields.personalDetails.cellphone.prefillAction}
                    form={form}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                      } as InputFieldDisplayProperties
                    }
                    {...steppedComponentsBehavior}
                  />
                </Col>
              )}
            </Row>
            <button
              style={{ display: 'none' }}
              onClick={submitForm}
              ref={createPolicyholderSubmitButtonRef}
              type='submit'
            />
          </form>
        </CardGroup>
      </FormWrapperStyle>
    </>
  );
};

const defaultValues = (params: {
  cellphone: ContactDetailCellphone | Cellphone | undefined;
  defaultCountryCodeFromBrowser: boolean | undefined;
}) => {
  const { defaultCountryCodeFromBrowser, cellphone } = params;

  return {
    countryCode:
      (cellphone as ContactDetailCellphone)?.countryCode ||
      (cellphone as Cellphone)?.country ||
      phone(cellphone?.number || '').countryIso2 ||
      (defaultCountryCodeFromBrowser && BCP47ToIsoCountryCode()) ||
      'ZA',
    number: cellphone?.number?.replace(phone(cellphone?.number || '').countryCode || '', ''),
  };
};

const getNextComponentName = (policyholderLookUpFormData: PolicyholderLookUpFormData): PersonalDetailsInputs => {
  const { idType } = policyholderLookUpFormData;

  if (!idType) {
    return PersonalDetailsInputs.IdentificationNumber;
  }

  const nextComponentMapping: {
    [key in SelectableIdType]: PersonalDetailsInputs;
  } = {
    [SelectableIdType.Id]: PersonalDetailsInputs.IdentificationNumber,
    [SelectableIdType.Passport]: PersonalDetailsInputs.IdentificationNumber,
    [SelectableIdType.Email]: PersonalDetailsInputs.Email,
    [SelectableIdType.Cellphone]: PersonalDetailsInputs.Cellphone,
    [SelectableIdType.Company]: PersonalDetailsInputs.CompanyName,
  };

  return nextComponentMapping[idType];
};
