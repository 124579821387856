import React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { Size, SuccessButton } from 'rootstrap/components/button/styles';
import { globalStyles, Spacing } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { StyledInput } from './styled-input';
import { Icon24PxCross } from 'assets/icon-24-px-cross';
import { getBorderRadius, getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';

export const PrimitiveListField = ({
  defaultValues,
  label,
  name,
  helpText,
  defaultValue,
  isDisabled,
  form,
  validators,
  prefillValue,
  prefillAction,
  disableScrollToElement,
  displayProperties,
  disableNextButton,
  hideDivider,
  disableTitle,
  isTouched: defaultIsTouched,
  disableActiveElement,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<number> & {
  defaultValues: string[];
}) => {
  const { siteConfig } = useSiteConfigContext();
  const [values, setValues] = useState<string[]>(defaultValues || []);
  const [inputValue, setInputValue] = useState('');

  const [isTouched, setTouched] = useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const defaultFormControl = useForm().control;
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;
  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,
    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: undefined,
      }),
  });

  useEffect(() => {
    if (hiddenFromPrefill.display && setActiveElement && !isTouched) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: false,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  const handleAddClicked = () => {
    if (inputValue !== '') {
      const newValues = [...values, inputValue];
      setValues(newValues);
      setInputValue('');
      form?.setValue(name, newValues);
      setTouched(true);
    }
  };

  const handleDeleteClicked = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          disableTitle={disableTitle}
          isActive={isActive}
          hiddenComponent={hiddenComponent}
          name={name}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          helpText={helpText}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
            fontSize: 16,
          }}
        >
          <Controller
            name={name}
            control={form?.control || defaultFormControl}
            defaultValue={defaultValue}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                }),
            }}
            render={({ onChange, value }) => {
              return (
                <StyledListInput>
                  <StyledListGroup siteConfig={siteConfig}>
                    {values.map((str, index) => (
                      <StyledListGroupItem
                        siteConfig={siteConfig}
                        isActive={isActive}
                        isTouched={isTouched}
                        key={index}
                      >
                        <StyledListGroupItemContents siteConfig={siteConfig} isActive={isActive} isTouched={isTouched}>
                          <span>{str}</span>
                          {!disabledFromPrefill && (
                            <span>
                              <Icon24PxCross width={16} height={16} onClick={() => handleDeleteClicked(index)} />
                            </span>
                          )}
                        </StyledListGroupItemContents>
                      </StyledListGroupItem>
                    ))}
                  </StyledListGroup>
                  <StyledAddCompanyInput>
                    <StyledInput
                      id={`${name}`}
                      isActive={isActive || isTouched}
                      disableActiveElement={true}
                      type='text'
                      disabled={disabledFromPrefill}
                      value={inputValue}
                      style={{ height: 32 }}
                      onChange={(e) => {
                        onChange(e.target.value);
                        setInputValue(e.target.value);
                      }}
                      onKeyDown={(e) =>
                        onEnterKeyDown({
                          e,
                          nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                          setFieldError: (fieldError) => setFieldError(fieldError),
                          setActiveElement: (elementId) => {
                            handleAddClicked();
                            setActiveElement({
                              elementId,
                            });
                          },
                          getValue: () => form?.watch(name),
                          validationFunction: getValidationMessage,
                          validators,
                          validationProps: undefined,
                          setIsTouched: (isTouched) => setTouched(isTouched),
                        })
                      }
                    />
                    <StyledSuccessButton
                      id={`${name}_add_button`}
                      outline
                      isActive={isActive}
                      disabled={disabledFromPrefill}
                      isTouched={isTouched}
                      siteConfig={siteConfig}
                      onClick={() => {
                        handleAddClicked();
                        document.getElementById(`stepped-form-next-button-${name}`)?.focus();
                      }}
                      size={Size.default}
                    >
                      Add
                    </StyledSuccessButton>
                  </StyledAddCompanyInput>
                </StyledListInput>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            setTouched={(isTouched) => setTouched(isTouched)}
            hideDivider={hideDivider}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) => {
              handleAddClicked();
              setActiveElement({
                elementId,
              });
            }}
            validationFunction={getValidationMessage}
            validators={validators}
            disableNextButton={disableNextButton}
            validationProps={{
              prefix: undefined,
            }}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};

const StyledSuccessButton = styled(SuccessButton)<{
  isActive: boolean;
  isTouched: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  height: 40.5px;
  min-height: 40.5px;

  border-color: ${({ isActive, isTouched, siteConfig }) =>
    getColor({ siteConfig, color: isActive || isTouched ? 'primary' : 'disabled' })} !important;

  color: ${({ isActive, isTouched, siteConfig }) =>
    getColor({ siteConfig, color: isActive || isTouched ? 'primary' : 'disabled' })} !important;
`;

const StyledAddCompanyInput = styled.div`
  display: flex;
  padding-top: ${Spacing.sm};

  div {
    margin-right: ${Spacing.xs};
  }
`;

const StyledListGroup = styled(ListGroup)<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  .list-group-item {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .list-group-item:first-child {
    border-top-left-radius: ${({ siteConfig }) => getBorderRadius({ siteConfig, borderRadius: 'button' })} !important;
    border-top-right-radius: ${({ siteConfig }) => getBorderRadius({ siteConfig, borderRadius: 'button' })} !important;
  }

  .list-group-item:last-child {
    border-bottom-left-radius: ${({ siteConfig }) =>
      getBorderRadius({ siteConfig, borderRadius: 'button' })} !important;
    border-bottom-right-radius: ${({ siteConfig }) =>
      getBorderRadius({ siteConfig, borderRadius: 'button' })} !important;
  }
`;

const StyledListInput = styled.div`
  .form-control {
    margin-bottom: ${Spacing.sm} !important;
  }

  .list-group {
    margin-top: ${Spacing.sm} !important;
    border-radius: 20px !important;
    margin-bottom: 4px;
  }

  .delete-btn {
    cursor: pointer;
  }
`;

const StyledListGroupItemContents = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  isTouched: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  color: ${({ isActive, isTouched, siteConfig }) =>
    isActive || isTouched ? globalStyles.colors.Body : getColor({ siteConfig, color: 'disabled' })};
`;

const StyledListGroupItem = styled(ListGroupItem)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  isTouched: boolean;
}>`
  border-color: ${({ isActive, isTouched, siteConfig }) =>
    isActive || isTouched ? globalStyles.colors.Body : getColor({ siteConfig, color: 'disabled' })} !important;
`;
