import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { debounce } from 'utils';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { useSiteConfigContext } from 'style-context';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormInputStyle, getSteppedFormLabelColor, SteppedFormButton } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledSliderRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { formatToCurrency, getCurrencySymbol } from './utils/currency-format';
import { PseudoTooltip } from 'rootstrap/components/tooltip/pseudo-tooltip';
import { getSliderTooltipAlignment, StyledSchemaSlider } from 'rootstrap/components/slider/slider';

export const CurrencySliderField = ({
  asCents = true,
  label,
  name,
  helpText,
  defaultValue,
  isDisabled,
  form,
  validators,
  currency,
  prefillValue,
  prefillAction,
  disableScrollToElement,
  displayProperties,
  disableNextButton,
  increment,
  hideDivider,
  disableTitle,
  isTouched: defaultIsTouched,
  disableActiveElement,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<number> & {
  asCents?: boolean;
  currency?: Currency;
  increment?: number;
}) => {
  const { siteConfig } = useSiteConfigContext();
  const currencySymbol = getCurrencySymbol({ currencyCode: currency });
  const [isTouched, setTouched] = useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const defaultFormControl = useForm().control;
  const { activeElement, setActiveElement } = displayProperties;
  const currencySliderRef = useRef<any>();
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;
  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,

    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: {
          prefix: currencySymbol,
        },
      }),
  });

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement && !isTouched) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  const minValidator = validators?.find(({ validation }) => validation.min);
  const maxValidator = validators?.find(({ validation }) => validation.max);

  const min = minValidator?.validation.min ? minValidator?.validation.min : undefined;
  const max = maxValidator?.validation.max ? maxValidator?.validation.max : undefined;

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledSliderRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          disableTitle={disableTitle}
          isActive={isActive}
          hiddenComponent={hiddenComponent}
          name={name}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          helpText={helpText}
          style={{
            ...hiddenFromPrefill,
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
            fontSize: 16,
          }}
        >
          <Controller
            name={name}
            control={form?.control || defaultFormControl}
            defaultValue={form?.watch(name) || min}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: {
                    prefix: currencySymbol,
                  },
                }),
            }}
            render={({ onChange, value }) => {
              return (
                <CurrencySliderWrapper>
                  <StyledSchemaSlider
                    borderColor={getColor({ siteConfig, color: 'border' })}
                    handleRender={(handleProps) => {
                      const clonedProps = { ...handleProps.props, id: `slider-${name}` };

                      return (
                        <>
                          <div {...clonedProps}>
                            <PseudoTooltip
                              isDisabled={!(isActive || isTouched)}
                              id={`currency-slider-value-display-${name}`}
                              borderColor={getColor({ siteConfig, color: 'border' })}
                              content={formatToCurrency({
                                number: value,
                                currencyOptions: { currencyCode: currency, excludeSymbol: false, excludeCents: false },
                              })}
                              alignment={getSliderTooltipAlignment({ value, min: min || 0, max: max || 100 })}
                            />
                          </div>
                        </>
                      );
                    }}
                    defaultValue={form?.watch(name) || min}
                    sliderRailColor={
                      isActive || isTouched
                        ? getColor({ siteConfig, color: 'border' })
                        : getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })
                    }
                    disabled={isDisabled || disabledFromPrefill}
                    max={max}
                    ref={currencySliderRef}
                    min={min}
                    step={increment || 1}
                    primaryColor={
                      isActive || isTouched
                        ? getColor({ siteConfig, color: 'primary' })
                        : getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })
                    }
                    onChange={(incomingValue: any) => {
                      setActiveElement({ elementId: name });
                      setTouched(true);
                      const result =
                        incomingValue === ''
                          ? incomingValue
                          : Math.round(Number.parseInt(Number.parseFloat(incomingValue).toFixed(0), 10) / 100) * 100;
                      setFieldError(
                        getValidationMessage({
                          validators: validators,
                          value: result,
                          props: {
                            prefix: currencySymbol,
                          },
                        }),
                      );
                      onChange(result);
                      debounce('blur-currency-on-change', () => onChange(result), 10);
                      debounce(
                        'blur-currency-slider',
                        () => document.getElementById(`stepped-form-next-button-${name}`)?.focus(),
                        100,
                      );
                    }}
                    value={value === '' ? value : value}
                    style={{ ...getSteppedFormInputStyle({ isActive, siteConfig }), border: 'none' }}
                  />
                  <CurrencyFooter
                    color={
                      isActive
                        ? Colors.Subscript
                        : getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body })
                    }
                  >
                    <CurrencyMinMaxLabel siteConfig={siteConfig}>
                      Min{' '}
                      {min &&
                        formatToCurrency({
                          number: min / 100,
                          currencyOptions: {
                            currencyCode: currency,
                            excludeSymbol: false,
                            excludeCents: true,
                          },
                        })}
                    </CurrencyMinMaxLabel>
                    <CurrencyMinMaxLabel siteConfig={siteConfig}>
                      Max{' '}
                      {max &&
                        formatToCurrency({
                          number: max / 100,
                          currencyOptions: {
                            currencyCode: currency,
                            excludeSymbol: false,
                            excludeCents: true,
                          },
                        })}
                    </CurrencyMinMaxLabel>
                  </CurrencyFooter>
                </CurrencySliderWrapper>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            setTouched={(isTouched) => setTouched(isTouched)}
            hideDivider={hideDivider}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            disableNextButton={disableNextButton}
            validationProps={{
              prefix: currencySymbol,
            }}
          />
        )}
      </StyledSliderRootSchemaComponent>
    </div>
  );
};

const CurrencySliderWrapper = styled.div`
  padding-top: 22px;
  padding-bottom: 22px;
  border: none;
  border-radius: 8px;
  justify-content: left;
  position: relative;
`;

const CurrencyMinMaxLabel = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  color: ${({ siteConfig }) => getColor({ color: 'disabled', siteConfig })};
`;

const CurrencyFooter = styled.div<{ color: string }>`
  grid-template-columns: 1fr 1fr;
  width: 100%;
  display: grid;
  padding-top: 30px;

  div {
    font-size: 12px;
    color: ${Colors.Subscript};
  }

  div + div {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;
