import React, { ReactNode, useEffect, useState } from 'react';
import { FormGroup, FormFeedback, Input } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { globalStyles } from 'rootstrap/global-styles';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { StyledCheckboxLabel } from './checkbox-field';
import { DisplayMarkdown, Field } from '../new-field';
import { Colors } from 'rootstrap/global-styles/colors';
import { usePromise } from 'shared/hooks/promise';

export const MultipleCheckboxField = ({
  label,
  name,
  defaultValue,
  isDisabled,
  form,
  prefillAction,
  prefillValue,
  helpText,
  disableActiveElement,
  hideDivider,
  displayProperties,
  disableScrollToElement,
  validators,
  disableNextButton,
  isTouched: defaultIsTouched,
  submitOnChange,
  disableTitle,
  options,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<string[]> & { options: { label: ReactNode; value: any; isDisabled?: boolean }[] }) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = React.useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;
  const [checkedValues, setCheckedValues] = useState<{ [k: string]: boolean }>(form?.watch(name) || {});

  const { result } = usePromise(async () => {
    const prefillBehaviorResult = prefillBehavior({
      prefillAction,
      options: undefined,
      prefillValue,
      validate: (value) =>
        getValidationMessage({
          validators: validators,
          value: value,
          props: undefined,
        }),
    });

    if (prefillValue) {
      const values = form?.getValues()[name];

      setFieldError(
        getValidationMessage({
          validators: validators,
          value: values,
          props: undefined,
        }),
      );
    }

    return prefillBehaviorResult;
  }, []);

  useEffect(() => {
    form?.setValue(name, form?.watch(name) || {});

    // Set the values in options not present in form values to false
    const formValues = form?.getValues()[name] || {};
    const updatedCheckedValues: any = {};
    options.forEach((option) => {
      updatedCheckedValues[option.value as any] = formValues.hasOwnProperty(option.value)
        ? formValues[option.value]
        : false;
    });
    form?.setValue(name, updatedCheckedValues);
    setCheckedValues(updatedCheckedValues);
  }, []);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
    isActive && setTouched(true);
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  useEffect(() => {
    if (isActive && result?.hiddenFromPrefill.display) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [result?.hiddenFromPrefill.display, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={result?.hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isActive={isActive}
          isTouched={isTouched}
          hiddenComponent={hiddenComponent}
          label={label}
          name={name}
          disableTitle={disableTitle}
          onClick={() => setActiveElement({ elementId: name })}
          helpText={helpText}
          style={{
            ...result?.hiddenFromPrefill,
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
          }}
        >
          <FormGroup
            check
            onClick={(e) => {
              e.stopPropagation();
              setActiveElement({ elementId: name });
            }}
          >
            <Controller
              name={name}
              control={form?.control}
              defaultValue={form?.watch(name) || defaultValue}
              rules={{
                validate: (value) =>
                  getValidationMessage({
                    validators: validators,
                    value: value,
                    props: undefined,
                  }),
              }}
              render={({ onChange: localOnChange }) => {
                return (
                  <StyledMultiCheckboxField
                    disableActiveElement={disableActiveElement}
                    isActive={isActive || isTouched}
                    siteConfig={siteConfig}
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    {options.map((option) => (
                      <MultiCheckboxLabelStyle check isActive={isActive || isTouched}>
                        <Checkbox
                          key={option.value}
                          name={name}
                          id={`${name}_${option.value}`}
                          inputmode='none'
                          isChecked={checkedValues[option.value]}
                          isActive={isActive || isTouched}
                          disabled={isDisabled || result?.disabledFromPrefill || option.isDisabled}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setActiveElement({ elementId: name });

                            setTouched(true);

                            const key = option.value;

                            const updatedCheckedState = !checkedValues[key];
                            checkedValues[key] = updatedCheckedState;
                            setCheckedValues(checkedValues);

                            const values = {
                              ...form?.getValues()[name],
                              [key]: updatedCheckedState,
                            };

                            localOnChange(values);

                            const fieldError = getValidationMessage({
                              validators: validators,
                              value: values,
                              props: undefined,
                            });
                            setFieldError(fieldError);
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            onEnterKeyDown({
                              e,
                              nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                              setFieldError: (fieldError) => setFieldError(fieldError),
                              setActiveElement: (elementId) => {
                                setActiveElement({
                                  elementId,
                                });
                              },

                              getValue: () => form?.watch(name),
                              validationFunction: getValidationMessage,
                              validators,
                              validationProps: undefined,
                              setIsTouched: (isTouched) => setTouched(isTouched),
                            });
                          }}
                          onBlur={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setTouched(true);
                          }}
                          checked={checkedValues[option.value]}
                          siteConfig={siteConfig}
                          type='checkbox'
                          invalid={!!fieldError}
                          isDisabled={isDisabled || result?.disabledFromPrefill || option.isDisabled}
                          style={{
                            borderColor: getColor({ color: 'disabled', siteConfig }),
                            caretColor: 'transparent',
                            opacity: 1,
                          }}
                        />
                        <span style={{ height: 'auto' }}>
                          <DisplayMarkdown label={option.label} />
                        </span>
                      </MultiCheckboxLabelStyle>
                    ))}
                  </StyledMultiCheckboxField>
                );
              }}
            />
            <div>
              {fieldError &&
                [fieldError].map((error) => {
                  return (
                    <FormFeedback key={JSON.stringify(error)} style={{ display: 'block' }}>
                      {error}
                    </FormFeedback>
                  );
                })}
            </div>
          </FormGroup>
          {!hiddenComponent && (
            <SteppedFormButton
              disableActiveElement={!!disableActiveElement}
              hideBorder={hideBorder}
              submitOnChange={submitOnChange}
              nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
              setTouched={(isTouched) => setTouched(isTouched)}
              getValue={() => form?.watch(name)}
              parentName={name}
              isDisabled={!!fieldError}
              isActive={isActive}
              setFieldError={(fieldError) => setFieldError(fieldError)}
              setActiveElement={(elementId) => {
                setActiveElement({
                  elementId,
                });
              }}
              hideDivider={hideDivider}
              validationFunction={getValidationMessage}
              validators={validators}
              validationProps={undefined}
              disableNextButton={disableNextButton}
            />
          )}
        </Field>
      </StyledRootSchemaComponent>
    </div>
  );
};

const MultiCheckboxLabelStyle = styled(StyledCheckboxLabel)`
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const StyledMultiCheckboxField = styled.div<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement: boolean | undefined;
}>`
  width: 100%;
  font-size: ${globalStyles.fontSize.body};
  border-radius: 8px;
  padding-left: 20px !important;
  cursor: default;
  height: auto;
  color: ${({ siteConfig, isActive, disableActiveElement }) =>
    getSteppedFormLabelColor({ isActive, siteConfig, color: globalStyles.colors.Body, disableActiveElement })};
`;

export const Checkbox = styled(Input)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isChecked: boolean;
  isActive: boolean;
  isDisabled: boolean;
}>`
  -webkit-appearance: none;
  width: 15px;
  opacity: 1;
  height: 15px;
  outline: none;
  border: 1px solid;
  border-color: ${({ siteConfig, isActive }) =>
    isActive ? globalStyles.colors.Body : getColor({ siteConfig, color: 'border' })}};
  border-radius: 3px;
  background: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${({ isChecked, siteConfig, isActive }) => {
    if (isChecked && isActive) {
      return svgString(getColor({ siteConfig, color: 'highlight' }));
    } else if (isChecked) {
      return svgString(`${getColor({ siteConfig, color: 'highlight' })}80`);
    }
    return svgString('#FFF');
  }}');

  :before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
  }

  &:focus {
    box-shadow: none;
  }

  cursor: ${({ isDisabled }) => 'default'};
`;

const svgString = (color: string) => `data:image/svg+xml;utf8,
  <svg width="17" height="14" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 17 14">
    <path fill="${color.replace(
      '#',
      '%23',
    )}" fill-rule="evenodd" clip-rule="evenodd" d="M7.15125 12.7566C6.76074 13.1471 6.1276 13.1471 5.73707 12.7566L0.707185 7.72729C0.316623 7.33677 0.316607 6.70357 0.707146 6.31303L2.10125 4.91893C2.49178 4.5284 3.12494 4.5284 3.51547 4.91893L6.44418 7.84764L13.5848 0.707017C13.9753 0.316528 14.6084 0.316487 14.9989 0.706925L16.3929 2.10053C16.7835 2.49104 16.7835 3.12428 16.393 3.51484L7.15125 12.7566Z"/>
  </svg>`;
