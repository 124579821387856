import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { useEffect } from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle, RootSchemaForm } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { toInputData, toOutputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Spacing } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { useDraftDefinition } from 'shared/api';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { JSONObject } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { debounce } from 'utils';
import { createQuotes } from '../actions/create-quotes';
import { Quote } from '../domain/quote';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { MixpanelOutcome, MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';

interface Props extends SteppedFullScreenModalComponentParams {
  quoteSchemaFormData: JSONObject | undefined;
  setQuoteSchemaFormData: (data: JSONObject | undefined) => void;
  setQuotePackages: (data?: Quote[]) => void;
  productModuleDefinitionQuoteSchema: ProductModuleDefinitionSchema | undefined;
  setIsLoading: (isLoading: boolean) => void;
  setCreateQuoteError: (error: any) => void;
  createQuoteButtonRef: React.MutableRefObject<any>;
  productModuleDefinition: ProductModuleDefinition | undefined;
  screeningQuestionsData?: JSONObject;
  isCompleted: boolean;
  rootSchemaFormRef: React.MutableRefObject<any>;
}

export const CreateQuotePackage = (params: Props) => {
  const targetDraftProductModuleDefinition = useDraftDefinition();
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { embedSessionId } = useEmbedSessionContext();
  const { startedTrack, subStepCompletedTrack } = useMixpanelTrack();

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.QuoteStep,
    });
  }, []);

  const { auth, organizationId, productModuleKey, environment } = embedParams;
  const {
    quoteSchemaFormData,
    setQuoteSchemaFormData,
    setQuotePackages,
    productModuleDefinitionQuoteSchema,
    setIsLoading,
    setCreateQuoteError,
    screeningQuestionsData,
    createQuoteButtonRef,
    isCompleted,
    prefillValues,
    rootSchemaFormRef,
  } = params;

  const disableSteppedComponents = siteConfig?.styles.disableSteppedComponents;
  const enableSessionPersistence = siteConfig?.settings.enableSessionPersistence;
  const isTouched = isCompleted || !!(prefillValues?.quote && Object.keys(prefillValues?.quote).length !== 0);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  });

  const { execute: onSubmitClicked } = usePromiseLazy(
    async (formData: JSONObject) => {
      setIsLoading(true);

      setQuoteSchemaFormData(formData);

      const { result: quotes, error } = await createQuote.execute({
        ...screeningQuestionsData,
        ...toOutputData({
          formData,
          schema: productModuleDefinitionQuoteSchema?.json || [],
        }),
      });

      setCreateQuoteError(error);
      setQuotePackages(quotes);
      setIsLoading(false);

      if (error) {
        subStepCompletedTrack({
          stepName: MixpanelStepNames.QuoteStep,
          extraProperties: {
            outcome: MixpanelOutcome.Failure,
            quotes: {
              error: (error?.fieldErrors && error?.fieldErrors[0]) || error.message,
            },
          },
        });
      }
    },

    [quoteSchemaFormData, screeningQuestionsData],
  );

  const createQuote = usePromiseLazy(async (data: JSONObject) => {
    setQuotePackages(undefined);

    const quotes = await createQuotes({
      auth,
      data: {
        type: productModuleKey,
        ...data,
      },
      embedSessionId: enableSessionPersistence ? embedSessionId : undefined,
      organizationId,
      targetDraftProductModuleDefinition,
      environment,
    });

    subStepCompletedTrack({
      stepName: MixpanelStepNames.QuoteStep,
      extraProperties: {
        outcome: MixpanelOutcome.Success,
        quotes: quotes.map(({ billingFrequency, currency, sumAssured, suggestedPremium, quotePackageId }) => ({
          billingFrequency,
          currency,
          sumAssured,
          suggestedPremium,
          quotePackageId,
        })),
      },
    });

    return quotes;
  }, []);

  if (!productModuleDefinitionQuoteSchema) {
    return (
      <>
        <FormWrapperStyle>
          <FormDetailsWrapper siteConfig={siteConfig}>
            <LoadingInputs count={3} />
          </FormDetailsWrapper>
        </FormWrapperStyle>
      </>
    );
  }

  return (
    <>
      <FormWrapperStyle>
        <RootSchemaForm
          ref={rootSchemaFormRef}
          defaultValues={{
            ...toInputData({
              formData: { ...prefillValues?.quote },
              schema: productModuleDefinitionQuoteSchema.json || [],
            }),
            ...quoteSchemaFormData,
          }}
          secondaryStepStaticData={undefined}
          isSecondaryStep={false}
          disableSteppedComponents={!!disableSteppedComponents}
          prefillValues={params.prefillValues?.quote}
          onCompletedActiveComponentName={'quote-footer-steps'}
          isDisabled={false}
          onError={() => {
            setQuotePackages(undefined);
          }}
          isTouched={isTouched}
          submitOnChange
          currency={params.productModuleDefinition?.settings.billing.currency}
          onSubmit={async (formData) => {
            setIsLoading(true);
            setCreateQuoteError(undefined);
            setQuotePackages(undefined);

            debounce(
              'creating-quote-package',
              () => {
                onSubmitClicked(formData);
              },
              1500,
            );
          }}
          // We need to have the first component in the list be displayed otherwise the stepped components have no reference for a starting point
          // If the first component is not displayed the stepped components will not work - this is to make sure that we always have the fist component displayed
          schema={productModuleDefinitionQuoteSchema.json || []}
          submitButtonRef={createQuoteButtonRef}
        />
      </FormWrapperStyle>
    </>
  );
};

export const QuotePackageDetailsStyle = styled.div`
  font-weight: bold;
  margin-bottom: ${Spacing.md};
  font-size: 20px;

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
