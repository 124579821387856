import React, { useRef } from 'react';
import { DefaultStepKeys, SteppedFullscreenModal } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Content, FlexContainer, MainLayout, MainLayoutContent } from 'rootstrap/components/structure';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

export const PolicyFlowSiteConfigIsLoading = () => {
  const onNextClickedRef = useRef<any>();
  return (
    <MainLayout>
      <MainLayoutContent>
        <Content>
          <FlexContainer>
            <div className='section' id='policy-flow-site-config-is-loading'>
              <SteppedFullscreenModal
                isLoading={true}
                title={''}
                firstStep={undefined}
                onNextClickedRef={onNextClickedRef}
                quotePackageDisplaySteps={[]}
                productModule={undefined}
                error={undefined}
                onClose={() => undefined}
                premiumDisplay={undefined}
                getSteps={() => [
                  {
                    title: '',
                    description: '',
                    key: IssuingSceneStepKeys.QuoteInput,
                    stepName: DefaultStepKeys.Quote,
                    onBackClick: () => undefined,
                    continueButtonProps: {
                      disabled: true,
                      isLoading: true,
                      isNextButtonHidden: true,
                      nextButtonText: '',
                    },
                    body: () => (
                      <FormWrapperStyle>
                        <div style={{ marginTop: 50, marginBottom: 50 }}>
                          <LoadingInputs count={3} />
                        </div>
                      </FormWrapperStyle>
                    ),
                    onNextClick: async () => {},
                  },
                  {
                    key: IssuingSceneStepKeys.Beneficiaries,
                    title: '',
                    description: '',
                    stepName: DefaultStepKeys.Beneficiaries,
                    continueButtonProps: {
                      disabled: true,
                      isLoading: true,
                      isNextButtonHidden: true,
                      nextButtonText: '',
                    },
                    body: () => <></>,
                    onNextClick: async () => {},
                  },
                  {
                    key: IssuingSceneStepKeys.Payment,
                    stepName: DefaultStepKeys.Payment,
                    title: '',
                    description: '',
                    continueButtonProps: {
                      disabled: true,
                      isLoading: true,
                    },
                    body: () => <></>,
                    onNextClick: async () => {},
                  },
                ]}
                finalStepButtonContent='Pay now'
                headingSplashSteps={[]}
                CustomActionBarContent={() => <></>}
                disableNavbar={true}
                footer={() => <></>}
              />
            </div>
          </FlexContainer>
        </Content>
      </MainLayoutContent>
    </MainLayout>
  );
};
