import React, { useEffect } from 'react';
import { ArrayField, useFieldArray, UseFormMethods } from 'react-hook-form';
import { JSONObject } from 'shared/utils';
import { LinkButton } from 'rootstrap/components-old/buttons';
import { Size, SuccessButton } from 'rootstrap/components/button/styles';
import { BaseStoreModel, FormStoreModelList } from 'rootstrap/components-old/root-schema-form/interfaces';
import {
  evaluateDisplayConditionsForOutputData,
  getComponent,
} from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { getFormKey } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { InputFieldDisplayProperties } from './input-field';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { getNextComponentId } from './utils';
import styled, { css } from 'styled-components';
import { StyledDividerOpenColor } from './divider';
import { IconCross } from 'assets/icon-cross';
import { Spacing } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';

type FormData = JSONObject;

export const ListField = (params: {
  name: string;
  form: UseFormMethods<JSONObject>;
  secondaryStepStaticData: FormData | undefined;
  componentSchema: FormStoreModelList;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  onSubmit: (data: any) => void | Promise<void>;
  currency: Currency | undefined;
  prefillValues: JSONObject | undefined;
  onCompletedActiveComponentName: string;
  displayProperties: InputFieldDisplayProperties;
  isTouched: boolean;
  submitOnChange?: boolean;
  showAddSubtractInApplicationStep: boolean | undefined;
  disableSteppedComponents: boolean;
  isSecondaryStep: boolean | undefined;
}) => {
  const {
    form,
    name,
    componentSchema,
    siteConfig,
    onSubmit,
    currency,
    prefillValues,
    onCompletedActiveComponentName,
    displayProperties,
    isTouched,
    submitOnChange,
    showAddSubtractInApplicationStep,
    secondaryStepStaticData,
    isSecondaryStep,
    disableSteppedComponents,
  } = params;
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name,
  });
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name;

  // Always ensure there is at least one child component
  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, []);

  useEffect(() => {
    if (isActive) {
      setActiveElement &&
        setActiveElement({
          elementId: `${name}[${0}].${getFormKey(componentSchema.components[0])}`,
        });
    }
  }, [name, isActive]);

  const displayAddAndRemove = !isSecondaryStep || showAddSubtractInApplicationStep;
  const isMaxComponent = componentSchema.maxNumber && fields.length === componentSchema.maxNumber;

  return (
    <div key={name}>
      <StyledDividerOpenColor height={1} color={Colors.Body} />
      {fields.map((field, fieldIndex) => {
        const { components, outputPathList } = componentSchema;

        return (
          <div key={field.id}>
            <ListItemWrapper siteConfig={siteConfig} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flexGrow: 1 }}>
                {displayAddAndRemove && fieldIndex === fields.length - 1 && fields.length !== 1 && (
                  <LinkButton
                    id={`remove-button-${name}-${field.id}`}
                    style={{ float: 'right' }}
                    size={Size.lg}
                    onClick={async () => {
                      remove(fieldIndex);
                      if (await form.trigger()) {
                        await form.handleSubmit(onSubmit)();
                      }
                    }}
                  >
                    <span>
                      <span className='remove-icon'>
                        <IconCross size={10} color={getColor({ siteConfig, color: 'primary' })} />
                      </span>
                      Remove
                    </span>
                  </LinkButton>
                )}
                {components.map((componentSchema, index) => {
                  const { key, nextComponentName } = getNextKey({
                    componentSchema,
                    displayProperties,
                    fieldIndex,
                    fields,
                    index,
                    name,
                    components,
                  });

                  const Component = getComponent({
                    componentSchema: { ...componentSchema, key } as any,
                    arrayIndex: fieldIndex,
                    form,
                    onSubmit,
                    siteConfig,
                    currency,
                    prefillValues,
                    isSecondaryStep,
                    secondaryStepStaticData,
                    submitOnChange: !!submitOnChange,
                    isTouched,
                    disableSteppedComponents,
                    onCompletedActiveComponentName,
                    displayProperties: {
                      setActiveElement: displayProperties.setActiveElement,
                      activeElement: displayProperties.activeElement,
                      nextComponentName,
                    } as any,
                  });

                  const formValue = form.watch(`${outputPathList || ''}[${fieldIndex}]`) as JSONObject;
                  const shouldRenderComponent = evaluateDisplayConditionsForOutputData({
                    displayConditions: componentSchema.displayConditions,
                    formData: formValue,
                  });

                  if (!shouldRenderComponent) {
                    form.unregister(key);
                  }

                  return shouldRenderComponent && <div>{Component}</div>;
                })}
              </div>
            </ListItemWrapper>
            <StyledDividerOpenColor height={1} color={Colors.Body} />
          </div>
        );
      })}
      {displayAddAndRemove && !isMaxComponent && (
        <>
          <StyledListFieldButtonWrapper>
            <SuccessButton
              outline
              siteConfig={siteConfig}
              id={`add-button-${name}`}
              disabled={false}
              size={Size.lg}
              onClick={async () => {
                append({});
                onAddItemClicked({ activeElementId: activeElement.elementId || '' });

                if (await form.trigger()) {
                  await form.handleSubmit(onSubmit)();
                }
              }}
            >
              {componentSchema.props?.addButtonLabel || 'Add new'}
            </SuccessButton>
          </StyledListFieldButtonWrapper>
          <StyledDividerOpenColor height={1} color={Colors.Body} />
        </>
      )}
    </div>
  );
};

const onAddItemClicked = async (params: { activeElementId: string }) => {
  const { activeElementId } = params;
  await new Promise((resolve) => {
    let counter = 0;
    const intervalId = setInterval(() => {
      const currentComponentNextButton = document.getElementById(`stepped-form-next-button-${activeElementId}` || '');
      if (currentComponentNextButton) {
        clearInterval(intervalId);
        currentComponentNextButton.click();
        resolve(currentComponentNextButton);
      } else if (counter >= 10) {
        clearInterval(intervalId);
        resolve(null);
      }
      counter++;
    }, 100);
  });
};

const getNextKey = (params: {
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  fieldIndex: number;
  name: string;
  componentSchema: BaseStoreModel;
  index: number;
  displayProperties: InputFieldDisplayProperties;
  components: BaseStoreModel[];
}) => {
  const { fieldIndex, fields, name, componentSchema, index, displayProperties, components } = params;

  if (Array.isArray(componentSchema.key)) {
    throw new TypeError('Unhandled array key');
  }

  const currentComponentKey = `${name}[${fieldIndex}].${getFormKey(componentSchema)}`;
  const nextKey = getFormKey(components[index + 1]);

  let nextComponentName = '';
  if (nextKey) {
    nextComponentName = `${name}[${fieldIndex}].${nextKey}`;
  } else if (fields[fieldIndex + 1]) {
    nextComponentName = `${name}[${fieldIndex + 1}].${getFormKey(components[0])}`;
  } else {
    nextComponentName = getNextComponentId({ ...displayProperties, key: name });
  }

  return {
    key: currentComponentKey,
    nextComponentName,
  };
};

const StyledListFieldButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
  margin-bottom: 39px;
`;

const ListItemWrapper = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  .field-divider {
    border: 0px;
  }

  .btn-link {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'primary' })};
    margin-top: 32px;
    padding: 0;

    span {
      font-size: 14px;
    }

    .remove-icon {
      padding-right: ${Spacing.xs};
    }
  }
`;
