import { ArrowIcon } from 'assets/arrow-icon';
import {
  BeneficiarySummaryFullScreenModalHeader,
  BeneficiarySummaryTitleBody,
  BeneficiarySummaryTitleContents,
} from 'beneficiaries/styles/create-or-update-beneficiary';
import { PaymentMethodAll, PaymentMethodType } from 'payment-methods/domain/payment-method';
import { Policy } from 'policies/domain/policy';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import React, { useState } from 'react';
import { FormOverlay, FormOverlayHeader, FormOverlayHeaderContents } from 'rootstrap/components-old/forms/form-overlay';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { FormOverlayContentWrapper } from 'rootstrap/components/modal/styles';
import {
  FormOverlayTitleWrapper,
  FormOverLay,
  SpacerLeft,
  SpacerRight,
} from 'rootstrap/components/modal/styles/form-overlay-styles';
import { FullScreenModalOffset } from 'rootstrap/components/modal/styles/fullscreen-modal-styles';
import { ArrowIconWrapper } from 'rootstrap/components/progress-bar/progress-bar';
import { MainLayout, MainLayoutContent, Content, FlexContainer } from 'rootstrap/components/structure';
import { globalStyles } from 'rootstrap/global-styles';
import { useSiteConfigContext } from 'style-context';
import { ExternalPaymentMethodDetailsInput } from './forms/external-payment-method-form';
import { PaymentMethodForm } from './forms/payment-method-form';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import { StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { useForm } from 'react-hook-form';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { usePromise } from 'shared/hooks/promise';
import { CollectionModule, CollectionModuleDefinitionVersion } from 'collection-modules/domain/collection-module';
import { getProductModule } from 'product-modules/actions/get-product-module';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { useDraftDefinition } from 'shared/api';
import { isCollectionModuleOnDraftProductModuleDefinition } from './utils/is-collection-module-on-draft-product-module-definition';
import { Environment } from 'models/environment';
import { getProductModuleCollectionModules } from 'collection-modules/actions/get-product-module-collection-modules';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { getNewPaymentMethodOptions } from 'policy-issuing/payment/utils/get-new-payment-method-options';
import { assign } from 'lodash';
import styled from 'styled-components';
import { Padding } from 'rootstrap/global-styles/padding';

interface Props {
  policy: Policy;
  policyholder: Policyholder;
  setPolicy: (policy: Policy) => void;
  setIsUpdatingOrCreatingPaymentMethod: (v: boolean) => void;
  paymentMethod: PaymentMethodAll | undefined;
  setPaymentMethod: (p: PaymentMethodAll) => void;
  productModule: ProductModule;
}

export const CreateOrUpdatePaymentMethod = (props: Props) => {
  const {
    policy,
    setPolicy,
    setIsUpdatingOrCreatingPaymentMethod,
    paymentMethod,
    policyholder,
    setPaymentMethod,
    productModule,
  } = props;
  const { siteConfig } = useSiteConfigContext();
  const targetDraftProductModuleDefinition = useDraftDefinition();
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: ExternalPaymentMethodDetailsInput.BillingDay,
  });
  const { embedParams } = useEmbedParamsContext();
  const { environment, organizationId, auth, productModuleKey } = embedParams;

  const form = useForm<{ type: PaymentMethodType }>({
    mode: 'onChange',
  });
  const isTouched = false;
  const selectedPaymentMethodType = form.watch('type');

  const {
    result,
    isLoading: isLoadingCollectionModules,
    error: collectionModulesError,
  } = usePromise(async () => {
    const productModule = await getProductModule({
      auth,
      organizationId,
      environment,
      targetDraftProductModuleDefinition,
      productModuleKey,
    });

    if (!productModule) {
      throw new Error('Product module not found');
    }

    let collectionModules: CollectionModule[] = [];
    if (environment === Environment.Sandbox) {
      const isDraft = isCollectionModuleOnDraftProductModuleDefinition({
        environment,
        productModule,
      });

      collectionModules = await getProductModuleCollectionModules({
        auth,
        environment,
        organizationId,
        productModuleKey,
        version: isDraft ? CollectionModuleDefinitionVersion.Draft : CollectionModuleDefinitionVersion.Live,
      });
    } else {
      collectionModules = await getProductModuleCollectionModules({
        auth,
        environment,
        organizationId,
        productModuleKey,
        version: undefined,
      });
    }

    const paymentMethodTypes = productModule?.productModuleDefinition?.settings.billing.paymentMethodTypes || [];

    const paymentMethodOptions = getNewPaymentMethodOptions({
      paymentMethodTypes,
      collectionModules,
    });

    const type = paymentMethodOptions.length === 1 ? paymentMethodOptions[0].value : undefined;

    form.control.defaultValuesRef.current = assign({}, { type }, form.getValues());
    form.control.fieldArrayValuesRef.current = assign({}, { type }, form.getValues());

    return {
      collectionModules,
      paymentMethodOptions,
    };
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <MainLayout>
        <MainLayoutContent>
          <Content>
            <FlexContainer>
              <FormOverlay>
                <FormOverlayHeader extendedWidth close={() => null}>
                  <FormOverlayHeaderContents siteConfig={siteConfig}>
                    <FullScreenModalOffset />
                    <FormOverlayTitleWrapper id='full-screen-header'>
                      <BeneficiarySummaryFullScreenModalHeader siteConfig={siteConfig} size={'20px'}>
                        <ArrowIconWrapper onClick={() => setIsUpdatingOrCreatingPaymentMethod(false)}>
                          <ArrowIcon color={globalStyles.colors.Body} size={20} />
                        </ArrowIconWrapper>
                        <BeneficiarySummaryTitleBody>
                          <BeneficiarySummaryTitleContents>Update payment method</BeneficiarySummaryTitleContents>
                        </BeneficiarySummaryTitleBody>
                      </BeneficiarySummaryFullScreenModalHeader>
                    </FormOverlayTitleWrapper>
                    <FullScreenModalOffset />
                  </FormOverlayHeaderContents>
                </FormOverlayHeader>
                <div style={{ height: '100%', overflow: 'scroll' }} id='form-overflow-content-wrapper-payment-details'>
                  <FormOverlayContentWrapper id='form-overlay-content-wrapper'>
                    <FormOverLay>
                      <SpacerLeft />
                      <div className='main'>
                        <ErrorAlert error={collectionModulesError} />

                        <StyledFormWrapperStyle>
                          {isLoadingCollectionModules && <LoadingLines />}
                          {!isLoadingCollectionModules && (
                            <>
                              <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
                                <StyledBeneficiaryCol sm={12}>
                                  <SelectField
                                    clearable={false}
                                    isTouched={isTouched}
                                    disableScrollToElement={false}
                                    hideDivider={true}
                                    options={result?.paymentMethodOptions || []}
                                    validators={[
                                      {
                                        validation: {
                                          type: ValidationTypes.REQUIRED,
                                        },
                                      },
                                    ]}
                                    name={'type'}
                                    label='Type'
                                    form={form}
                                    placeholder={'Type'}
                                    disableActiveElement={true}
                                    disableNextButton={true}
                                    displayProperties={
                                      {
                                        activeElement,
                                        setActiveElement,
                                        nextComponentName: '',
                                      } as InputFieldDisplayProperties
                                    }
                                  />
                                </StyledBeneficiaryCol>
                              </StaticFormWithTitleRowStyle>
                              <PaymentMethodForm
                                selectedPaymentMethodType={selectedPaymentMethodType}
                                productModule={productModule}
                                setPaymentMethod={setPaymentMethod}
                                collectionModules={result?.collectionModules || []}
                                policyholder={policyholder}
                                paymentMethod={paymentMethod}
                                setPolicy={setPolicy}
                                policy={policy}
                                activeElement={activeElement}
                                setActiveElement={setActiveElement}
                                setIsUpdatingOrCreatingPaymentMethod={setIsUpdatingOrCreatingPaymentMethod}
                              />
                            </>
                          )}
                        </StyledFormWrapperStyle>
                      </div>
                      <SpacerRight />
                    </FormOverLay>
                  </FormOverlayContentWrapper>
                </div>
              </FormOverlay>
            </FlexContainer>
          </Content>
        </MainLayoutContent>
      </MainLayout>
    </div>
  );
};

const StyledFormWrapperStyle = styled(FormWrapperStyle)`
  padding-top: ${Padding.md};
`;
