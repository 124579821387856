import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select, { Option } from 'react-select';
import { Field } from '../new-field';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { useSiteConfigContext } from 'style-context';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import arrowDownIconDownActive from '../../../../assets/icon-24-px-arrow-down-active.svg';
import arrowDownIconDownBlur from '../../../../assets/icon-24-px-arrow-down-blur.svg';
import { Colors } from 'rootstrap/global-styles/colors';
import { scroller } from 'react-scroll';
import { globalStyles } from 'rootstrap/global-styles';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';

export interface SelectOption<O> {
  name?: string;
  value: O;
  label: string;
}

const handleFilter = <O,>(options: Option<O>[], filter?: string) => {
  return options.filter((opt: any) =>
    typeof opt.label === 'string'
      ? opt?.label?.toLowerCase().includes(filter?.toLowerCase())
      : opt.name.toLowerCase().includes(filter?.toLowerCase()),
  );
};
export interface SelectFieldParams<O> extends Omit<InputFieldParams<O>, 'defaultValue'> {
  defaultValue?: O | O[];
  options: { label: string; value: string | number | boolean }[];
  filterOptions?: (options: Option<O>[], filter: string) => any;
  searchable?: boolean;
  multi?: boolean;
  clearable: boolean;
}

export const SelectField = <O,>({
  // Generic field props
  label,
  name,
  defaultValue,
  isDisabled,
  form,
  // Specific props
  options,
  searchable = false,
  filterOptions,
  multi = false,
  prefillAction,
  prefillValue,
  hideDivider,
  disableScrollToElement,
  disableTitle,
  clearable = true,
  placeholder,
  disableActiveElement,
  displayProperties,
  validators,
  disableNextButton,
  isTouched: defaultIsTouched,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: SelectFieldParams<O>) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = React.useState<boolean>(!!prefillValue || defaultIsTouched);
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const [required, setRequired] = React.useState<string | undefined>(isRequired ? 'Required' : undefined);
  const [fieldError, setFieldError] = React.useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options,
    validate: (value) => {
      const a = getValidationMessage({
        validators: validators,
        value,
        props: {},
      });
      return a;
    },
  });

  useEffect(() => {
    setTouched(!!prefillValue || defaultIsTouched);
  }, [defaultIsTouched]);

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  useEffect(() => {
    if (isActive && (isDisabled || disabledFromPrefill)) {
      scroller.setActiveLink(getNextComponentId({ ...displayProperties, key: name }) || '');
      setActiveElement({
        elementId: getNextComponentId({ ...displayProperties, key: name }) || '',
      });
    }
  }, [isActive, isDisabled, disabledFromPrefill, scroller.getActiveLink()]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
    });
    if (!disableScrollToElement && isActive && document.activeElement?.className !== 'Select-input') {
      document.getElementById(`stepped-form-next-button-${name}`)?.focus();
    }
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined}>
      <StyledRootSchemaSelectComponent
        className='schema-component'
        isTouched={isTouched}
        disableActiveElement={!!disableActiveElement}
        siteConfig={siteConfig}
        isActive={isActive}
        isFirstElement={!!disableScrollToElement}
        style={hiddenFromPrefill}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          isActive={isActive}
          hiddenComponent={hiddenComponent}
          name={name}
          label={label}
          disableTitle={disableTitle}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          style={{
            ...hiddenFromPrefill,
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body, disableActiveElement }),
          }}
          onClick={() => setActiveElement({ elementId: name })}
        >
          <Controller
            name={name}
            control={form?.control}
            defaultValue={defaultValue}
            id={name}
            rules={{
              required: required,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                }),
            }}
            render={({ onChange, value, ref }) => {
              return (
                <>
                  <StyledSelect
                    isActive={isActive || isTouched}
                    siteConfig={siteConfig}
                    style={{
                      borderRadius: '8px',
                    }}
                    clearable={clearable}
                    name={name}
                    disableActiveElement={disableActiveElement}
                    options={options}
                    multi={multi}
                    id={name}
                    searchable
                    placeholder={placeholder !== undefined ? placeholder : 'Select...'}
                    disabled={isDisabled || disabledFromPrefill}
                    autoFocus={false}
                    autoBlur={true}
                    onFocus={() => setActiveElement({ elementId: name })}
                    onBlur={() => setTouched(true)}
                    filterOptions={(options: Option<any>[], filter) => handleFilter(options, filter)}
                    onInputKeyDown={(e) =>
                      onEnterKeyDown({
                        e,
                        nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                        setFieldError: (fieldError) => setFieldError(fieldError),
                        setActiveElement: (elementId) =>
                          setActiveElement({
                            elementId,
                          }),
                        getValue: () => form?.watch(name),
                        validationFunction: getValidationMessage,
                        validators,
                        validationProps: undefined,
                        setIsTouched: (isTouched) => setTouched(isTouched),
                      })
                    }
                    onChange={(options) => {
                      const value = Array.isArray(options) ? options.map((option) => option.value) : options?.value;
                      setRequired(typeof value === 'boolean' ? undefined : isRequired ? 'Required' : undefined);

                      onChange(value);
                      setTouched(true);

                      const fieldError = getValidationMessage({
                        validators: validators,
                        value,
                        props: undefined,
                      });
                      setFieldError(fieldError);

                      if (!fieldError) {
                        setActiveElement &&
                          setActiveElement({
                            elementId: getNextComponentId({ ...displayProperties, key: name }),
                          });
                      }
                    }}
                    value={value}
                  />
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            hideDivider={hideDivider}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            setTouched={(isTouched) => setTouched(isTouched)}
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={undefined}
            disableNextButton={disableNextButton}
          />
        )}
      </StyledRootSchemaSelectComponent>
    </div>
  );
};

const StyledRootSchemaSelectComponent = styled(StyledRootSchemaComponent)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isTouched: boolean;
  disableActiveElement: boolean;
  isActive: boolean;
}>`
  .Select-control {
    border-radius: ${globalStyles.borderRadius.default};
    border: 1px solid;
    border-color: ${({ siteConfig, isActive, isTouched }) =>
      isActive || isTouched ? globalStyles.colors.Body : getColor({ siteConfig, color: 'disabled' })};
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: ${globalStyles.inputHeight.default};
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .Select.is-focused > .Select-control {
    border-color: ${globalStyles.colors.Body};
  }

  .is-open {
    color: red;
  }
`;

export const StyledSelect = styled(Select)<{
  isActive: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  disableActiveElement?: boolean;
}>`
  height: ${globalStyles.inputHeight.default} !important;
  line-height: ${globalStyles.lineHeight.default};
  font-size: ${globalStyles.fontSize.body};
  box-shadow: none;
  display: flex !important;

  .Select-value-label {
    color: ${({ isActive, siteConfig, disableActiveElement }) =>
      getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body, disableActiveElement })} !important;
  }
  .Select-arrow {
    background-image: url(${({ isActive }) => (isActive ? arrowDownIconDownActive : arrowDownIconDownBlur)});
    margin-right: 18px;
    width: 16px;
    height: 16px;
  }
  .Select-clear {
    color: ${({ isActive, siteConfig, disableActiveElement }) =>
      getSteppedFormLabelColor({
        isActive,
        siteConfig,
        color: getColor({ siteConfig, color: 'disabled' }),
        disableActiveElement,
      })} !important;
  }

  .Select-placeholder {
    font-size: ${globalStyles.fontSize.body};
    align-items: center;
    color: ${({ isActive, siteConfig, disableActiveElement }) =>
      getSteppedFormLabelColor({ isActive, siteConfig, color: 'disabled', disableActiveElement })} !important;
  }

  .Select-value-label {
    align-items: center;
    margin-left: 10px;
  }

  .Select-multi-value-wrapper {
    div {
      outline-color: transparent !important;
    }
  }

  .Select-input {
    align-items: center !important;
    caret-color: transparent !important;
    outline-color: transparent;
    font-size: ${globalStyles.fontSize.body};
  }

  .invalid-feedback {
    margin-top: 12px;
    display: flex !important;
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })} !important;
  }

  .Select-placeholder {
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'disabled' })};
    margin-left: 10px;
  }

  &.focus {
    border: ${({ siteConfig }) => `2px solid ${getColor({ siteConfig: siteConfig, color: 'highlight' })}`} !important;
  }

  padding-bottom: 17px;
`;
