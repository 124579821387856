import { ProductModuleDefinition } from 'product-modules/domain/product-module-definition';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { useEffect } from 'react';
import { LoadingInputs } from 'rootstrap/components-old/loaders/loading-lines';
import { FormWrapperStyle, RootSchemaForm } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { toInputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Spacing } from 'rootstrap/global-styles';
import { devices } from 'rootstrap/global-styles/devices';
import { JSONObject } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { debounce } from 'utils';
import _, { cloneDeep } from 'lodash';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';

interface Props extends SteppedFullScreenModalComponentParams {
  quoteSchemaFormDataPart1: JSONObject | undefined;
  quoteSchemaFormDataPart2: JSONObject | undefined;
  setQuoteSchemaFormDataPart1: (data: JSONObject | undefined) => void;
  setQuoteSchemaFormDataPart2: (data: JSONObject | undefined) => void;
  productModuleDefinitionQuoteSchema?: ProductModuleDefinitionSchema;
  createQuoteButtonRef: React.MutableRefObject<any>;
  productModuleDefinition: ProductModuleDefinition | undefined;
  screeningQuestionsData?: JSONObject;
  isCompleted: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setIsValid: (isValid: boolean) => void;
}

export const CreateQuotePackageStep1 = (params: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    quoteSchemaFormDataPart1,
    quoteSchemaFormDataPart2,
    setQuoteSchemaFormDataPart1,
    setQuoteSchemaFormDataPart2,
    productModuleDefinitionQuoteSchema,
    createQuoteButtonRef,
    isCompleted,
    prefillValues,
    setIsLoading,
    setIsValid,
  } = params;

  const disableSteppedComponents = siteConfig?.styles.disableSteppedComponents;
  const isTouched = isCompleted || !!(prefillValues?.quote && Object.keys(prefillValues?.quote).length !== 0);
  const { startedTrack } = useMixpanelTrack();

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.SplitQuoteStep1,
    });
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  if (!productModuleDefinitionQuoteSchema) {
    return (
      <>
        <FormWrapperStyle>
          <FormDetailsWrapper siteConfig={siteConfig}>
            <LoadingInputs count={3} />
          </FormDetailsWrapper>
        </FormWrapperStyle>
      </>
    );
  }

  const schema = (productModuleDefinitionQuoteSchema.json || []).filter(
    ({ sectionIndex }) => sectionIndex === 1 || sectionIndex === undefined,
  );
  return (
    <>
      <FormWrapperStyle onMouseLeave={() => (document.activeElement as any).blur()}>
        <RootSchemaForm
          defaultValues={{
            ...toInputData({
              formData: { ...prefillValues?.quote },
              schema,
            }),
            ...quoteSchemaFormDataPart1,
          }}
          isSecondaryStep={false}
          secondaryStepStaticData={undefined}
          disableSteppedComponents={!!disableSteppedComponents}
          prefillValues={params.prefillValues?.quote}
          onCompletedActiveComponentName={''}
          isDisabled={false}
          onError={() => undefined}
          setIsValid={(isValid) => setIsValid(isValid)}
          isTouched={isTouched}
          submitOnChange
          currency={params.productModuleDefinition?.settings.billing.currency}
          onSubmit={async (formData) => {
            setIsLoading(true);
            setQuoteSchemaFormDataPart1(_.merge(cloneDeep(quoteSchemaFormDataPart1), formData));
            setQuoteSchemaFormDataPart2(_.merge(cloneDeep(quoteSchemaFormDataPart2), formData));

            debounce(
              'set-quote-step-1-data',
              () => {
                setIsLoading(false);
              },
              1000,
            );
          }}
          schema={schema}
          submitButtonRef={createQuoteButtonRef}
        />
      </FormWrapperStyle>
    </>
  );
};

export const QuotePackageDetailsStyle = styled.div`
  font-weight: bold;
  margin-bottom: ${Spacing.md};
  font-size: 20px;

  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
