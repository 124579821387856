import React, { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { DisplayConditions, FormStoreModelList } from './interfaces';
import { CurrencyField } from 'rootstrap/components/forms/new-fields/currency-field';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { CheckboxField } from 'rootstrap/components/forms/new-fields/checkbox-field';
import { UseFormMethods } from 'react-hook-form';
import { NumberField } from 'rootstrap/components/forms/new-fields/number-field';
import { assign, cloneDeep, get } from 'lodash';
import { LoadingInputs } from '../loaders/loading-lines';
import { RadioField } from 'rootstrap/components/forms/new-fields/radio-field';
import { JSONObject } from 'shared/utils';
import { Form } from 'rootstrap/components/forms/new-form';
import { Header } from 'rootstrap/components/headings/header';
import { useForm } from 'shared/hooks/form';
import styled from 'styled-components';
import { usePromise } from 'shared/hooks/promise';
import {
  InputFieldDisplayProperties,
  InputFieldParams,
  PrefillAction,
} from 'rootstrap/components/forms/new-fields/input-field';
import { CountrySelectField } from 'rootstrap/components/forms/new-fields/extended-components/country-select-field';
import { TextField } from 'rootstrap/components/forms/new-fields/extended-components/text-field';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { devices } from 'rootstrap/global-styles/devices';
import { BlankSpace } from 'rootstrap/components/forms/new-fields/blank-space';
import { HorizontalLine } from 'rootstrap/components/forms/new-fields/horizontal-line';
import { Paragraph } from 'rootstrap/components/forms/new-fields/paragraph';
import { ListField } from 'rootstrap/components/forms/new-fields/list-field';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { RadioButtonField } from 'rootstrap/components/forms/new-fields/radio-button-field';
import { ActiveElement, scrollTo } from 'rootstrap/components/forms/new-fields/utils';
import { CurrencySliderField } from 'rootstrap/components/forms/new-fields/currency-slider-field';
import { getFormKey, getOutputPath } from './utils/output-data';
import { displayConditionalsMap, formComputeShouldRender } from './utils/should-render';
import { NumberSliderField } from 'rootstrap/components/forms/new-fields/number-slider-field';
import { DatePickerField } from 'rootstrap/components/forms/new-fields/date-picker-field';
import { PhoneNumberField } from 'rootstrap/components/forms/new-fields/phone-number-field';
import phone from 'phone';
import { BCP47ToIsoCountryCode } from 'rootstrap/components/forms/countries';
import { MultipleCheckboxField } from 'rootstrap/components/forms/new-fields/multiple-checkbox';
import { SteppedComponentsBehavior } from './utils/stepped-components-behavior';

enum NonActionableComponents {
  SectionHeader = 'section-header',
  BlankSpace = 'blank-space',
  HorizontalLine = 'horizontal-line',
  Paragraph = 'paragraph',
}

enum ActionableComponents {
  Currency = 'currency',
  CurrencySlider = 'currency-slider',
  Number = 'number',
  NumberSlider = 'number-slider',
  Text = 'text',
  DatePicker = 'date-picker',
  Select = 'select',
  Checkbox = 'checkbox',
  Radio = 'radio',
  MultipleCheckbox = 'multiple-checkbox',
  RadioButton = 'radio-button',
  Country = 'country',
  List = 'list',
  Cellphone = 'cellphone',
}

type FormData = JSONObject;

interface RootSchemaFormParams {
  schema: FormStoreModelList[];
  defaultValues?: FormData;
  prefillValues?: FormData;
  onSubmit: (data: FormData) => void | Promise<void>;
  onError: () => any;
  submitButtonRef: Ref<any>;
  secondaryStepStaticData?: FormData;
  isSecondaryStep: boolean;
  isDisabled?: boolean;
  submitOnChange: boolean;
  setRenderComponentsLength?: (componentLength: number) => void;
  currency: Currency | undefined;
  onCompletedActiveComponentName: string;
  onComponentChanged?: () => void;
  isTouched: boolean;
  setIsValid?: (isValid: boolean) => void;
  disableSteppedComponents: boolean;
}

export const RootSchemaForm = forwardRef((params: RootSchemaFormParams, ref) => {
  const {
    onError,
    onSubmit,
    schema,
    submitButtonRef,
    defaultValues,
    secondaryStepStaticData,
    isSecondaryStep,
    prefillValues,
    isDisabled,
    submitOnChange,
    setRenderComponentsLength,
    currency,
    onCompletedActiveComponentName,
    onComponentChanged,
    isTouched,
    setIsValid,
    disableSteppedComponents,
  } = params;
  const { siteConfig } = useSiteConfigContext();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: getFormKey(!isTouched ? (schema[0] as any) : ''),
  });

  useImperativeHandle(ref, () => ({
    resetActiveElement() {
      setActiveElement({
        elementId: '',
      });
    },
  }));

  const form = useForm<FormData>({
    defaultValues: defaultValues,
  });

  (window as any).form = form;

  const [Components, setComponents] = useState<RootSchemaFormComponent[]>(
    getComponentsFromSchema({
      schema,
      form,
      isDisabled,
      prefillValues,
      setActiveElement,
      activeElement,
      currency,
      onSubmit,
      siteConfig,
      defaultValues,
      onCompletedActiveComponentName,
      isTouched,
      submitOnChange,
      secondaryStepStaticData,
      isSecondaryStep,
      disableSteppedComponents,
    }),
  );

  useEffect(() => {
    if (activeElement.elementId === onCompletedActiveComponentName) {
      scrollTo({
        scrollToId: onCompletedActiveComponentName,
        containerId: 'form-overlay-content-wrapper',
        duration: 0,
      });
    }
  }, [activeElement, onCompletedActiveComponentName]);

  useEffect(() => {
    setIsValid && setIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  useEffect(() => {
    const Components = getComponentsFromSchema({
      schema,
      form,
      isDisabled,
      prefillValues,
      setActiveElement,
      activeElement,
      currency,
      onSubmit,
      siteConfig,
      defaultValues,
      onCompletedActiveComponentName,
      isTouched,
      submitOnChange,
      secondaryStepStaticData,
      isSecondaryStep,
      disableSteppedComponents,
    });
    setComponents(Components);
  }, [schema, hasLoaded, activeElement]);

  useEffect(() => {
    form.reset({ ...prefillValues, ...defaultValues });
    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    hasLoaded && setRenderComponentsLength && setRenderComponentsLength(Components.length);
  }, [hasLoaded]);

  usePromise(async () => {
    if (submitOnChange && hasLoaded && (await form.trigger())) {
      form.control.defaultValuesRef.current = assign({}, cloneDeep(defaultValues) || {}, form.getValues());
      form.control.fieldArrayValuesRef.current = assign({}, cloneDeep(defaultValues) || {}, form.getValues());

      await form.handleSubmit(onSubmit)();
    }
  }, [hasLoaded, submitOnChange]);

  if (!hasLoaded) {
    return <LoadingInputs count={1} />;
  }

  if (submitOnChange) {
    return (
      <Form
        onChange={form.handleSubmit(onSubmit, onError)}
        error={null}
        onSubmit={form.handleSubmit(onSubmit, onError)}
      >
        {Components.map(({ Component }) => Component)}
        <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
      </Form>
    );
  }

  return (
    <Form error={null} onChange={onComponentChanged} onSubmit={form.handleSubmit(onSubmit, onError)}>
      {Components.map(({ Component }) => Component)}
      <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
    </Form>
  );
});

export const FormWrapperStyle = styled.div`
  @media ${devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const getLabel = (label: string) => {
  const trimmedLabel = label.trimEnd();
  return trimmedLabel;
};

export const getComponent = (params: {
  componentSchema: FormStoreModelList;
  arrayIndex?: number;
  secondaryStepStaticData: FormData | undefined;
  isSecondaryStep: boolean | undefined;
  isDisabled?: boolean;
  prefillValues: JSONObject | undefined;
  currency: Currency | undefined;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  form: UseFormMethods<FormData>;
  onSubmit: (data: FormData) => void | Promise<void>;
  onCompletedActiveComponentName: string;
  displayProperties: InputFieldDisplayProperties;
  components?: RootSchemaFormComponent[];
  isTouched: boolean;
  submitOnChange: boolean;
  disableSteppedComponents: boolean;
}) => {
  const {
    componentSchema,
    arrayIndex,
    form,
    onSubmit,
    siteConfig,
    currency,
    secondaryStepStaticData,
    isSecondaryStep,
    isDisabled,
    prefillValues,
    onCompletedActiveComponentName,
    displayProperties,
    components,
    isTouched,
    submitOnChange,
    disableSteppedComponents,
  } = params;
  if (Array.isArray(componentSchema.key)) {
    throw new TypeError('Unhandled array key');
  }
  const name = getFormKey(componentSchema);
  const key = getFormKey(componentSchema);
  const label = componentSchema.label && getLabel(componentSchema.label);

  const isRequired = !!componentSchema.validators?.find((validation) => validation.validation.type === 'required');
  const outputPath = getOutputPath({
    key: componentSchema.key,
    outputPath: componentSchema.outputPath,
  });
  const prefillValue = get(prefillValues, outputPath) as PrefillAction;
  const { validators, props: componentSchemaProps } = componentSchema;

  const steppedComponentsBehavior: SteppedComponentsBehavior = {
    disableNextButton: disableSteppedComponents,
    hideDivider: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents ? isTouched : true,
  };

  const props = {
    key,
    name,
    label,
    form,
    arrayIndex,
    isRequired,
    components,
    isDisabled,
    submitOnChange,
    defaultValue: componentSchema.defaultValue as any,
    validators,
    prefillAction: prefillValue !== undefined ? componentSchemaProps?.prefillAction : undefined,
    prefillValue,
    placeholder: componentSchema.props?.placeholder,
    isFirstElement: displayProperties.index === 0,
    hiddenComponent: componentSchema.props?.hiddenComponent,
    prefix: componentSchema.props?.prefix,
    ...steppedComponentsBehavior,
    displayProperties: {
      ...displayProperties,
      setActiveElement: (params: ActiveElement) => {
        displayProperties.setActiveElement({
          elementId: params.elementId || onCompletedActiveComponentName,
        });
      },
    },
  } as InputFieldParams<any>;

  const nonActionableComponents = {
    [NonActionableComponents.SectionHeader]: () => {
      const sizeMap: { [key: string]: any } = {
        h5: 'xs',
        h4: 'xs',
        h3: 'sm',
        h2: 'md',
        h1: 'lg',
      };

      const shouldDisplayListComponentIndex =
        componentSchema.props?.indexNumber && arrayIndex !== undefined ? `${arrayIndex + 1}` : '';
      const labelContent = `${componentSchema.label || ''} ${shouldDisplayListComponentIndex}`;

      return (
        <Header
          {...props}
          size={componentSchema.props?.headingTag ? sizeMap[componentSchema.props.headingTag] : 'md'}
          content={labelContent}
          bolded={(componentSchema.props as any)?.fontWeight === 'bold'}
        />
      );
    },
    [NonActionableComponents.BlankSpace]: () => {
      return <BlankSpace {...props} />;
    },
    [NonActionableComponents.HorizontalLine]: () => {
      return <HorizontalLine {...props} />;
    },
    [NonActionableComponents.Paragraph]: () => {
      return <Paragraph {...props} />;
    },
  };

  const componentMap: { [key: string]: () => ReactNode } = {
    // Form field components
    [ActionableComponents.Currency]: () => {
      return <CurrencyField {...props} prefillAction={props.prefillAction} currency={currency} />;
    },
    [ActionableComponents.CurrencySlider]: () => {
      return <CurrencySliderField increment={componentSchema.props?.increment} currency={currency} {...props} />;
    },
    [ActionableComponents.Number]: () => {
      return <NumberField {...props} decimal={componentSchema.props?.decimal} />;
    },
    [ActionableComponents.NumberSlider]: () => {
      return <NumberSliderField increment={componentSchema.props?.increment} {...props} />;
    },
    [ActionableComponents.Text]: () => {
      return <TextField {...props} />;
    },
    [ActionableComponents.DatePicker]: () => {
      return <DatePickerField {...props} formatValue={(date) => date?.format('YYYY-MM-DD')} />;
    },
    [ActionableComponents.Select]: () => {
      if (!componentSchema.options) {
        throw new TypeError('Cannot render select input without options');
      }

      return <SelectField clearable={true} {...props} options={componentSchema.options} />;
    },
    [ActionableComponents.Checkbox]: () => {
      return <CheckboxField {...props} defaultValue={!!componentSchema.defaultValue} />;
    },
    [ActionableComponents.Radio]: () => {
      if (!componentSchema.options) {
        throw new TypeError('Cannot render radio input without options');
      }

      return <RadioField {...props} options={componentSchema.options} />;
    },
    [ActionableComponents.MultipleCheckbox]: () => {
      if (!componentSchema.options) {
        throw new TypeError('Cannot render multi-checkbox input without options');
      }

      return <MultipleCheckboxField {...props} options={componentSchema.options} />;
    },
    [ActionableComponents.RadioButton]: () => {
      if (!componentSchema.options) {
        throw new TypeError('Cannot render radio button input without options');
      }
      return <RadioButtonField {...props} options={componentSchema.options} />;
    },
    [ActionableComponents.Cellphone]: () => {
      return (
        <PhoneNumberField
          {...props}
          defaultValue={{
            countryCode: phone(props.defaultValue?.number || '').countryIso2 || BCP47ToIsoCountryCode() || 'ZA',
            number: props.defaultValue?.number?.replace(phone(props.defaultValue?.number || '').countryCode || '', ''),
          }}
        />
      );
    },
    [ActionableComponents.Country]: () => {
      return <CountrySelectField {...props} clearable={true} />;
    },
    ...nonActionableComponents,
    // Layout components
    [ActionableComponents.List]: () => {
      return (
        <ListField
          {...props}
          disableSteppedComponents={disableSteppedComponents}
          onCompletedActiveComponentName={onCompletedActiveComponentName}
          form={form}
          componentSchema={componentSchema}
          siteConfig={siteConfig}
          prefillValues={prefillValues}
          currency={currency}
          secondaryStepStaticData={secondaryStepStaticData}
          isSecondaryStep={isSecondaryStep}
          displayProperties={displayProperties}
          onSubmit={onSubmit}
          showAddSubtractInApplicationStep={componentSchema.showAddSubtractInApplicationStep}
        />
      );
    },
  };

  const componentHandler = componentMap[componentSchema.type];
  if (!componentHandler) {
    return console.error(`No component defined for schema type: ${componentSchema.type}`);
  }
  const Component = componentHandler();
  return Component;
};

export const schemaToComponent = (params: {
  componentSchema: FormStoreModelList;
  schema: FormStoreModelList[];
  onCompletedActiveComponentName: string;
  form: UseFormMethods<FormData>;
  onSubmit: (data: FormData) => void | Promise<void>;
  displayProperties: InputFieldDisplayProperties;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  prefillValues: JSONObject | undefined;
  index: number;
  isTouched: boolean;
  currency: Currency | undefined;
  secondaryStepStaticData: FormData | undefined;
  isSecondaryStep: boolean | undefined;
  submitOnChange: boolean;
  disableSteppedComponents: boolean;
}): RootSchemaFormComponent => {
  const {
    componentSchema,
    onCompletedActiveComponentName,
    form,
    onSubmit,
    siteConfig,
    displayProperties,
    prefillValues,
    index,
    isTouched,
    currency,
    secondaryStepStaticData,
    isSecondaryStep,
    submitOnChange,
    disableSteppedComponents,
  } = params;

  const Component = getComponent({
    componentSchema,
    form,
    onSubmit,
    siteConfig,
    onCompletedActiveComponentName,
    displayProperties,
    prefillValues,
    arrayIndex: index,
    isTouched,
    currency,
    secondaryStepStaticData,
    isSecondaryStep,
    submitOnChange,
    disableSteppedComponents,
  });

  const shouldRenderComponent = componentSchema.displayConditions
    ? formComputeShouldRender({
        displayConditions: componentSchema.displayConditions,
        form,
        secondaryStepStaticData,
      })
    : true;

  if (!shouldRenderComponent) {
    // Unregister components which are being hidden because we don't want to include state from hidden components:
    const formPath = componentSchema.outputPathList || componentSchema.outputPath || componentSchema.key;
    // TODO: Investigate not being able to watch the value directly here.
    const formValue = get(form.watch(), formPath);
    if (formPath && formValue !== undefined) {
      form.unregister(formPath);
    }
  }

  componentSchema.key = componentSchema.outputPathList || componentSchema.key;

  return {
    Component: shouldRenderComponent && Component ? Component : undefined,
    componentSchema: shouldRenderComponent && Component ? componentSchema : undefined,
  };
};

export interface RootSchemaFormComponent {
  Component: ReactNode;
  componentSchema: FormStoreModelList | undefined;
}

const getConditionFunc = (condition: DisplayConditions) => {
  const conditionFunc = displayConditionalsMap[condition.condition];

  if (conditionFunc === undefined) {
    throw new Error(`No conditional defined for condition: ${condition.condition}`);
  }

  return conditionFunc;
};

export const evaluateDisplayConditionsForOutputData = ({
  displayConditions,
  formData,
  parentKey,
  fieldIndex,
}: {
  displayConditions?: DisplayConditions[];
  formData?: FormData;
  parentKey?: string;
  fieldIndex?: number;
}): boolean => {
  if (!displayConditions) {
    return true;
  }

  return displayConditions.every((conditional) => {
    const path = `${parentKey}[${fieldIndex}].${conditional.path}`.replace(`${parentKey}.`, '');
    const formValue =
      formData && parentKey !== undefined && fieldIndex !== undefined
        ? get(formData, path)
        : get(formData, conditional.path);

    const checkCondition = getConditionFunc(conditional);
    const resultParent = checkCondition({ formValue, conditional });

    if (conditional.and && conditional.or) {
      const resultAnd = evaluateDisplayConditionsForOutputData({
        displayConditions: conditional.and,
        formData,
        parentKey,
        fieldIndex,
      });
      const resultOr = evaluateDisplayConditionsForOutputData({
        displayConditions: conditional.or,
        formData,
        parentKey,
        fieldIndex,
      });
      return (resultParent && resultAnd) || resultOr;
    }

    if (conditional.and) {
      return (
        resultParent &&
        evaluateDisplayConditionsForOutputData({
          displayConditions: conditional.and,
          formData,
          parentKey,
          fieldIndex,
        })
      );
    }

    if (conditional.or) {
      return (
        resultParent ||
        evaluateDisplayConditionsForOutputData({
          displayConditions: conditional.or,
          formData,
          parentKey,
          fieldIndex,
        })
      );
    }

    return resultParent;
  });
};

export const getComponentsFromSchema = (params: {
  schema: FormStoreModelList[];
  secondaryStepStaticData: FormData | undefined;
  isSecondaryStep: boolean | undefined;
  form: UseFormMethods<FormData>;
  isDisabled?: boolean;
  defaultValues?: JSONObject | undefined;
  prefillValues?: JSONObject | undefined;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  currency: Currency | undefined;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  onSubmit: (data: FormData) => void | Promise<void>;
  onCompletedActiveComponentName: string;
  isTouched: boolean;
  submitOnChange: boolean;
  disableSteppedComponents: boolean;
}): RootSchemaFormComponent[] => {
  const {
    form,
    schema,
    setActiveElement,
    activeElement,
    onSubmit,
    siteConfig,
    onCompletedActiveComponentName,
    prefillValues,
    isTouched,
    currency,
    secondaryStepStaticData,
    isSecondaryStep,
    submitOnChange,
    disableSteppedComponents,
  } = params;

  const componentsToRender = () =>
    schema
      .map((componentSchema, index) => {
        return schemaToComponent({
          componentSchema,
          schema,
          onCompletedActiveComponentName,
          form,
          currency,
          onSubmit,
          siteConfig,
          prefillValues,
          isSecondaryStep,
          isTouched,
          index,
          secondaryStepStaticData,
          submitOnChange,
          displayProperties: {
            activeElement,
            setActiveElement,
            index,
          } as InputFieldDisplayProperties,
          disableSteppedComponents,
        });
      })
      .filter(({ Component }) => Component)
      .map(({ componentSchema }) => componentSchema);

  const Components = schema
    .map((componentSchema, index) => {
      return schemaToComponent({
        componentSchema,
        schema,
        secondaryStepStaticData,
        isSecondaryStep,
        onCompletedActiveComponentName,
        submitOnChange,
        form,
        onSubmit,
        currency,
        siteConfig,
        prefillValues,
        index: index,
        displayProperties: {
          activeElement,
          setActiveElement,
          index,
          schema: componentsToRender,
        } as InputFieldDisplayProperties,
        isTouched,
        disableSteppedComponents,
      });
    })
    .filter(({ Component }) => Component);

  return Components;
};
