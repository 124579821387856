import React, { ReactNode, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { Input } from 'reactstrap';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { globalStyles } from 'rootstrap/global-styles';

export const RadioField = ({
  label,
  name,
  isDisabled,
  form,
  options,
  defaultValue,
  prefillAction,
  prefillValue,
  disableScrollToElement,
  hideDivider,
  displayProperties,
  validators,
  disableNextButton,
  disableTitle,
  isTouched: defaultIsTouched,
  disableActiveElement,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<string> & { options: { label: ReactNode; value: any; isDisabled?: boolean }[] }) => {
  const checkedValue = form?.watch(name);
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = useState<boolean>(!!checkedValue || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { activeElement, setActiveElement } = displayProperties;

  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: options as { label: string; value: any }[],
    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: undefined,
      }),
  });

  useEffect(() => {
    setTouched(!!prefillValue || defaultIsTouched);
  }, [defaultIsTouched]);

  const doesValueMatchSelected = (selected: string, value: string | boolean) => {
    const selectedOrDefault = isTouched ? selected : defaultValue;

    if (typeof value === 'boolean') {
      return value === false ? selectedOrDefault === 'false' : selectedOrDefault === 'true';
    }

    return selectedOrDefault === value;
  };

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        disableActiveElement={disableActiveElement}
        isActive={isActive}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          isActive={isActive}
          hiddenComponent={hiddenComponent}
          name={name}
          disableTitle={disableTitle}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          style={{
            color: getSteppedFormLabelColor({ isActive: isActive || isTouched, siteConfig, color: Colors.Body }),
          }}
        >
          <Controller
            defaultValue={defaultValue}
            name={name}
            control={form?.control}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                }),
            }}
            render={({ onChange }) => {
              return (
                <>
                  <RadioButtonWrapper
                    style={{
                      fontSize: globalStyles.fontSize.body,
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    {options.map((option, index) => {
                      const isOptionDisabled = disabledFromPrefill || isDisabled || option.isDisabled;
                      const cursor = isOptionDisabled ? 'not-allowed' : 'pointer';
                      return (
                        <>
                          <StyledRadioLabel
                            isActive={isActive || isTouched}
                            siteConfig={siteConfig}
                            borderColor={getColor({ siteConfig, color: 'border' })}
                            isSelected={doesValueMatchSelected(checkedValue, option.value)}
                            color={
                              isActive || isTouched
                                ? getColor({ siteConfig: siteConfig, color: 'highlight' })
                                : `${getColor({ siteConfig: siteConfig, color: 'highlight' })}80`
                            }
                            isFirstItem={index === 0}
                            isLastItem={index === options.length - 1}
                          >
                            <Radio
                              siteConfig={siteConfig}
                              isActive={isActive}
                              isSelected={doesValueMatchSelected(checkedValue, option.value)}
                            />
                            <Input
                              id={name}
                              isActive={isActive || isTouched}
                              name={name}
                              siteConfig={siteConfig}
                              disabled={isOptionDisabled}
                              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                onEnterKeyDown({
                                  e,
                                  nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                                  setFieldError: (fieldError) => setFieldError(fieldError),
                                  setActiveElement: (elementId) =>
                                    setActiveElement({
                                      elementId,
                                    }),
                                  getValue: () => form?.watch(name),
                                  validationFunction: getValidationMessage,
                                  validators,
                                  validationProps: undefined,
                                  setIsTouched: (isTouched) => setTouched(isTouched),
                                })
                              }
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setTouched(true);
                                onChange(value);

                                const fieldError = getValidationMessage({
                                  validators: validators,
                                  value,
                                  props: undefined,
                                });
                                setFieldError(fieldError);

                                if (!fieldError) {
                                  setActiveElement &&
                                    setActiveElement({
                                      elementId: getNextComponentId({ ...displayProperties, key: name }),
                                    });
                                }
                              }}
                              value={option.value}
                              type='radio'
                              invalid={!!fieldError}
                              style={{ opacity: 1, cursor }}
                              onBlur={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                            <div className='text-container' style={{ cursor }}>
                              <span>{option.label}</span>
                            </div>
                          </StyledRadioLabel>
                        </>
                      );
                    })}
                  </RadioButtonWrapper>
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            hideDivider={hideDivider}
            setTouched={(isTouched) => setTouched(isTouched)}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            disableNextButton={disableNextButton}
            validationProps={undefined}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};

const Radio = styled.div<{
  isSelected: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
}>`
  border-radius: 3px;
  background: white;
  background-repeat: no-repeat;
  background-position: left;
  width: 20px;
  height: 20px;
  background-image: url('${({ isSelected, siteConfig, isActive }) => {
    if (isSelected) {
      return selectedSvgString(getColor({ siteConfig, color: 'highlight' }));
    }
    return unselectedSvgString(getColor({ siteConfig, color: 'disabled' }));
  }}');
  margin-right: 10px;
`;

export const RadioButtonOptionsContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px !important;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #fff;
    cursor: not-allowed;`}
`;

export const StyledRadioLabel = styled.label<{
  isSelected: boolean;
  color: string;
  isFirstItem: boolean;
  isLastItem: boolean;
  borderColor: string;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: left;
  margin-bottom: 0px;
  height: 40px;
  .text-container {
    text-align: left;
    width: 100%;
  }
`;

const selectedSvgString = (color: string) => `data:image/svg+xml;utf8,
  <svg width="19" height="19" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
  <circle stroke="${color.replace('#', '%23')}" fill="white" cx="13.0967" cy="13" r="12" />  
  <circle fill="${color.replace('#', '%23')}" cx="13.0968" cy="12.9996" r="8.4" />
  </svg>`;

const unselectedSvgString = (color: string) => `data:image/svg+xml;utf8,
  <svg width="19" height="19" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
  <circle stroke="${color.replace('#', '%23')}" fill="white" cx="13.0967" cy="13" r="12" />  
  </svg>`;

export const RadioButtonWrapper = styled.div`
  .form-check-input {
    display: none !important;
    position: inherit !important;
  }
`;
