import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardGroup } from 'rootstrap/components/card';
import {
  InputField,
  InputFieldDisplayProperties,
  PrefillAction,
} from 'rootstrap/components/forms/new-fields/input-field';
import { Col, Row } from 'reactstrap';
import { IdTypeSelectField } from 'rootstrap/components/forms/new-fields/id-type-select-field';
import { usePromise } from 'shared/hooks/promise';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { useSiteConfigContext } from 'style-context';
import { IdentificationType, SelectableIdType } from 'policyholder/domain/policyholder-identification';
import { debounce } from 'utils';
import { DatePickerField } from 'rootstrap/components/forms/new-fields/date-picker-field';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { Moment } from 'moment';
import { ProductModule } from 'product-modules/domain/product-module';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { Gender } from 'models/gender';
import { PersonalDetailsInputs, PolicyholderPrefillValues } from './personal-details';
import { CountrySelectField } from 'rootstrap/components/forms/new-fields/extended-components/country-select-field';
import { TextField } from 'rootstrap/components/forms/new-fields/extended-components/text-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import {
  getDisplayOptionalSections,
  getWording,
  getWordingForIdTypeHandlebars,
  ProductModuleEmbedPersonalDetailsContactDetailTypes,
} from 'site-config';
import { getIdType, getIdTypeValue, getIdTypes, isPrefilledFromLookupData } from '../utils/helpers';
import { SteppedComponentsBehavior } from 'rootstrap/components-old/root-schema-form/utils/stepped-components-behavior';
import { PolicyholderLookUpFormData } from './unauthenticated-policyholder-lookup';

export interface PersonalDetailsIdentificationData {
  firstName?: string;
  lastName?: string;
  idType?: IdentificationType;
  identificationNumber?: string;
  [PersonalDetailsInputs.IdentificationCountry]?: string;
  [PersonalDetailsInputs.IdentificationExpirationDate]?: Moment;
  dateOfBirth?: Moment;
  gender?: Gender;
}

interface Props {
  setIsValid: (identificationIsValid: boolean) => void;
  setSectionData: (IdentificationSectionParams: PersonalDetailsIdentificationData) => void;
  identificationSectionData: PersonalDetailsIdentificationData | undefined;
  productModule?: ProductModule;
  prefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  isCompleted: boolean;
  isPolicyholderTypeFieldDisplayed: boolean;
  isCompany?: boolean;
  policyholderLookupFormData: PolicyholderLookUpFormData | undefined;
}

export const IdentificationSection = (props: Props) => {
  const {
    setIsValid,
    setSectionData,
    identificationSectionData,
    productModule,
    isCompleted,
    policyholderLookupFormData,
  } = props;
  const { siteConfig } = useSiteConfigContext();
  const { Id, Passport, Cellphone, Email } = IdentificationType;
  const { prefillValues, activeElement, setActiveElement, isCompany } = props;
  const { firstName, lastName, identification, dateOfBirth, gender } = prefillValues;
  const [isTouched] = useState<boolean>(isCompleted);

  const isGenderHidden = Boolean(
    productModule?.productModuleDefinition?.settings.policyholder.individualPolicyholderFields?.gender?.hidden,
  );

  const policyholderSettings = productModule?.productModuleDefinition?.settings.policyholder;
  const individualPolicyholderFields = policyholderSettings?.individualPolicyholderFields;
  const isDateOfBirthRequired = individualPolicyholderFields?.dateOfBirth?.required ?? true;

  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const steppedComponentsBehavior: SteppedComponentsBehavior = {
    disableNextButton: disableSteppedComponents,
    hideDivider: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents ? isTouched : true,
  };

  const unFilteredIdTypes = productModule?.productModuleDefinition?.settings.policyholder.idTypes || [];

  const isCustomAndHidden = Boolean(
    (prefillValues.identification?.type === IdentificationType.Custom ||
      (unFilteredIdTypes.length === 1 && unFilteredIdTypes.includes(IdentificationType.Custom))) &&
      prefillValues?.identification?.number,
  );

  const displayEmailAddress = getDisplayOptionalSections({
    displayOptionalSection: siteConfig?.personalDetails.displayOptionalSections.contactDetailTypes.includes(
      ProductModuleEmbedPersonalDetailsContactDetailTypes.Email,
    ),
  });

  usePromise(async () => {
    await form.trigger();
  }, []);

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: identificationSectionData,
      }),
      [],
    ),
  });
  form.watch();

  usePromise(async () => {
    setIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  const execute = () => {
    const formValues = form.getValues() as PersonalDetailsIdentificationData;
    if (isCustomAndHidden) {
      formValues.idType = IdentificationType.Custom;
      formValues.identificationNumber = prefillValues.identification?.number;
    }
    setSectionData(formValues);
  };

  if (!productModule) {
    return (
      <FormDetailsWrapper siteConfig={siteConfig}>
        <LoadingLines />
      </FormDetailsWrapper>
    );
  }

  const idType = getIdType({
    formIdType: form.watch(PersonalDetailsInputs.IdType) as IdentificationType,
    productModule,
  });
  const idTypes = getIdTypes(productModule);

  return (
    <CardGroup>
      <form
        onBlur={() => debounce('', () => execute())} // NB need to find another way around the
        onChange={() => execute()}
        onSubmit={form.handleSubmit((data: PersonalDetailsIdentificationData) => setSectionData(data))}
      >
        <Row>
          <Col sm={12}>
            <InputField
              placeholder={undefined}
              defaultValue={identificationSectionData?.firstName}
              name={PersonalDetailsInputs.FirstName}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.firstName.label })}
              form={form}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              prefillValue={firstName}
              prefillAction={siteConfig?.inputFields.personalDetails.firstName.prefillAction}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: PersonalDetailsInputs.LastName,
                } as InputFieldDisplayProperties
              }
              {...steppedComponentsBehavior}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InputField
              placeholder={undefined}
              defaultValue={identificationSectionData?.lastName}
              validators={[
                {
                  validation: {
                    type: ValidationTypes.REQUIRED,
                  },
                },
              ]}
              name={PersonalDetailsInputs.LastName}
              label={getWording({ wording: siteConfig?.inputFields.personalDetails.lastName.label })}
              form={form}
              prefillValue={lastName}
              prefillAction={siteConfig?.inputFields.personalDetails.lastName.prefillAction}
              displayProperties={
                {
                  activeElement,
                  setActiveElement,
                  nextComponentName: isCompany
                    ? PersonalDetailsInputs.Email
                    : isCustomAndHidden
                    ? PersonalDetailsInputs.DateOfBirth
                    : PersonalDetailsInputs.IdType,
                } as InputFieldDisplayProperties
              }
              {...steppedComponentsBehavior}
            />
          </Col>
        </Row>
        {!isCompany && !isCustomAndHidden && (
          <Row>
            <Col sm={12}>
              <IdTypeSelectField
                clearable={true}
                placeholder={undefined}
                idTypes={idTypes}
                defaultValue={
                  policyholderLookupFormData?.idType ||
                  getIdTypeValue({
                    identificationType: identificationSectionData?.idType,
                    idTypes,
                  })
                }
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                name={PersonalDetailsInputs.IdType}
                label={getWording({ wording: siteConfig?.inputFields.personalDetails.idType.label })}
                prefillAction={
                  policyholderLookupFormData?.idType
                    ? PrefillAction.Disabled
                    : siteConfig?.inputFields.personalDetails.idType.prefillAction
                }
                form={form}
                isDisabled={idTypes.length <= 1}
                prefillValue={policyholderLookupFormData?.idType || identification?.type}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement: (actions) => {
                      form.watch(PersonalDetailsInputs.IdType) &&
                        setActiveElement({
                          elementId: idTypeNextComponent(
                            form.watch(PersonalDetailsInputs.IdType) as IdentificationType,
                          ),
                        });
                    },
                    nextComponentName: idTypeNextComponent(form.watch(PersonalDetailsInputs.IdType) as any),
                  } as InputFieldDisplayProperties
                }
                {...steppedComponentsBehavior}
              />
            </Col>
            {idType === Id && (
              <Col sm={12}>
                <TextField
                  placeholder={undefined}
                  defaultValue={
                    isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Id)
                      ? policyholderLookupFormData?.identificationNumber
                      : identificationSectionData?.identificationNumber
                  }
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                    {
                      validation: {
                        type: ValidationTypes.ZAId,
                      },
                    },
                  ]}
                  name={PersonalDetailsInputs.IdentificationNumber}
                  label={getWordingForIdTypeHandlebars({
                    wording: siteConfig?.inputFields.personalDetails.identificationNumber.label,
                    identificationType: idType,
                  })}
                  form={form}
                  prefillValue={policyholderLookupFormData?.identificationNumber || identification?.number}
                  prefillAction={
                    isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Id)
                      ? PrefillAction.Disabled
                      : siteConfig?.inputFields.personalDetails.identificationNumber.prefillAction
                  }
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: !displayEmailAddress
                        ? PersonalDetailsInputs.Cellphone
                        : PersonalDetailsInputs.Email,
                    } as InputFieldDisplayProperties
                  }
                  {...steppedComponentsBehavior}
                />
              </Col>
            )}
            {idType === Passport && (
              <Col sm={12}>
                <TextField
                  placeholder={undefined}
                  defaultValue={
                    isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Passport)
                      ? policyholderLookupFormData?.identificationNumber
                      : identificationSectionData?.identificationNumber
                  }
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  name={PersonalDetailsInputs.IdentificationNumber}
                  label={getWordingForIdTypeHandlebars({
                    wording: siteConfig?.inputFields.personalDetails.identificationNumber.label,
                    identificationType: idType,
                  })}
                  prefillAction={
                    isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Passport)
                      ? PrefillAction.Disabled
                      : siteConfig?.inputFields.personalDetails.identificationNumber.prefillAction
                  }
                  form={form}
                  prefillValue={
                    isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Passport)
                      ? policyholderLookupFormData?.identificationNumber
                      : identification?.number
                  }
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: PersonalDetailsInputs.IdentificationCountry,
                    } as InputFieldDisplayProperties
                  }
                  {...steppedComponentsBehavior}
                />
              </Col>
            )}
          </Row>
        )}
        <Row>
          {!isCompany &&
            (idType === IdentificationType.Cellphone ||
              idType === IdentificationType.Email ||
              idType === IdentificationType.Passport ||
              idType === IdentificationType.Custom) && (
              <Col sm={12}>
                {![IdentificationType.Email, IdentificationType.Cellphone].includes(idType) && (
                  <CountrySelectField
                    clearable={true}
                    placeholder={undefined}
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                    ]}
                    form={form}
                    label={getWordingForIdTypeHandlebars({
                      wording: siteConfig?.inputFields.personalDetails.identificationCountry.label,
                      identificationType: idType,
                    })}
                    prefillAction={
                      isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Passport)
                        ? PrefillAction.Disabled
                        : siteConfig?.inputFields.personalDetails.identificationCountry.prefillAction
                    }
                    name={PersonalDetailsInputs.IdentificationCountry}
                    defaultValue={
                      isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Passport)
                        ? policyholderLookupFormData?.identificationCountry
                        : identificationSectionData &&
                          identificationSectionData[PersonalDetailsInputs.IdentificationCountry]
                    }
                    prefillValue={
                      isPrefilledFromLookupData(policyholderLookupFormData, SelectableIdType.Passport)
                        ? policyholderLookupFormData?.identificationCountry
                        : identification?.country
                    }
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                        nextComponentName: PersonalDetailsInputs.DateOfBirth,
                      } as InputFieldDisplayProperties
                    }
                    {...steppedComponentsBehavior}
                  />
                )}
              </Col>
            )}
          {!isCompany && idType === Passport && (
            <Col sm={12}>
              <DatePickerField
                placeholder={undefined}
                validators={
                  isDateOfBirthRequired
                    ? [
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]
                    : []
                }
                form={form}
                label={getWording({ wording: siteConfig?.inputFields.personalDetails.dateOfBirth.label })}
                name={PersonalDetailsInputs.DateOfBirth}
                defaultValue={identificationSectionData?.dateOfBirth}
                prefillValue={dateOfBirth}
                prefillAction={siteConfig?.inputFields.personalDetails.dateOfBirth.prefillAction}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: isGenderHidden
                      ? PersonalDetailsInputs.IdentificationExpirationDate
                      : PersonalDetailsInputs.Gender,
                  } as InputFieldDisplayProperties
                }
                {...steppedComponentsBehavior}
              />
            </Col>
          )}
        </Row>
        {idType === Passport && (
          <Row>
            {!isGenderHidden && (
              <Col sm={12}>
                <SelectField
                  clearable={true}
                  placeholder={undefined}
                  options={genderSelectOptions}
                  form={form}
                  label={getWording({ wording: siteConfig?.inputFields.personalDetails.gender.label })}
                  prefillAction={siteConfig?.inputFields.personalDetails.gender.prefillAction}
                  name={PersonalDetailsInputs.Gender}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  defaultValue={identificationSectionData?.gender}
                  prefillValue={gender}
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: PersonalDetailsInputs.IdentificationExpirationDate,
                    } as InputFieldDisplayProperties
                  }
                  {...steppedComponentsBehavior}
                />
              </Col>
            )}
            {idType === Passport && (
              <Col sm={12}>
                <DatePickerField
                  placeholder={undefined}
                  validators={
                    isDateOfBirthRequired
                      ? [
                          {
                            validation: {
                              type: ValidationTypes.REQUIRED,
                            },
                          },
                        ]
                      : []
                  }
                  form={form}
                  label={getWordingForIdTypeHandlebars({
                    wording: siteConfig?.inputFields.personalDetails.identificationExpirationDate.label,
                    identificationType: idType,
                  })}
                  prefillAction={siteConfig?.inputFields.personalDetails.identificationExpirationDate.prefillAction}
                  name={PersonalDetailsInputs.IdentificationExpirationDate}
                  defaultValue={identificationSectionData?.identificationExpirationDate}
                  prefillValue={identification?.expirationDate}
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: !displayEmailAddress
                        ? PersonalDetailsInputs.Cellphone
                        : PersonalDetailsInputs.Email,
                    } as InputFieldDisplayProperties
                  }
                  {...steppedComponentsBehavior}
                />
              </Col>
            )}
          </Row>
        )}
        {([Email, Cellphone].includes(idType) || isCustomAndHidden) && (
          <Row>
            <Col sm={12}>
              <DatePickerField
                placeholder={undefined}
                validators={[
                  {
                    validation: {
                      type: ValidationTypes.REQUIRED,
                    },
                  },
                ]}
                form={form}
                label={getWording({ wording: siteConfig?.inputFields.personalDetails.dateOfBirth.label })}
                prefillAction={siteConfig?.inputFields.personalDetails.dateOfBirth.prefillAction}
                name={PersonalDetailsInputs.DateOfBirth}
                defaultValue={identificationSectionData?.dateOfBirth}
                prefillValue={dateOfBirth}
                displayProperties={
                  {
                    activeElement,
                    setActiveElement,
                    nextComponentName: isGenderHidden ? PersonalDetailsInputs.Email : PersonalDetailsInputs.Gender,
                  } as InputFieldDisplayProperties
                }
                {...steppedComponentsBehavior}
              />
            </Col>
            {!isGenderHidden && (
              <Col sm={12}>
                <SelectField
                  clearable={true}
                  placeholder={undefined}
                  options={genderSelectOptions}
                  form={form}
                  label={getWording({ wording: siteConfig?.inputFields.personalDetails.gender.label })}
                  prefillAction={siteConfig?.inputFields.personalDetails.gender.prefillAction}
                  name={PersonalDetailsInputs.Gender}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  defaultValue={identificationSectionData?.gender}
                  prefillValue={identification?.gender}
                  displayProperties={
                    {
                      activeElement,
                      setActiveElement,
                      nextComponentName: PersonalDetailsInputs.Email,
                    } as InputFieldDisplayProperties
                  }
                  {...steppedComponentsBehavior}
                />
              </Col>
            )}
          </Row>
        )}
      </form>
    </CardGroup>
  );
};

const idTypeNextComponent = (idType: IdentificationType) => {
  const nextField = {
    [IdentificationType.Cellphone]: PersonalDetailsInputs.DateOfBirth,
    [IdentificationType.Email]: PersonalDetailsInputs.DateOfBirth,
    [IdentificationType.Id]: PersonalDetailsInputs.IdentificationNumber,
    [IdentificationType.Passport]: PersonalDetailsInputs.IdentificationNumber,
    [IdentificationType.Custom]: PersonalDetailsInputs.IdentificationCountry,
    [IdentificationType.Company]: PersonalDetailsInputs.CompanyName,
  };

  return nextField[idType];
};

const genderSelectOptions = [
  {
    label: 'Male',
    value: Gender.Male,
  },
  {
    label: 'Female',
    value: Gender.Female,
  },
];
