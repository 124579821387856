import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { PrefillAction } from 'rootstrap/components/forms/new-fields/input-field';
import { globalStyles } from 'rootstrap/global-styles';
import { Colors } from 'rootstrap/global-styles/colors';
import { FontFamily } from 'rootstrap/global-styles/font-family';

export enum ProductModuleEmbedConfigSupportType {
  Email = 'email',
  Url = 'url',
}

export enum EmbeddedConfigSection {
  Header = 'header',
  Landing = 'landing',
  Quote = 'quote',
  PrePersonalDetailsCompliance = 'prePersonalDetailsCompliance',
  PersonalDetails = 'personalDetails',
  Application = 'application',
  Summary = 'summary',
  PrePaymentCompliance = 'prePaymentCompliance',
  Beneficiaries = 'beneficiaries',
  Payment = 'payment',
  Confirmation = 'confirmation',
  Footer = 'footer',
  Styles = 'styles',
  PolicyIssuingIFrameUrl = 'policyIssuingIFrameUrl',
  Settings = 'settings',
  Management = 'management',
  InputFields = 'inputFields',
}

export enum ProductModuleEmbedConfigIssuingFlowStartingStep {
  PersonalDetails = 'personalDetails',
  Default = 'default',
}

export enum EmbeddedConfigManagementSections {
  PolicyView = 'policyView',
  PolicyDetails = 'policyDetails',
  Beneficiaries = 'beneficiaries',
  Claim = 'claim',
  Payment = 'payment',
}

export interface EmbedInputField {
  label: string;
  prefillAction: PrefillAction;
}

export interface EmbedLabelOnlyInputField {
  label: string;
}

export interface PersonalDetailsInputFields {
  firstName: EmbedInputField;
  lastName: EmbedInputField;
  idType: EmbedInputField;
  identificationNumber: EmbedInputField;
  identificationCountry: EmbedInputField;
  identificationExpirationDate: EmbedInputField;
  gender: EmbedInputField;
  dateOfBirth: EmbedInputField;
  email: EmbedInputField;
  cellphone: EmbedInputField;
  internationalNumber: EmbedInputField;
  addressOptIn: EmbedInputField;
  addressLine1: EmbedInputField;
  addressLine2: EmbedInputField;
  areaCode: EmbedInputField;
  suburb: EmbedInputField;
  city: EmbedInputField;
  country: EmbedInputField;
  registrationNumber: EmbedInputField;
  companyName: EmbedInputField;
  subsidiaryCompanies: EmbedInputField;
  dateOfEstablishment: EmbedInputField;
  companyWebsiteUrl: EmbedInputField;
  policyholderType: EmbedInputField;
  contactPosition: EmbedInputField;
  fetchifyAutocomplete?: EmbedInputField;
  googlePlacesAutocomplete: EmbedLabelOnlyInputField;
}

export interface BeneficiaryInputFields {
  title: EmbedLabelOnlyInputField;
  firstName: EmbedLabelOnlyInputField;
  lastName: EmbedLabelOnlyInputField;
  relationship: EmbedLabelOnlyInputField;
  idType: EmbedLabelOnlyInputField;
  identificationNumber: EmbedLabelOnlyInputField;
  identificationCountry: EmbedLabelOnlyInputField;
  gender: EmbedLabelOnlyInputField;
  email: EmbedLabelOnlyInputField;
  cellphone: EmbedLabelOnlyInputField;
}
export const getWording = (params: { wording?: string }) => {
  return params.wording || '-';
};

const prettyIdType: {
  [key in IdentificationType]: string;
} = {
  [IdentificationType.Id]: 'identification',
  [IdentificationType.Passport]: 'passport',
  [IdentificationType.Email]: 'email',
  [IdentificationType.Cellphone]: 'cellphone',
  [IdentificationType.Company]: 'company',
  [IdentificationType.Custom]: 'custom',
};

// TODO fix this once the historical data is sorted
export const getWordingForIdTypeHandlebars = (params: {
  wording?: string;
  identificationType?: IdentificationType;
}) => {
  const { identificationType, wording } = params;
  const prettyIdentificationType = identificationType ? prettyIdType[identificationType] : '';

  const sentence = getWording({ wording })
    .replace('<selected_identification_type>', prettyIdentificationType)
    .replace('{{selected_identification_type}}', prettyIdentificationType)
    .replace('{{ selected_identification_type }}', prettyIdentificationType);

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const getLink = (params: { link?: string }) => {
  return params.link || '';
};

export const getDisplayOptionalSections = (params: { displayOptionalSection?: boolean }) => {
  return params.displayOptionalSection || false;
};

export const getColor = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
  color: keyof ProductModuleDefinitionEmbeddedConfigColors;
  defaultColor?: Colors;
}): string => {
  const { siteConfig, color } = params;
  const defaultColor = !!params.defaultColor ? params.defaultColor : Colors.Body;
  return siteConfig?.styles.colors[color] || defaultColor;
};

export const getFontFamily = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
  fontFamily: keyof ProductModuleDefinitionEmbeddedFontFamily;
}) => {
  const { siteConfig, fontFamily } = params;
  return siteConfig?.styles.fontFamily[fontFamily] || FontFamily.body;
};

export const getFontSize = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
  fontSize: keyof ProductModuleDefinitionEmbeddedFontSize;
}) => {
  const { siteConfig, fontSize } = params;
  return siteConfig?.styles.fontSize[fontSize] || globalStyles.colors.Body;
};

export const getBorderRadius = (params: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null | null;
  borderRadius: keyof ProductModuleDefinitionEmbeddedBorderRadius;
}) => {
  const { siteConfig, borderRadius } = params;
  return siteConfig?.styles.borderRadius[borderRadius] || globalStyles.colors.Body;
};

export enum ProductModuleEmbedPersonalDetailsContactDetailTypes {
  Email = 'email',
  Cellphone = 'cellphone',
}

export interface ProductModuleDefinitionEmbeddedConfig {
  [EmbeddedConfigSection.Header]: {
    wording: {
      title: string;
    };
    images: {
      titleUrl?: string;
    };
    links: {};
    displayOptionalSections: {
      titleImage: boolean;
      title: boolean;
      premiumInProgressBar: boolean;
    };
  };
  [EmbeddedConfigSection.Landing]: {
    wording: {
      title: string;
      subTitle: string;
      description: string[];
      createQuoteButton: string;
      videoTitle?: string;
      descriptionBullets?: string[];
    };
    images: {
      background: string;
    };
    links: {
      youTubeVideoId?: string;
    };
    displayOptionalSections: {
      displayLandingPage: boolean;
      watchVideo: boolean;
      descriptionBullets: boolean;
    };
  };
  [EmbeddedConfigSection.Quote]: {
    wording: {
      title: string;
      description: string;
      summary: string[];
      coverRejected: string;
      coverRejectedEmail?: string;
      screeningQuestionsRejected?: string;
      screeningQuestionsDescription?: string;
      screeningQuestions?: {
        key: string;
        header: string;
        question: string;
      }[];
      consentDisclaimer?: {
        title: string;
        consentItems: string[];
      };
    };
    images: {};
    links: {
      exitRedirect?: string;
      supportEmail?: string;
      supportUrl?: { label: string; url: string };
      supportType: ProductModuleEmbedConfigSupportType;
    };

    displayOptionalSections: {
      screeningQuestions: boolean;
      screeningQuestionsRetry: boolean;
      consentDisclaimer: boolean;
    };
  };
  [EmbeddedConfigSection.PersonalDetails]: {
    wording: {
      title: string;
      description: string;
    };
    images: {};
    links: {};
    displayOptionalSections: {
      skipOnPrefill: boolean;
      fetchifyAutoComplete: boolean;
      contactDetailTypes: ProductModuleEmbedPersonalDetailsContactDetailTypes[];
    };
  };
  [EmbeddedConfigSection.InputFields]: {
    personalDetails: PersonalDetailsInputFields;
    beneficiaries: BeneficiaryInputFields;
  };
  [EmbeddedConfigSection.PrePersonalDetailsCompliance]:
    | {
        wording: {
          title: string;
          description: string;
          content: string;
        };
        images: {};
        links: { exitRedirect: string | undefined };
        displayOptionalSections: {
          displayPrePersonalDetailsCompliance: boolean;
        };
      }
    | undefined;
  [EmbeddedConfigSection.Application]: {
    wording: {
      title: string;
      description: string;
    };
    images: {};
    links: {};
    displayOptionalSections: {};
  };
  [EmbeddedConfigSection.Beneficiaries]?: {
    wording: {
      title: string;
      description: string;
    };
    images: {};
    links: {};
    displayOptionalSections: {
      displayManageBeneficiaries: boolean;
    };
  };
  [EmbeddedConfigSection.PrePaymentCompliance]:
    | {
        wording: {
          title: string;
          description: string;
          content: string;
        };
        images: {};
        links: { exitRedirect: string | undefined };
        displayOptionalSections: {
          displayPrePaymentCompliance: boolean;
        };
      }
    | undefined;
  [EmbeddedConfigSection.Payment]: {
    wording: {
      title: string;
      description: string;
      declaration: string;
      summary: LegacyModuleData[] | string[];
    };
    images: {};
    links: {};
    displayOptionalSections: {
      billingDay: boolean;
      editBillingDay: boolean;
      displayPaymentMethod: boolean;
      displayPaymentDeclaration: boolean;
    };
  };
  [EmbeddedConfigSection.Confirmation]: {
    wording: {
      title: string;
      subTitle: string;
      description: string;
      contactNumber: string;
      secondarySubTitle: string;
      secondaryButton: string;
      contactNumberDescription: string;
      primaryButton: string;
    };
    images: {};
    links: {
      redirectOnCompletedUrl: string;
      openClaim: string;
    };
    displayOptionalSections: {
      contactNumber: boolean;
      displayConfirmation: boolean;
      contactNumberDescription: boolean;
      secondaryButton: boolean;
      secondarySubTitle: boolean;
      primaryButton: boolean;
    };
  };
  [EmbeddedConfigSection.Footer]: {
    wording: {
      disclaimer: string;
    };
    images: {
      disclaimerUrl: string;
    };
    links: {};
    displayOptionalSections: {
      disclaimerImage: boolean;
    };
  };
  [EmbeddedConfigSection.Styles]: {
    colors: ProductModuleDefinitionEmbeddedConfigColors;
    fontFamily: ProductModuleDefinitionEmbeddedFontFamily;
    fontSize: ProductModuleDefinitionEmbeddedFontSize;
    borderRadius: ProductModuleDefinitionEmbeddedBorderRadius;
    position: ProductModuleDefinitionEmbeddedPosition;
    disableSteppedComponents: boolean;
  };
  [EmbeddedConfigSection.PolicyIssuingIFrameUrl]?: string;
  [EmbeddedConfigSection.Settings]: {
    issuingFlowStartingStep: ProductModuleEmbedConfigIssuingFlowStartingStep;
    defaultCountryCodeFromBrowser: boolean;
    supportEmail?: string;
    supportUrl?: { label: string; url: string };
    supportType: ProductModuleEmbedConfigSupportType;
    stripePublishableKey?: string;
    enableSessionPersistence: boolean;
    enabledFetchify: boolean;
  };
  [EmbeddedConfigSection.Management]?: {
    [EmbeddedConfigManagementSections.PolicyDetails]: {
      wording: {
        description: string;
        title: string;
        summary: { label: string; content: string }[];
      };
      images: {};
      links: {
        exitRedirect: string;
      };
      displayOptionalSections: {};
    };
    [EmbeddedConfigManagementSections.Beneficiaries]: {
      wording: {
        title: string;
      };
      images: {};
      links: {
        exitRedirect: string;
      };
      displayOptionalSections: {};
    };
    [EmbeddedConfigManagementSections.Claim]: {
      wording: {
        title: string;
        description: string;
        contactNumber: string;
        callToAction: string;
      };
      images: {};
      links: {
        openClaim: string;
        exitRedirect: string;
      };
      displayOptionalSections: {
        contactNumber: boolean;
        callToAction: boolean;
      };
    };
    [EmbeddedConfigManagementSections.Payment]: {
      wording: {
        title: string;
        description: string;
      };
      images: {};
      links: {
        exitRedirect: string;
      };
      displayOptionalSections: {
        editPaymentMethod: boolean;
        viewPaymentMethod: boolean;
        editBillingDay: boolean;
      };
    };
    [EmbeddedConfigManagementSections.PolicyView]: {
      wording: {
        title: string;
        description: string;
        contactDescription: string;
        contactNumber: string;
      };
      images: {};
      links: {
        exitRedirect: string;
      };
      displayOptionalSections: {
        contactNumber: boolean;
      };
    };
  };
}

export interface ProductModuleDefinitionEmbeddedBorderRadius {
  button: string;
}

export interface ProductModuleDefinitionEmbeddedPosition {
  title: 'center' | 'left' | 'right';
}

export interface ProductModuleDefinitionEmbeddedFontSize {
  title: string;
  subTitle: string;
  body: string;
  subscript: string;
  button: string;
  footer: string;
}
export interface ProductModuleDefinitionEmbeddedFontFamily {
  title: string;
  body: string;
}

export interface ProductModuleDefinitionEmbeddedConfigColors {
  // Primary colors
  primary: string;
  highlight: string;
  dark: string;
  light: string;

  // Interface colors
  success: string;
  warning: string;
  error: string;
  disabled: string;

  // Backgrounds and borders
  backgroundHighlight: string;
  border: string;

  policyStatusActive: string;
  policyStatusCancelled: string;
  policyStatusPending: string;
  policyStatusExpired: string;
  policyStatusNotTakenUp: string;
  policyStatusLapsed: string;
}

export interface LegacyModuleData {
  label: string;
  content: string;
}
