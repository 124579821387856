import React, { useMemo, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSiteConfigContext } from 'style-context';
import { usePromiseLazy } from 'shared/hooks/promise';
import { ExternalPaymentMethod, PaymentMethodAll } from 'payment-methods/domain/payment-method';
import { Policy } from 'policies/domain/policy';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { Policyholder } from 'policyholder/domain/policyholder';
import { updatePolicy } from 'policies/actions/update-policy';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { getExternalPaymentMethodTypes } from 'payment-methods/actions/get-external-payment-method-types';
import { createExternalPaymentMethod } from 'payment-methods/actions/create-external-payment-method';
import { BeneficiaryDetailsStyle, StyledBeneficiaryCol } from 'beneficiaries/styles/beneficiary-details.styles';
import { StaticFormWithTitleRowStyle } from 'rootstrap/components/forms/styles/static-form-with-title-row-style';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { ExternalPaymentMethodDetailsInput } from './external-payment-method-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { useForm } from 'react-hook-form';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import {
  StripePaymentElement,
  stripePaymentElementOptions,
  useStripePaymentMethod,
} from 'rootstrap/components/stripe/stripe-payment-element';
import styled from 'styled-components';

interface Props {
  policy: Policy;
  policyholder: Policyholder;
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  setPolicy: (policy: Policy) => void;
  setIsUpdatingOrCreatingPaymentMethod: (v: boolean) => void;
  setPaymentMethod: (p: PaymentMethodAll) => void;
  paymentMethod: ExternalPaymentMethod | undefined;
}

export const StripeCardForm = (params: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { auth, organizationId, environment } = embedParams;
  const {
    setActiveElement,
    setIsUpdatingOrCreatingPaymentMethod,
    policy,
    setPolicy,
    paymentMethod,
    policyholder,
    setPaymentMethod,
  } = params;
  const { submitPaymentMethod } = useStripePaymentMethod();
  const submitButtonRef = useRef<any>();
  const canEditPaymentMethod = siteConfig?.management?.payment.displayOptionalSections.editPaymentMethod;
  const canEditBillingDay = siteConfig?.management?.payment.displayOptionalSections.editBillingDay;

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });
  form.watch();

  const { execute, error, isLoading } = usePromiseLazy(async () => {
    const formValues = form.getValues() as { billingDay: number };
    const billingDay = formValues.billingDay;

    let stripePaymentMethodId: string | undefined;

    if (canEditPaymentMethod) {
      // NB each time the billing day is updated the stripe payment method will be created due to the fact that we don't have the detail and cant check all the data
      const paymentMethodId = await submitPaymentMethod({ policyholder });

      const externalPaymentMethodTypes = await getExternalPaymentMethodTypes({
        auth,
        environment,
        organizationId,
      });

      const updatedPaymentMethod = await createExternalPaymentMethod({
        billingDay,
        externalPaymentMethodType: externalPaymentMethodTypes[0].key,
        paymentReference: paymentMethodId,
        policyholderId: policyholder.policyholderId,
        auth,
        environment,
        organizationId,
        policyId: policy.policyId,
      });

      setPaymentMethod(updatedPaymentMethod);

      stripePaymentMethodId = paymentMethodId;
    }

    const updatedPolicy = await updatePolicy({
      environment,
      organizationId,
      auth,
      data: canEditBillingDay ? { billingDay } : {},
      policyId: policy.policyId,
      appData: {
        ...policy.appData,
        ...(stripePaymentMethodId ? { stripe_payment_method_id: stripePaymentMethodId } : {}),
      },
    });

    setPolicy(updatedPolicy);
    setIsUpdatingOrCreatingPaymentMethod(false);
  }, []);

  return (
    <BeneficiaryDetailsStyle style={{ paddingBottom: 60 }}>
      <ErrorAlert error={error?.message !== 'Could not submit stripe payment method' ? error : undefined} />
      {canEditPaymentMethod && <StripePaymentElement policyholder={policyholder} />}
      {
        <StyledForm onSubmit={form.handleSubmit(() => execute())}>
          {canEditBillingDay && (
            <StaticFormWithTitleRowStyle siteConfig={siteConfig}>
              <StyledBeneficiaryCol sm={12}>
                <BillingDaySelectField
                  isTouched={false}
                  clearable={true}
                  disableScrollToElement={true}
                  name={ExternalPaymentMethodDetailsInput.BillingDay}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  label='Debit day'
                  form={form}
                  defaultValue={policy.billingDay}
                  prefillValue={ExternalPaymentMethodDetailsInput.BillingDay}
                  placeholder={'Debit day'}
                  disableActiveElement={true}
                  hideDivider={true}
                  displayProperties={
                    {
                      activeElement: {
                        elementId: '',
                      },
                      setActiveElement,
                      nextComponentName: '',
                    } as InputFieldDisplayProperties
                  }
                />
              </StyledBeneficiaryCol>
            </StaticFormWithTitleRowStyle>
          )}
          <button style={{ display: 'none' }} ref={submitButtonRef} type='submit' />
          <LongButtonSuccess
            id='manage-payment-methods-submit-button'
            siteConfig={siteConfig}
            onClick={() => submitButtonRef.current.click()}
            disabled={isLoading}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
              </span>
            )}
            {paymentMethod ? 'Update' : 'Add'}
          </LongButtonSuccess>
        </StyledForm>
      }
    </BeneficiaryDetailsStyle>
  );
};

export const StripeCardPaymentMethodForm = (params: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const stripePublishableKey = siteConfig?.settings.stripePublishableKey;

  if (!stripePublishableKey) {
    return <div />;
  }

  const stripePromise = loadStripe(stripePublishableKey);
  return (
    <Elements stripe={stripePromise} options={stripePaymentElementOptions}>
      <StripeCardForm {...params} />
    </Elements>
  );
};

const StyledForm = styled.form`
  margin-top: 20px;
`;
