import React from 'react';
import { Validator } from 'rootstrap/components-old/root-schema-form/interfaces';
import { GetValidationMessage, ValidationProps } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { SteppedFormSuccessButton } from 'rootstrap/components/button/styles';
import { globalStyles } from 'rootstrap/global-styles';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { Divider } from '../divider';

interface SteppedFormButtonParams {
  isActive: boolean;
  nextComponentId: string | undefined;
  isDisabled: boolean;
  parentName: string;
  hideDivider: boolean | undefined;
  validationFunction: (getValidationMessage: GetValidationMessage) => string | undefined;
  validators: Validator[] | undefined;
  setFieldError: (fieldError: string) => void;
  setActiveElement: (elementId: string) => void;
  validationProps: ValidationProps | undefined;
  getValue: () => string | number | boolean | undefined;
  disableNextButton?: boolean;
  setTouched: (isTouched: boolean) => void;
  submitOnChange?: boolean;
  hideBorder: boolean | undefined;
  disableActiveElement: boolean;
}

export const SteppedFormButton = (params: SteppedFormButtonParams) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    isActive,
    parentName,
    hideDivider,
    hideBorder,
    validationFunction,
    validators,
    setFieldError,
    setActiveElement,
    nextComponentId,
    validationProps,
    getValue,
    disableNextButton,
    setTouched,
    submitOnChange,
    disableActiveElement,
  } = params;

  const steppedFormSuccessButtonDisplay = disableNextButton ? { display: 'none' } : {};

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActiveElement(parentName);
          setTouched(true);
        }}
      >
        {isActive && (submitOnChange ? true : !!nextComponentId) && (
          <SteppedFormSuccessButton
            siteConfig={siteConfig}
            style={steppedFormSuccessButtonDisplay}
            id={`stepped-form-next-button-${parentName}`}
            size='sm'
            onKeyDown={(e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              setTouched(true);

              const fieldError = validationFunction({
                validators,
                value: getValue && getValue(),
                props: validationProps,
              });

              if (fieldError) {
                return setFieldError(fieldError);
              }

              setActiveElement(nextComponentId || '');
            }}
            onClick={(e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              setTouched(true);
              const fieldError = validationFunction({
                validators,
                value: getValue && getValue(),
                props: validationProps,
              });

              if (fieldError) {
                return setFieldError(fieldError);
              }

              setActiveElement(nextComponentId || '');
            }}
          >
            Next
          </SteppedFormSuccessButton>
        )}
      </div>
      {!hideDivider && <Divider className='stepped-form-divider' />}
      {hideBorder && <StyledInputFooter />}
      {disableActiveElement && <StyledNonSteppedComponentDivider />}
    </>
  );
};

export const StyledInputFooter = styled.div`
  margin-top: 10px !important;
  height: 13px !important;
`;
interface OnEnterKeyDown {
  e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement> | KeyboardEvent;
  validationFunction: (getValidationMessage: GetValidationMessage) => string | undefined;
  validators: Validator[] | undefined;
  setFieldError: (fieldError: string) => void;
  setActiveElement: (elementId: string) => void;
  validationProps: ValidationProps | undefined;
  getValue: () => string | number | boolean | undefined;
  nextComponentId: string | undefined;
  setIsTouched: (isTouched: boolean) => void;
}

export const onEnterKeyDown = (params: OnEnterKeyDown) => {
  const {
    e,
    setActiveElement,
    setFieldError,
    validationFunction,
    validationProps,
    validators,
    getValue,
    nextComponentId,
    setIsTouched,
  } = params;
  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    setIsTouched(true);

    const fieldError = validationFunction({
      validators,
      value: getValue && getValue(),
      props: validationProps,
    });

    if (fieldError) {
      return setFieldError(fieldError);
    }

    setActiveElement(nextComponentId || '');
  }
};

export const getSteppedFormLabelColor = (params: {
  isActive: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  color: string;
  disableActiveElement?: boolean;
}) =>
  params.isActive || params.disableActiveElement
    ? params.color
    : getColor({ siteConfig: params.siteConfig, color: 'disabled' });

export const getSteppedFormInputStyle = (params: {
  isActive: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  disableActiveElement?: boolean;
}) => {
  const { isActive, siteConfig, disableActiveElement } = params;
  return {
    fontSize: globalStyles.fontSize.body,
    color: getSteppedFormLabelColor({ isActive, siteConfig, color: '#3d3d3d', disableActiveElement }),
    border: '1px solid #CED1DA',
    boxShadow: 'none',
    borderRadius: '8px',
    paddingLeft: '20px !important',
    cursor: 'pointer',
  };
};

const StyledNonSteppedComponentDivider = styled.div`
  margin-top: 25px;
`;
