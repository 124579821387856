import { isPlainObject, snakeCase } from 'lodash';
import { useEffect } from 'react';

export enum IssuingSceneStepKeys {
  ScreeningQuestions = 'screeningQuestions',
  QuoteInput = 'quoteInput',
  QuoteInputMultiStep1 = 'quoteInputMultiStep1',
  QuoteInputMultiStep2 = 'quoteInputMultiStep2',
  PrePersonalDetailsConsent = 'prePersonalDetailsConsent',
  Application = 'application',
  Beneficiaries = 'beneficiaries',
  PrePaymentConsent = 'prePaymentConsent',
  PersonalDetails = 'personalDetails',
  Payment = 'payment',
}

export const scrollTo = () => {
  const scrollable = document.getElementById('insurer-disclaimer-container');

  setTimeout(() => scrollable && scrollable.scrollIntoView({ behavior: 'smooth', block: 'end' }));
};

export const sanitizeModel = (model: any): any =>
  Object.keys(model).reduce(
    (output, key) => ({
      ...output,
      [snakeCase(key)]: isPlainObject(model[key]) ? sanitizeModel(model[key]) : model[key],
    }),
    {},
  );

export const useSetIframeHeight = (params: {
  iframeId: string;
  isLoading: boolean;
  modifiedHtmlContent: string | undefined;
}): void => {
  const { iframeId, isLoading, modifiedHtmlContent } = params;
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let currentInterval = 50; // Start with 1 second
    let previousHeight: number | null = null;
    const maxInterval = 1000; // Maximum interval of 10 seconds

    const checkHeightAndUpdateInterval = () => {
      const iframe = document.getElementById(iframeId) as HTMLIFrameElement | null;
      if (!iframe) return;

      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
      if (iframeDocument) {
        const height = iframeDocument.documentElement.offsetHeight;

        if (height !== previousHeight) {
          currentInterval = 50; // Reset interval
          previousHeight = height;
          iframe.style.height = `${height}px`;
        } else {
          currentInterval = Math.min(currentInterval * 2, maxInterval);
        }

        clearInterval(intervalId);
        intervalId = setInterval(checkHeightAndUpdateInterval, currentInterval);
      }
    };

    if (!isLoading && modifiedHtmlContent) {
      intervalId = setInterval(checkHeightAndUpdateInterval, currentInterval);
    }

    return () => clearInterval(intervalId);
  }, [isLoading, iframeId]);
};
