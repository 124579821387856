import { GetPolicyIssuingFlowStepFromStepIndex } from 'policy-issuing/policy-issuing-scene';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { CardGroup } from 'rootstrap/components/card';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { RadioField } from 'rootstrap/components/forms/new-fields/radio-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { JSONObject } from 'shared/utils';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { ScreeningQuestionsModal } from './screening-questions-modal';
import { SteppedComponentsBehavior } from 'rootstrap/components-old/root-schema-form/utils/stepped-components-behavior';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

interface Props extends SteppedFullScreenModalComponentParams {
  screeningQuestionsData?: JSONObject;
  setScreeningQuestionsData: (screeningQuestionsData: JSONObject) => void;
  setScreeningQuestionsValid: (screeningQuestionsValid: boolean) => void;
  setScreeningQuestionsComplete: (screeningQuestionsComplete: boolean) => void;
  setCurrentStepKey: (currentStepKey: IssuingSceneStepKeys) => void;
  screeningQuestionsEnabled: boolean;
  modalIsOpen: boolean;
  setModalIsOpen: (v: boolean) => void;
  setStepProgress: (stepProgress: string) => void;
  step: IssuingSceneStepKeys;
  priorStepKey: IssuingSceneStepKeys;
  setPriorStepKey: (stepProgress: string) => void;
  isLoading: boolean;
  stepOrder: Record<IssuingSceneStepKeys, number>;
  isMultiQuoteStep: boolean;
}

export const ScreeningQuestions = (props: Props) => {
  const { siteConfig } = useSiteConfigContext();
  const {
    screeningQuestionsEnabled,
    modalIsOpen,
    setModalIsOpen,
    setScreeningQuestionsValid,
    screeningQuestionsData,
    setScreeningQuestionsComplete,
    setScreeningQuestionsData,
    setStepProgress,
    priorStepKey,
    setPriorStepKey,
    step,
    isLoading,
    stepOrder,
    isMultiQuoteStep,
  } = props;
  const screeningQuestions = screeningQuestionsEnabled ? siteConfig?.quote.wording.screeningQuestions : [];

  const { startedTrack } = useMixpanelTrack();

  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: screeningQuestions ? screeningQuestions[0]?.key : '',
  });

  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const steppedComponentsBehavior = (params: {
    question: {
      key: string;
      header: string;
      question: string;
    };
  }): SteppedComponentsBehavior => ({
    hideDivider: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents
      ? !!(screeningQuestionsData && screeningQuestionsData[params.question.key] !== undefined
          ? String(screeningQuestionsData && screeningQuestionsData[params.question.key])
          : undefined)
      : true,
    disableNextButton: true,
  });

  useEffect(() => {
    if (!isLoading) {
      if (!props.screeningQuestionsEnabled) {
        setStepProgress(
          GetPolicyIssuingFlowStepFromStepIndex({
            step: stepOrder[step] + 1,
            issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
            isMultiQuoteStep,
          }),
        );

        if (priorStepKey && stepOrder[priorStepKey] > stepOrder[step]) {
          setPriorStepKey(
            GetPolicyIssuingFlowStepFromStepIndex({
              step: stepOrder[step],
              issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
              isMultiQuoteStep,
            }),
          );
          setStepProgress(
            GetPolicyIssuingFlowStepFromStepIndex({
              step: stepOrder[step] - 1,
              issuingFlowStartingStep: siteConfig?.settings.issuingFlowStartingStep,
              isMultiQuoteStep,
            }),
          );
        }
      }
      if (props.screeningQuestionsEnabled) {
        startedTrack({
          stepName: MixpanelStepNames.QuoteStep,
        });
      }
    }

    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [isLoading]);

  // if (!screeningQuestionsEnabled) {
  //   const isPersonalDetailsStartingStep =
  //     siteConfig?.settings.issuingFlowStartingStep === ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails;

  //   setCurrentStepKey(isPersonalDetailsStartingStep ? StepKeys.PersonalDetails : StepKeys.QuoteInput);
  // }
  // }, [screeningQuestionsEnabled, setCurrentStepKey]);

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        values: { ...screeningQuestionsData },
      }),
      [],
    ),
  });
  form.watch();

  const onModalClose = () => {
    setModalIsOpen(false);
    const formValues = form.getValues() as JSONObject;
    const firstInvalidValue = Object.keys(formValues).find((key: keyof typeof formValues) => formValues[key] === 'yes');
    firstInvalidValue &&
      setActiveElement({
        elementId: firstInvalidValue,
      });
  };

  const handleChange = () => {
    const formValues = form.getValues() as JSONObject;
    setScreeningQuestionsData(formValues);

    const isComplete = Object.keys(formValues)
      .map((key: string) => formValues[key])
      .every((i) => ['yes', 'no'].includes(String(i)));

    const isValid = Object.keys(formValues)
      .map((key: string) => formValues[key])
      .every((i) => ['no'].includes(String(i)));

    setScreeningQuestionsValid(isValid);
    setScreeningQuestionsComplete(isComplete);
  };

  return (
    <>
      {modalIsOpen && <ScreeningQuestionsModal onBackClicked={onModalClose} />}
      <FormWrapperStyle>
        <CardGroup>
          <form onChange={() => handleChange()}>
            {screeningQuestions &&
              screeningQuestions.map((question, index) => {
                return (
                  <Row>
                    <Col sm={12}>
                      <ScreeningQuestionRadioField
                        placeholder={undefined}
                        form={form}
                        displayProperties={
                          {
                            activeElement,
                            setActiveElement,
                            nextComponentName: screeningQuestions[index + 1]?.key,
                          } as InputFieldDisplayProperties
                        }
                        name={question.key}
                        validators={[
                          {
                            validation: {
                              type: ValidationTypes.REQUIRED,
                            },
                          },
                        ]}
                        label={
                          <span>
                            <ScreeningQuestionsHeader
                              onClick={() =>
                                setActiveElement({
                                  elementId: screeningQuestions[index + 1]?.key,
                                })
                              }
                              questionKey={question.key}
                              activeElement={activeElement}
                            >
                              {question.header}
                            </ScreeningQuestionsHeader>
                            <span style={{ fontSize: 20 }}>{question.question}</span>
                          </span>
                        }
                        defaultValue={
                          screeningQuestionsData && screeningQuestionsData[question.key] !== undefined
                            ? String(screeningQuestionsData[question.key])
                            : undefined
                        }
                        options={[
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ]}
                        {...steppedComponentsBehavior({ question })}
                      />
                    </Col>
                  </Row>
                );
              })}
          </form>
        </CardGroup>
      </FormWrapperStyle>
    </>
  );
};

const ScreeningQuestionRadioField = styled(RadioField)`
  font-size: 16px;
`;

const ScreeningQuestionsHeader = styled.div<{ questionKey: string; activeElement: ActiveElement }>`
  font-size: 16px !important;
  font-weight: 400;
  cursor: pointer;
  padding-bottom: 10px;
`;
