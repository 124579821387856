import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Policyholder } from 'policyholder/domain/policyholder';
import React from 'react';
import { Colors } from 'rootstrap/global-styles/colors';
import uuid from 'uuid';

interface Params {
  policyholder: Policyholder | undefined;
}

export const StripePaymentElement = (params: Params) => {
  const { policyholder } = params;
  return (
    <PaymentElement
      options={{
        wallets: {
          applePay: 'never',
          googlePay: 'never',
        },
        fields: {
          billingDetails: {
            email: 'never',
          },
        },
        defaultValues: {
          billingDetails: {
            email: policyholder?.email,
          },
        },
        terms: {
          card: 'always',
        },
      }}
    />
  );
};

export const stripePaymentElementOptions: StripeElementsOptions = {
  mode: 'setup',
  currency: 'usd',
  payment_method_creation: 'manual',
  paymentMethodCreation: 'manual',
  setup_future_usage: 'off_session',
  setupFutureUsage: 'off_session',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
    rules: {
      '.p-Fade p-LinkOptInWrapper': {
        display: 'none',
      },
      '.p-Fade-item': {
        display: 'none',
      },
      '.Input:focus': {
        borderColor: Colors.Body,
      },
      '.Input': {
        width: '100% !important',
        borderColor: Colors.Body,
      },
      '.Label': {
        fontSize: '18px',
        paddingBottom: '10px',
      },
    },
    variables: {
      focusBoxShadow: 'none',
      focusOutline: Colors.Body,
      colorPrimary: Colors.Body,
      borderRadius: '8px',
      spacingGridRow: '20px',
      spacingGridColumn: '20px',
    },
    disableAnimations: false,
  },
};

export const useStripePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();

  const submitPaymentMethod = async (params: { policyholder: Policyholder | undefined }) => {
    const { policyholder } = params;

    if (process.env.REACT_APP_NODE_ENV === 'test') {
      // Return a hard-coded PaymentMethod for testing purposes
      return `pm_test_${uuid().toString()}`;
    }

    if (policyholder === undefined) {
      throw new Error('Policyholder not found');
    }

    if (!stripe || !elements) {
      throw new Error('Stripe could not be found');
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw new Error('Could not submit stripe payment method');
    }

    // Create the payment method
    const cardElement = elements.getElement(PaymentElement);
    if (!cardElement) {
      throw new Error('Could not find stripe card element');
    }

    const result = await stripe.createPaymentMethod({
      element: cardElement,
      params: {
        billing_details: {
          email: policyholder.email,
        },
      },
    });

    if (!result.paymentMethod?.id) {
      throw new Error('Could not create stripe payment method');
    }

    return result.paymentMethod?.id;
  };

  return { submitPaymentMethod };
};
