import { devices } from 'rootstrap/global-styles/devices';
import styled, { css } from 'styled-components';

export const StyledRootSchemaComponent = styled.div<{
  isActive: boolean;
  isFirstElement: boolean;
  disableActiveElement: boolean | undefined;
  hiddenComponent?: boolean;
  className: 'schema-component'; // This is to ensure the same class name so that we can target the class from outside of the component eg. list-field.tsx
}>`
  padding-top: ${({ isActive, isFirstElement, disableActiveElement }) =>
    isActive && !isFirstElement ? '77px' : '35px'};
  transition: all 300ms;

  ${({ disableActiveElement }) =>
    disableActiveElement &&
    css`
      padding-top: 25px;
    `}

  ${({ hiddenComponent }) =>
    hiddenComponent &&
    css`
      display: none;
    `}

  .field-divider {
    margin-top: ${({ isActive }) => (isActive ? '82px' : '40px')};
  }
`;

export const StyledSliderRootSchemaComponent = styled(StyledRootSchemaComponent)`
  @media${devices.mobileL} {
    .form-group {
      padding-left: 14px !important;
      padding-right: 14px !important;
    }

    label {
      margin-left: -14px;
    }
  }
`;
