import React, { createContext, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { Currency } from 'product-modules/domain/product-module-definition-settings';
import { PolicyBillingFrequency } from 'policies/domain/policy-billing';

export enum MixpanelEventNames {
  SessionInitiated = 'Session initiated',
  StepStarted = 'Step started',
  StepCompleted = 'Step completed',
  SubStepCompleted = 'Sub-step completed',

  StepExited = 'Step exited',
  StepRetried = 'Step retried',
}

export enum MixpanelLocation {
  PrePolicyholder = 'pre_policyholder',
  PrePayment = 'pre_payment',
}

export enum MixpanelOutcome {
  Success = 'success',
  Failure = 'failure',
}

export interface MixpanelExtraProperties {
  outcome?: MixpanelOutcome;
  quotes?:
    | {
        billingFrequency: PolicyBillingFrequency;
        currency: Currency;
        sumAssured: number;
        suggestedPremium: number;
        quotePackageId: string;
      }[]
    | {
        error: string;
      };
}

export enum MixpanelStepNames {
  LandingPage = 'landing_page',
  ScreeningQuestions = 'screening_questions',
  QuoteStep = 'quote_step',
  SplitQuoteStep1 = 'split_quote_step_1',
  SplitQuoteStep2 = 'split_quote_step_2',
  ConsentPrePersonalDetails = 'consent_pre_personal_details',
  PersonalDetails = 'personal_details',
  Application = 'application',
  Beneficiaries = 'beneficiaries',
  ConsentPrePayment = 'consent_pre_payment',
  Payment = 'payment',
  Confirmation = 'confirmation',
}

interface MixpanelContextValue {
  track: (eventName: MixpanelEventNames, step: MixpanelStepNames, extraProperties?: MixpanelExtraProperties) => void;
}

const MixpanelContext = createContext<MixpanelContextValue | undefined>(undefined);

export const MixpanelProvider: React.FC = ({ children }) => {
  const { embedSessionId } = useEmbedSessionContext();
  const { embedParams } = useEmbedParamsContext(); // Use the hook here to get the embedParams

  const track = (eventName: MixpanelEventNames, step: MixpanelStepNames, extraProperties?: MixpanelExtraProperties) => {
    const isTestEnvironment = process.env.NODE_ENV === 'test';
    const isLocalEnvironment = process.env.NODE_ENV === 'development';

    if (!isTestEnvironment && !isLocalEnvironment) {
      // Include embedSessionId and organizationId in the properties sent to Mixpanel
      const baseProperties = {
        step,
        embedSessionId,
        organizationId: embedParams.organizationId,
        organizationName: embedParams.organizationName,
        productModuleKey: embedParams.productModuleKey,
      };

      const properties = { ...baseProperties, ...extraProperties };
      mixpanel.track(eventName, properties);
    }
  };

  mixpanel.init('aabc4faea38ad2c269f95d1c9505af97');
  return <MixpanelContext.Provider value={{ track }}>{children}</MixpanelContext.Provider>;
};

export const useMixpanel = () => {
  const context = useContext(MixpanelContext);
  if (context === undefined) {
    // In a non-production environment, return a no-op function for track.
    return {
      track: () => {},
    };
  }
  return context;
};

export const useMixpanelTrack = () => {
  const { track } = useMixpanel();

  const startedTrack = (params: { stepName: MixpanelStepNames; extraProperties?: MixpanelExtraProperties }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.StepStarted, stepName, extraProperties);
  };

  const subStepCompletedTrack = (params: {
    stepName: MixpanelStepNames;
    extraProperties?: MixpanelExtraProperties;
  }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.SubStepCompleted, stepName, extraProperties);
  };

  const stepCompletedTrack = (params: { stepName: MixpanelStepNames; extraProperties?: MixpanelExtraProperties }) => {
    const { stepName, extraProperties } = params;
    track(MixpanelEventNames.StepCompleted, stepName, extraProperties);
  };

  return {
    stepCompletedTrack,
    subStepCompletedTrack,
    startedTrack,
  };
};
