import React, { ReactNode, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DisplayMarkdown, Field } from '../new-field';
import { InputFieldParams } from './input-field';
import styled from 'styled-components';
import { useSiteConfigContext } from 'style-context';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { Input } from 'reactstrap';
import { ProductModuleDefinitionEmbeddedConfig, getColor } from 'site-config';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { globalStyles } from 'rootstrap/global-styles';

export const RadioButtonField = ({
  label,
  name,
  isDisabled,
  form,
  options,
  defaultValue,
  prefillAction,
  prefillValue,
  disableScrollToElement,
  hideDivider,
  displayProperties,
  validators,
  disableNextButton,
  disableTitle,
  isTouched: defaultIsTouched,
  disableActiveElement,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<string> & { options: { label: ReactNode; value: any; isDisabled?: boolean }[] }) => {
  const checkedValue = form?.watch(name);
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = useState<boolean>(!!checkedValue || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const { activeElement, setActiveElement } = displayProperties;

  const isActive = activeElement.elementId === name || activeElement.elementId === `stepped-form-next-button-${name}`;

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: options as { label: string; value: any }[],
    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: undefined,
      }),
  });

  const doesValueMatchSelected = (selected: string, value: string | boolean) => {
    const selectedOrDefault = isTouched ? selected : defaultValue;

    if (typeof value === 'boolean') {
      return value === false ? selectedOrDefault === 'false' : selectedOrDefault === 'true';
    }

    return selectedOrDefault === value;
  };

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  const errors = form?.errors;
  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: true,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        disableActiveElement={disableActiveElement}
        isActive={isActive}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          isTouched={isTouched}
          isActive={isActive}
          hiddenComponent={hiddenComponent}
          name={name}
          disableTitle={disableTitle}
          onClick={() => setActiveElement({ elementId: name })}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          style={{
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
          }}
        >
          <Controller
            defaultValue={defaultValue}
            name={name}
            control={form?.control}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: undefined,
                }),
            }}
            render={({ onChange }) => {
              return (
                <>
                  <RadioButtonWrapper
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <RadioButtonOptionsContainer disabled={isDisabled || disabledFromPrefill}>
                      {options.map((option, index) => {
                        const isOptionDisabled = disabledFromPrefill || isDisabled || option.isDisabled;
                        const cursor = isOptionDisabled ? 'not-allowed' : 'pointer';
                        return (
                          <>
                            <StyledRadioLabel
                              borderColor={getSteppedFormLabelColor({
                                isActive: isActive || isTouched,
                                siteConfig,
                                color: Colors.Body,
                              })}
                              isSelected={doesValueMatchSelected(checkedValue, option.value)}
                              color={
                                isActive || isTouched
                                  ? getColor({ siteConfig: siteConfig, color: 'highlight' })
                                  : `${getColor({ siteConfig: siteConfig, color: 'highlight' })}80`
                              }
                              disableActiveElement={!!disableActiveElement}
                              isActive={isActive || isTouched}
                              siteConfig={siteConfig}
                              isFirstItem={index === 0}
                              isLastItem={index === options.length - 1}
                            >
                              <Input
                                id={name}
                                isActive={isActive}
                                name={name}
                                siteConfig={siteConfig}
                                disabled={isOptionDisabled}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                                  onEnterKeyDown({
                                    e,
                                    nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                                    setFieldError: (fieldError) => setFieldError(fieldError),
                                    setActiveElement: (elementId) =>
                                      setActiveElement({
                                        elementId,
                                      }),
                                    getValue: () => form?.watch(name),
                                    validationFunction: getValidationMessage,
                                    validators,
                                    validationProps: undefined,
                                    setIsTouched: (isTouched) => setTouched(isTouched),
                                  })
                                }
                                onChange={(e: any) => {
                                  const value = e.target.value;
                                  setTouched(true);
                                  onChange(value);

                                  const fieldError = getValidationMessage({
                                    validators: validators,
                                    value,
                                    props: undefined,
                                  });
                                  setFieldError(fieldError);

                                  if (!fieldError) {
                                    setActiveElement &&
                                      setActiveElement({
                                        elementId: getNextComponentId({ ...displayProperties, key: name }),
                                      });
                                  }
                                }}
                                value={option.value}
                                type='radio'
                                invalid={!!fieldError}
                                style={{ opacity: 1, cursor }}
                                onBlur={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              />
                              <div
                                className='text-container'
                                style={{
                                  cursor,
                                }}
                              >
                                <span>
                                  <DisplayMarkdown label={option.label} />
                                </span>
                              </div>
                            </StyledRadioLabel>
                          </>
                        );
                      })}
                    </RadioButtonOptionsContainer>
                  </RadioButtonWrapper>
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            submitOnChange={submitOnChange}
            hideDivider={hideDivider}
            hideBorder={hideBorder}
            setTouched={(isTouched) => setTouched(isTouched)}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            validationFunction={getValidationMessage}
            validators={validators}
            disableNextButton={disableNextButton}
            validationProps={undefined}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};

export const RadioButtonOptionsContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px !important;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #fff;
    cursor: not-allowed;`}
`;

export const StyledRadioLabel = styled.label<{
  isSelected: boolean;
  color: string;
  isFirstItem: boolean;
  isLastItem: boolean;
  borderColor: string;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  disableActiveElement: boolean;
}>`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin-bottom: 0px;
  height: 40px;
  border: 1px solid ${({ borderColor }) => borderColor};

  background-color: ${({ isSelected, color }) => (isSelected ? color : '#FFFFFF')};
  color: ${({ siteConfig, isActive, disableActiveElement, isSelected }) =>
    getSteppedFormLabelColor({
      isActive,
      siteConfig,
      color: isSelected ? '#FFFFFF' : globalStyles.colors.Body,
      disableActiveElement,
    })};

  ${({ isFirstItem }) => isFirstItem && 'border-top-left-radius: 8px; border-bottom-left-radius: 8px;'};
  ${({ isLastItem }) => isLastItem && 'border-top-right-radius: 8px; border-bottom-right-radius: 8px;'};

  .text-container {
    text-align: center;
    width: 100%;
  }

  // Remove the right border for all but the last label
  ${({ isLastItem }) =>
    !isLastItem &&
    `
    border-right: none;
  `};

  // Modify the border-color based on the isSelected prop
  ${({ isSelected, color }) =>
    isSelected &&
    `
    border-color: ${color};
  `};

  ${({ isSelected, borderColor }) =>
    !isSelected &&
    `
    border-color: ${borderColor};
  `};

  // Style for the active label's right border and the following label's left border
  &.active + &:not(.active) {
    border-left-color: ${({ color }) => color};
  }

  & + & {
    border-left-color: ${({ borderColor }) => borderColor};
  }
`;

export const RadioButtonWrapper = styled.div`
  .form-check-input {
    display: none !important;
    position: inherit !important;
  }
`;
