import React, { useEffect, useState } from 'react';
import { ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { StyledManagePaymentMethodCard } from '../styles';
import { Policy } from 'policies/domain/policy';
import { formatBillingDate } from '../utils';
import { ThemedAlert } from 'rootstrap/components/alerts/alert';
import { ExternalPaymentMethod } from 'payment-methods/domain/payment-method';

export const ManageStripePaymentMethodCard = (props: {
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  paymentMethod: ExternalPaymentMethod;
  policy: Policy;
  refetchPolicy: () => void;
}) => {
  const { siteConfig, policy, refetchPolicy } = props;
  const [counter, setCounter] = useState(0);

  const stripePaymentMethod = (policy.appData.stripe_payment_method || {}) as {
    id: string;
    type: string;
    last_four: number;
    brand: string;
  };
  const existingStripePaymentMethodLast4Digits = stripePaymentMethod?.last_four || '-';
  const existingStripePaymentMethodCardType = stripePaymentMethod?.type || '';
  const isLatestDetails = stripePaymentMethod.id === policy.appData.stripe_payment_method_id;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isLatestDetails) {
        refetchPolicy();
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 15000); // 1 minute interval

    if (counter >= 5) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [isLatestDetails, refetchPolicy, counter]);

  return (
    <>
      {!isLatestDetails && (
        <ThemedAlert
          id='stripe-payment-method-card-updating-in-progress'
          body={'Updating in progress. Your payment method will be refreshed soon.'}
        />
      )}
      <StyledManagePaymentMethodCard siteConfig={siteConfig}>
        <p id='manage-stipe-payment-method-header' className='header'>
          Stripe payment method
        </p>
        <p id='stripe-payment-method-last-4-card-details' className='details'>
          Last 4 card details: {isLatestDetails ? existingStripePaymentMethodLast4Digits : '-'}
        </p>
        <p id='stripe-payment-method-card-type' className='details'>
          Card type: {isLatestDetails ? existingStripePaymentMethodCardType : '-'}
        </p>
        {policy.nextBillingDate && (
          <p className='billing'>Next billing day: {formatBillingDate(policy.nextBillingDate)}</p>
        )}
      </StyledManagePaymentMethodCard>
    </>
  );
};
