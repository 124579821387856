import { updateApplicationBillingDay } from 'applications/actions/update-application-billing-day';
import { issuePolicy } from 'policies/actions/issue-policy';
import { StyledTitle } from 'policy-issuing/common/styled-title';
import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { Col, Row } from 'reactstrap';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { LongButtonSuccess } from 'rootstrap/components/button/styles';
import { CardGroup } from 'rootstrap/components/card';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { BillingDaySelectField } from 'rootstrap/components/forms/new-fields/extended-components/billing-day-select-field';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import NewSpinner, { AnimationTypes, SpinnerSize } from 'rootstrap/components/spinner/new-spinner';
import { applyPolicyDetailsToUrl, useConfirmationUrl, useBaseUrl, AuthTypes } from 'shared/api';
import { PaymentMethodPrefillParams } from 'shared/domain/prefill-values';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { PaymentMethodProps } from '../payment-step';
import { handleRedirect } from '../utils/handle-payment-redirect';
import { Elements, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  StripePaymentElement,
  stripePaymentElementOptions,
  useStripePaymentMethod,
} from 'rootstrap/components/stripe/stripe-payment-element';
import { updateApplication } from 'applications/actions/update-application';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { DebitOrderPaymentInputs } from 'policy-management/payment-details/forms/debit-order-payment-method-form';

export enum ExternalPaymentInputs {
  BillingDay = 'billingDay',
}

export const StripeCardForm = (params: PaymentMethodProps) => {
  const { prefillValues, application, setPaymentMethodIsValid, policyholder, setActiveElement, isConfirmationSkipped } =
    params;
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { submitPaymentMethod } = useStripePaymentMethod();
  const { stepCompletedTrack } = useMixpanelTrack();

  const { auth, environment, organizationId } = embedParams;
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const confirmationUrl = useConfirmationUrl();
  const canEditBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.editBillingDay);
  const displayBillingDay = Boolean(siteConfig?.payment.displayOptionalSections.billingDay);

  const externalPaymentInputData = getExternalPaymentMethodInputData({
    prefillValues: prefillValues?.payment,
    billingDay: application?.billingDay,
    canEditBillingDay,
  });
  const redirectOnCompletedUrl = siteConfig?.confirmation.links.redirectOnCompletedUrl;
  const paymentMethodSubmitButtonRef = useRef<any>();

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });

  form.watch();

  usePromise(async () => {
    setPaymentMethodIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    scroller.scrollTo(`bankName-form-group`, {
      duration: 400,
      offset: !isMobile ? -200 : 0,
      delay: 0,
      containerId: 'form-overlay-content-wrapper',
      smooth: true,
    });
  }, []);

  const { execute, error, isLoading } = usePromiseLazy(async (formData: ExternalPaymentMethodFormData) => {
    if (!policyholder || !application) {
      throw new Error(`No ${!policyholder ? 'policyholder' : 'application'} found`);
    }

    const paymentMethodId = await submitPaymentMethod({ policyholder });

    if (canEditBillingDay && application.billingDay !== formData.billingDay && displayBillingDay) {
      const billingDay = formData.billingDay || 1;

      if (auth.type === AuthTypes.APIKey) {
        await updateApplication({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay,
          },
        });
      } else {
        await updateApplicationBillingDay({
          applicationId: application.applicationId,
          environment,
          auth,
          organizationId,
          data: {
            billingDay,
          },
        });
      }
    }

    const { policyId, policyholderId } = await issuePolicy({
      applicationId: application.applicationId,
      billingDay: formData.billingDay,
      auth,
      environment,
      organizationId,
      appData: {
        stripePaymentMethodId: paymentMethodId,
      },
    });

    stepCompletedTrack({
      stepName: MixpanelStepNames.Payment,
    });

    handleRedirect({
      applyPolicyDetailsToUrl,
      baseUrl,
      confirmationUrl,
      environment,
      history,
      isConfirmationSkipped,
      policyholderId,
      policyId,
      redirectOnCompletedUrl,
    });
  }, []);

  return (
    <StripeFormWrapperStyle>
      <StripeStyledTitle>Payment details:</StripeStyledTitle>
      <ErrorAlert error={error?.message !== 'Could not submit stripe payment method' ? error : undefined} />
      <CardGroup>
        <LinkAuthenticationElement
          className='link-authentication-element'
          id='link-authentication-element'
          options={{
            defaultValues: {
              email: policyholder?.email || '',
            },
          }}
        />
        <StripePaymentElement policyholder={policyholder} />
        <form onSubmit={form.handleSubmit((data: ExternalPaymentMethodFormData) => execute(data))}>
          {displayBillingDay && (
            <Row>
              <Col sm={12} style={{ marginTop: 0 }}>
                <BillingDaySelectField
                  clearable={true}
                  isTouched={true}
                  disableScrollToElement={true}
                  name={ExternalPaymentInputs.BillingDay}
                  validators={[
                    {
                      validation: {
                        type: ValidationTypes.REQUIRED,
                      },
                    },
                  ]}
                  label='Debit day'
                  form={form}
                  isDisabled={!canEditBillingDay}
                  defaultValue={externalPaymentInputData.billingDay}
                  prefillValue={externalPaymentInputData.billingDay}
                  disableTitle={false}
                  placeholder={'Debit day'}
                  disableActiveElement={true}
                  hideDivider={true}
                  displayProperties={
                    {
                      activeElement: {
                        elementId: '',
                      },
                      setActiveElement,
                      nextComponentName: DebitOrderPaymentInputs.AccountType,
                    } as InputFieldDisplayProperties
                  }
                />
              </Col>
            </Row>
          )}
          <button style={{ display: 'none' }} ref={paymentMethodSubmitButtonRef} type='submit' />
          <ExternalPaymentLongButtonSuccess
            siteConfig={siteConfig}
            onClick={() => paymentMethodSubmitButtonRef.current.click()}
            disabled={isLoading || !form.formState.isValid}
          >
            {isLoading && (
              <span style={{ marginRight: '10px' }}>
                <NewSpinner animation={AnimationTypes.Border} size={SpinnerSize.sm} color='FFFFFF' />
              </span>
            )}
            Pay now
          </ExternalPaymentLongButtonSuccess>
        </form>
      </CardGroup>
    </StripeFormWrapperStyle>
  );
};

export const StripeCardPaymentMethod = (params: PaymentMethodProps) => {
  const { siteConfig } = useSiteConfigContext();
  const stripePublishableKey = siteConfig?.settings.stripePublishableKey;

  if (!stripePublishableKey) {
    return <div />;
  }

  const stripePromise = loadStripe(stripePublishableKey);
  return (
    <Elements stripe={stripePromise} options={stripePaymentElementOptions}>
      <StripeCardForm {...params} />
    </Elements>
  );
};

const StripeStyledTitle = styled(StyledTitle)`
  margin-bottom: 37px !important;
`;

const StripeFormWrapperStyle = styled(FormWrapperStyle)`
  .link-authentication-element {
    display: none;
  }
`;

const ExternalPaymentLongButtonSuccess = styled(LongButtonSuccess)`
  width: 142px;
  float: right;
`;
interface ExternalPaymentMethodFormData {
  billingDay?: number;
}

const getExternalPaymentMethodInputData = (params: {
  prefillValues?: PaymentMethodPrefillParams;
  canEditBillingDay: boolean;
  billingDay: number | undefined;
}): ExternalPaymentMethodFormData => {
  const { billingDay, canEditBillingDay, prefillValues } = params;
  return {
    billingDay: canEditBillingDay ? prefillValues?.debit_day || billingDay : billingDay,
  };
};
