import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { Quote } from 'policy-issuing/quotes/domain/quote';
import { CreateQuotePackage } from 'policy-issuing/quotes/views/create-quote-package';
import { CreateQuotePackageStep1 } from 'policy-issuing/quotes/views/create-quote-package-step-1';
import { CreateQuotePackageStep2 } from 'policy-issuing/quotes/views/create-quote-package-step-2';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';
import { ProductModule } from 'product-modules/domain/product-module';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { SetStateAction, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultStepKeys } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { useBaseUrl } from 'shared/api';
import { PrefillValues } from 'shared/domain/prefill-values';
import { JSONObject } from 'shared/utils';
import { getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';

interface ProductModuleAndSchema {
  productModuleDefinitionQuoteSchema: ProductModuleDefinitionSchema;
  productModule: ProductModule;
  productModuleDefinitionApplicationSchema: ProductModuleDefinitionSchema;
}

export const QuoteDetailsStep = (params: {
  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
  setPriorStepKey: (key: SetStateAction<any | undefined>) => void;
  getPriorStepKey: () => SetStateAction<any | undefined>;
  onNextCompleted: (() => void | Promise<void>) | undefined;
  onBackCompleted: (() => void | Promise<void>) | undefined;
  setIsQuoteCompletedStep1: (isCompleted: boolean) => void;
  isLoadingCreateQuote: boolean;
  quoteSchemaFormData: JSONObject | undefined;
  prefillValues: PrefillValues;
  setLoadingCreateQuote: (isLoading: boolean) => void;
  isQuoteCompletedStep1: boolean;
  productModuleAndSchema: ProductModuleAndSchema | undefined;
  createQuoteButtonRef: React.MutableRefObject<HTMLButtonElement | undefined>;
  setQuoteSchemaFormData: (data: JSONObject | undefined) => void;
  quoteSchemaFormDataPart1: JSONObject | undefined;
  quoteSchemaFormDataPart2: JSONObject | undefined;
  setQuoteSchemaFormDataPart1: (data: JSONObject | undefined) => void;
  setQuoteSchemaFormDataPart2: (data: JSONObject | undefined) => void;
  screeningQuestionsData: any;
  selectedQuote: Quote | undefined;
  setQuotePackages: (quote: Quote[] | undefined) => void;
  setCreateQuoteError: (error: any) => void;
  rootSchemaFormQuoteRef: React.MutableRefObject<any>;
  isQuoteCompleted: boolean;
  setIsQuoteCompleted: (isCompleted: boolean) => void;
  isMultiQuote: boolean;
}) => {
  const {
    isLoadingCreateQuote,
    quoteSchemaFormData,
    prefillValues,
    setLoadingCreateQuote,
    isQuoteCompletedStep1,
    productModuleAndSchema,
    createQuoteButtonRef,
    setQuoteSchemaFormData,
    screeningQuestionsData,
    setIsQuoteCompletedStep1,
    selectedQuote,
    setQuotePackages,
    setCreateQuoteError,
    rootSchemaFormQuoteRef,
    isQuoteCompleted,
    setIsQuoteCompleted,
    isMultiQuote,
    quoteSchemaFormDataPart1,
    quoteSchemaFormDataPart2,
    setQuoteSchemaFormDataPart1,
    setQuoteSchemaFormDataPart2,
  } = params;
  const { siteConfig } = useSiteConfigContext();
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { stepCompletedTrack } = useMixpanelTrack();

  const [isValidQuotePart1, setIsValidQuotePart1] = useState<boolean>(false);

  const quote = siteConfig?.quote;

  const screeningQuestionsEnabled = Boolean(
    siteConfig &&
      siteConfig.quote.displayOptionalSections.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions.length > 0,
  );

  const productModuleDefinitionQuoteSchema = productModuleAndSchema?.productModuleDefinitionQuoteSchema;
  const productModuleDefinition = productModuleAndSchema?.productModule.productModuleDefinition;

  if (isMultiQuote) {
    return [
      {
        title: quote?.wording.title,
        description: getWording({ wording: quote?.wording.description }),
        key: IssuingSceneStepKeys.QuoteInputMultiStep1,
        stepName: DefaultStepKeys.Quote,
        onBackClick: () => {
          screeningQuestionsEnabled
            ? params.setCurrentStepKey(IssuingSceneStepKeys.ScreeningQuestions)
            : history.push(baseUrl);
        },
        continueButtonProps: {
          isLoading: isLoadingCreateQuote,
          disabled: isLoadingCreateQuote || !quoteSchemaFormDataPart1 || !isValidQuotePart1,
          isNextButtonHidden: false,
        },
        body: () => (
          <CreateQuotePackageStep1
            prefillValues={prefillValues}
            setIsLoading={(isLoading) => setLoadingCreateQuote(isLoading)}
            onNextCompleted={() => null}
            isCompleted={isQuoteCompletedStep1}
            productModuleDefinition={productModuleDefinition}
            quoteSchemaFormDataPart1={quoteSchemaFormDataPart1}
            quoteSchemaFormDataPart2={quoteSchemaFormDataPart2}
            productModuleDefinitionQuoteSchema={productModuleDefinitionQuoteSchema}
            createQuoteButtonRef={createQuoteButtonRef}
            setIsValid={(isValid) => setIsValidQuotePart1(isValid)}
            setQuoteSchemaFormDataPart1={(quoteData) => {
              setQuoteSchemaFormDataPart1(quoteData);
            }}
            setQuoteSchemaFormDataPart2={(quoteData) => setQuoteSchemaFormDataPart2(quoteData)}
            screeningQuestionsData={screeningQuestionsData}
          />
        ),
        onNextClick: async () => {
          setIsQuoteCompletedStep1(true);
          stepCompletedTrack({
            stepName: MixpanelStepNames.SplitQuoteStep1,
          });
          params.onNextCompleted && params.onNextCompleted();
        },
      },
      {
        title: quote?.wording.title,
        description: getWording({ wording: quote?.wording.description }),
        key: IssuingSceneStepKeys.QuoteInputMultiStep2,
        stepName: DefaultStepKeys.Quote,
        onBackClick: () => {
          history.push(baseUrl);
        },
        continueButtonProps: {
          isLoading: false,
          disabled: !selectedQuote || isLoadingCreateQuote,
          isNextButtonHidden: true,
        },
        body: () => (
          <CreateQuotePackageStep2
            prefillValues={prefillValues}
            onNextCompleted={() => null}
            isCompleted={isQuoteCompleted}
            setIsLoading={(isLoading) => setLoadingCreateQuote(isLoading)}
            productModuleDefinition={productModuleDefinition}
            quoteSchemaFormDataPart2={quoteSchemaFormDataPart2}
            quoteSchemaFormDataPart1={quoteSchemaFormDataPart1}
            productModuleDefinitionQuoteSchema={productModuleDefinitionQuoteSchema}
            createQuoteButtonRef={createQuoteButtonRef}
            setQuoteSchemaFormData={(quoteData) => setQuoteSchemaFormDataPart2(quoteData)}
            setQuotePackages={(quotes) => setQuotePackages(quotes)}
            setCreateQuoteError={(error) => setCreateQuoteError(error)}
            screeningQuestionsData={screeningQuestionsData}
            rootSchemaFormRef={rootSchemaFormQuoteRef}
          />
        ),
        onNextClick: async () => {
          setIsQuoteCompleted(true);
          if (selectedQuote) {
            stepCompletedTrack({
              stepName: MixpanelStepNames.SplitQuoteStep2,
            });
            params.onNextCompleted && params.onNextCompleted();
          }
        },
      },
    ];
  }

  return [
    {
      title: quote?.wording.title,
      description: getWording({ wording: quote?.wording.description }),
      key: IssuingSceneStepKeys.QuoteInput,
      stepName: DefaultStepKeys.Quote,
      onBackClick: () => {
        screeningQuestionsEnabled
          ? params.setCurrentStepKey(IssuingSceneStepKeys.ScreeningQuestions)
          : history.push(baseUrl);
      },
      continueButtonProps: {
        isLoading: false,
        disabled: !selectedQuote || isLoadingCreateQuote,
        isNextButtonHidden: true,
      },
      body: () => (
        <CreateQuotePackage
          prefillValues={prefillValues}
          onNextCompleted={() => null}
          isCompleted={isQuoteCompleted}
          setIsLoading={(isLoading) => setLoadingCreateQuote(isLoading)}
          productModuleDefinition={productModuleDefinition}
          quoteSchemaFormData={quoteSchemaFormData}
          productModuleDefinitionQuoteSchema={productModuleAndSchema?.productModuleDefinitionQuoteSchema}
          createQuoteButtonRef={createQuoteButtonRef}
          setQuoteSchemaFormData={(quoteData) => setQuoteSchemaFormData(quoteData)}
          setQuotePackages={(quotes) => setQuotePackages(quotes)}
          setCreateQuoteError={(error) => setCreateQuoteError(error)}
          screeningQuestionsData={screeningQuestionsData}
          rootSchemaFormRef={rootSchemaFormQuoteRef}
        />
      ),
      onNextClick: async () => {
        setIsQuoteCompleted(true);
        if (selectedQuote) {
          stepCompletedTrack({
            stepName: MixpanelStepNames.QuoteStep,
          });
          params.onNextCompleted && params.onNextCompleted();
        }
      },
    },
  ];
};
