import React from 'react';

interface Props {
  size: number;
  color: string;
  style?: React.CSSProperties;
}

export const ArrowIcon = (props: Props) => {
  const { size, color } = props;
  return (
    <svg
      width={size}
      style={props.style}
      height={size}
      viewBox={`0 0 24 24`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M20 11.0003H2' stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 20L2 11L11 2' stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
