import { EmbedSession, NetworkEmbedSession } from 'embed-sessions/domain/embed-session';
import { Environment } from 'models/environment';
import { apiRequest, getApiHost } from 'shared/api';

interface Params {
  organizationId: string;
  productModuleId: string;
  environment: Environment;
}

export const createEmbedSession = async (params: Params) => {
  const { organizationId, productModuleId, environment } = params;

  const response = await apiRequest({
    url: `${getApiHost({ environment })}/v1/embed/embed-sessions/create`,
    method: 'post',
    body: {
      organization_id: organizationId,
      product_module_id: productModuleId,
    },
  });

  const networkEmbedSession: NetworkEmbedSession = await response.json();

  const embedSession = EmbedSession.fromNetwork(networkEmbedSession);

  return embedSession;
};
