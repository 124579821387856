import { ArrowIcon } from 'assets/arrow-icon';
import React, { ReactNode, useEffect, useState } from 'react';
import { globalStyles } from 'rootstrap/global-styles';
import { usePromise } from 'shared/hooks/promise';
import { getColor, getFontFamily, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { debounce } from 'utils';
import { FormOverlay, FormOverlayHeader, FormOverlayFooter } from '../../components-old/forms/form-overlay';
import { Step } from './stepped-fullscreen-modal';
import { FormOverlayContentWrapper } from './styles';
import {
  FullScreenModalHeader,
  FormOverLay,
  FormOverLayNavBarContainer,
  FormOverlayTitleWrapper,
  SpacerLeft,
  SpacerRight,
  FormOverLayNavBar,
  FormOverLayMainContents,
} from './styles/form-overlay-styles';
import {
  FullscreenModalActionBarStyle,
  FullScreenModalDescriptionContainer,
  FullscreenModalDescriptionContent,
  FullScreenModalOffset,
  FullscreenModalSplashHeadingContainer,
  FullscreenModalSplashHeadingContent,
} from './styles/fullscreen-modal-styles';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';

export interface FullScreenModalPageComponentDimensions {
  fullScreenHeaderHeight: number;
  progressBarHeight: number;
  formContentHeight: number;
  footerHeight: number;
}

export const FullscreenModal = (params: {
  title: string;
  onClose: () => any;
  leftBody?: ReactNode;
  body: ReactNode;
  actionBar: ReactNode;
  footer?: ReactNode;
  navBar?: ReactNode;
  currentStep: Step<any> | undefined;
  headingSplashSteps: IssuingSceneStepKeys[] | any[];
  quotePackageDisplaySteps: IssuingSceneStepKeys[] | any[];
}) => {
  const { siteConfig } = useSiteConfigContext();
  const [pageDimensions, setPageDimensions] = useState<FullScreenModalPageComponentDimensions>(getPageDimensions());
  const titleEnabled = siteConfig?.header.displayOptionalSections.title;
  const { actionBar, body, title, footer, navBar, headingSplashSteps, quotePackageDisplaySteps } = params;

  const [currentStep, setCurrentStep] = useState<Step<any> | undefined>();

  const [navbar, setNavbar] = useState<HTMLElement | null>();
  const [header, setHeader] = useState<HTMLElement | null>();

  useEffect(() => {
    const navbar = document.getElementById('form-overflow-content-wrapper');
    const header = document.getElementById('full-screen-header');

    setNavbar(navbar);
    setHeader(header);
  }, [navbar, header]);

  usePromise(async () => {
    if (navbar && header) {
      navbar.addEventListener('scroll', () => {
        header.style.marginTop = `${-Number(navbar.scrollTop)}px`;
      });
    }
  }, [navbar, header]);

  const displayHeadingSplash = headingSplashSteps.includes(params.currentStep?.key);
  const isQuotePackageFooterDisplayed = quotePackageDisplaySteps.includes(params.currentStep?.key);

  useEffect(() => {
    const handleResize = () => {
      setPageDimensions(getPageDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fullscreenModalDescriptionContainer = document.getElementById('fullscreen-modal-description-container');
    const fullScreenHeader = document.getElementById('full-screen-header');
    const progressBar = document.getElementById('progress-bar');
    const fullscreenModalFooter = document.getElementById('fullscreen-modal-footer');

    const observer = new ResizeObserver((entries) => {
      setPageDimensions(getPageDimensions());
    });

    fullscreenModalDescriptionContainer && observer.observe(fullscreenModalDescriptionContainer);
    fullScreenHeader && observer.observe(fullScreenHeader);
    progressBar && observer.observe(progressBar);
    fullscreenModalFooter && observer.observe(fullscreenModalFooter);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    debounce(
      'Updating-description-header',
      () => {
        setCurrentStep(params.currentStep);
      },
      500,
    );
  }, [params.currentStep]);

  return (
    <FormOverlay>
      <FormOverlayHeader extendedWidth close={() => null}>
        <div style={{ display: 'flex', backgroundColor: getColor({ siteConfig, color: 'primary' }) }}>
          <FullScreenModalOffset />
          {titleEnabled && (
            <FormOverlayTitleWrapper id='full-screen-header'>
              <FullScreenModalHeader siteConfig={siteConfig} size={'24px'}>
                {title}
              </FullScreenModalHeader>
            </FormOverlayTitleWrapper>
          )}
          <FullScreenModalOffset />
        </div>
      </FormOverlayHeader>
      {navBar && (
        <FormOverLayNavBarContainer id='progress-bar' siteConfig={siteConfig}>
          <FormOverlayContentWrapper display='flex' alignItems='center'>
            <FormOverLay width='100%'>
              <SpacerLeft />
              <div className='main'>
                <FormOverLayNavBar siteConfig={siteConfig} navBar={navBar} />
              </div>
              <SpacerRight />
            </FormOverLay>
          </FormOverlayContentWrapper>
        </FormOverLayNavBarContainer>
      )}
      <div style={{ height: '100%', overflow: 'scroll' }} id='form-overflow-content-wrapper-outer'>
        <FormOverlayContentWrapper id='form-overlay-content-wrapper'>
          <FullScreenModalDescriptionContainer
            id='fullscreen-modal-description-container'
            siteConfig={siteConfig}
            removeMarginBottom={!!displayHeadingSplash}
          >
            <SpacerLeft />
            <FullscreenModalDescriptionContent>
              <DescriptionContentText currentStep={currentStep} />
            </FullscreenModalDescriptionContent>
            <SpacerRight />
          </FullScreenModalDescriptionContainer>
          <FullscreenModalSplashHeadingContainer
            id='fullscreen-modal-splash-heading-container'
            displayHeadingSplash={displayHeadingSplash}
            removeMarginBottom={false}
            siteConfig={siteConfig}
          >
            <SpacerLeft />
            <FullscreenModalSplashHeadingContent />
            <SpacerRight />
          </FullscreenModalSplashHeadingContainer>
          <FormOverLayMainContents
            id='fullscreen-modal-body'
            isQuotePackageFooterDisplayed={isQuotePackageFooterDisplayed}
            isHeadingSplashDisplayed={displayHeadingSplash}
            pageComponentDimensions={pageDimensions}
          >
            <SpacerLeft />
            <div className='main'>
              {body}
              <FullscreenModalActionBarStyle>{actionBar}</FullscreenModalActionBarStyle>
            </div>
            <SpacerRight />
          </FormOverLayMainContents>
          {footer && (
            <FormOverlayFooter
              id='fullscreen-modal-footer'
              siteConfig={siteConfig}
              disableBackgroundColor
              extendedWidth={true}
            >
              {footer}
            </FormOverlayFooter>
          )}
        </FormOverlayContentWrapper>
      </div>
    </FormOverlay>
  );
};

const DescriptionContentText = (params: { currentStep: Step<any> | undefined }) => {
  const { currentStep } = params;
  const displayBackButton = currentStep?.secondaryButton && currentStep.secondaryButton.enabled;
  const { siteConfig } = useSiteConfigContext();
  const title = currentStep?.title || currentStep?.description;
  const description = currentStep?.title ? currentStep?.description : undefined;

  return (
    <>
      <DescriptionHeaderContents id='description-contents' siteConfig={siteConfig}>
        {displayBackButton && (
          <DescriptionContentsIcon id='description-contents-icon' siteConfig={siteConfig}>
            <ArrowIconWrapper
              onClick={() => {
                currentStep?.secondaryButton?.onBackClicked && currentStep.secondaryButton.onBackClicked();
              }}
            >
              <ArrowIcon color={globalStyles.colors.Body} size={22} />
            </ArrowIconWrapper>
          </DescriptionContentsIcon>
        )}
        <DescriptionContentsBody id='description-contents-body' siteConfig={siteConfig}>
          <span className='description-header' id='description-header'>
            {title}
          </span>
          {description && <span className='description-subheader'>{description}</span>}
        </DescriptionContentsBody>
      </DescriptionHeaderContents>
    </>
  );
};

const DescriptionContentsIcon = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: flex;
  float: left;
  width: 50px;
  height: 100%;
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};
`;

const DescriptionContentsBody = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: -22px;
  font-family: ${({ siteConfig }) => getFontFamily({ siteConfig, fontFamily: 'title' })};

  span:nth-child(1) {
    font-size: 20px;
  }

  span:nth-child(2) {
    font-size: ${globalStyles.fontSize.body};
  }
`;

const DescriptionHeaderContents = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: inline-block;
  width: 100%;
`;

export const ArrowIconWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
`;

const getPageDimensions = () => {
  return {
    fullScreenHeaderHeight: document.getElementById('full-screen-header')?.offsetHeight || 0,
    progressBarHeight: document.getElementById('progress-bar')?.offsetHeight || 0,
    formContentHeight: document.getElementById('fullscreen-modal-description-container')?.offsetHeight || 0,
    footerHeight: document.getElementById('fullscreen-modal-footer')?.offsetHeight || 0,
  };
};
