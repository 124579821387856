import React, { ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { DefaultStepKeys, SteppedFullscreenModal } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { Content, FlexContainer, MainLayout, MainLayoutContent } from 'rootstrap/components/structure';
import { ApiError, useBaseUrl } from 'shared/api';
import { usePromise } from 'shared/hooks/promise';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { useHistory } from 'react-router-dom';
import { JSONObject } from 'shared/utils';
import { Quote } from './quotes/domain/quote';
import { InsurerDisclaimerContainer } from 'general/insurer-disclaimer';
import { getProductModuleDefinitionSchema } from 'product-modules/actions/get-product-module-definition-schema';
import { QuoteStepActionBar } from './quotes/views/quote-step-action-bar';
import { PaymentSection } from './payment/payment-step';
import { Application } from 'applications/domain/application';
import { Policyholder } from 'policyholder/domain/policyholder';
import { useSiteConfigContext } from 'style-context';
import { ProductModule } from 'product-modules/domain/product-module';
import {
  getWording,
  ProductModuleDefinitionEmbeddedConfig,
  ProductModuleEmbedConfigIssuingFlowStartingStep,
} from 'site-config';
import { PrefillValues } from 'shared/domain/prefill-values';
import { CreateApplication } from './application/create-application';
import {
  FormOverlayFooterWrapper,
  OverlayFormInnerContentExtendedWidth,
} from 'rootstrap/components-old/forms/form-overlay';
import { ScreeningQuestions } from './screening-questions/screening-questions';
import { ConsentSection } from './consent-section/consent-section';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { BeneficiarySummary, BeneficiarySummaryType } from 'beneficiaries/views/beneficiary-summary';
import { EmbedFlowType } from 'App';
import { FullScreenModalFooterOverlay } from 'rootstrap/components/modal/styles/fullscreen-modal-styles';
import { SpacerLeft, SpacerRight } from 'rootstrap/components/modal/styles/form-overlay-styles';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { beneficiariesDescriptionWording } from './personal-details/utils/beneficiaries-description-wording';
import { PersonalDetailsStep } from './personal-details/views/stepped-full-screen-modal-personal-details';
import { QuoteDetailsStep } from './quotes/views/stepped-full-screen-modal-quote';
import { MixpanelEventNames, MixpanelStepNames, useMixpanel, useMixpanelTrack } from 'context/mix-pannel-context';
import { PolicyFlowSiteConfigIsLoading } from './page-mount/site-config-is-loading';
import { createEmbedSessionPrefillData } from 'embed-sessions/actions/create-embed-session-prefill-data';
import { useEmbedSessionContext } from 'rootstrap/components/tabs/hooks/embed-session-context';
import { getPrefillValuesFromPolicyholder } from './personal-details/utils/get-prefill-values-from-policyholder';
import { toOutputData } from 'rootstrap/components-old/root-schema-form/utils/output-data';
import { PolicyholderLookUpFormData } from './personal-details/views/unauthenticated-policyholder-lookup';
import _, { cloneDeep } from 'lodash';
import { IssuingSceneStepKeys } from './utils';

export interface GetSteps {
  setCurrentStepKey: (key: IssuingSceneStepKeys) => void;
  stepKey: IssuingSceneStepKeys;
}
export interface PolicyholderInputData {
  identification: {
    type: IdentificationType;
    number: any;
    country: any;
  };
  firstName: any;
  lastName: any;
  email: any;
  cellphone: any;
}

export interface Step<StepKey> {
  key: StepKey;
  onBackClick?: () => void | Promise<void>;
  onNextClick: () => void | Promise<void>;
  getHooks?: () => void;
  description: string;
  title?: string;
  isWide?: boolean;
  wideBody?: boolean;
  continueButtonProps?: {
    disabled?: boolean;
    isLoading?: boolean;
    isNextButtonHidden?: boolean;
  };
  body: () => ReactNode;
}

export const PolicyIssuingScene = (props: { productModule: ProductModule; prefillValues: PrefillValues }) => {
  const { prefillValues } = props;
  const { screeningQuestions, prePersonalDetailsConsent, prePaymentDetailsConsent } = prefillValues;
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { embedSessionId } = useEmbedSessionContext();

  const mixpanel = useMixpanel();
  const { auth, environment, organizationId } = embedParams;
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { stepCompletedTrack } = useMixpanelTrack();

  // Steps
  const [policyIssuingFlowStepOrder, setPolicyIssuingFlowStepOrder] = useState<
    Record<IssuingSceneStepKeys, number> | undefined
  >();
  const [firstStep, setFirstStep] = useState<IssuingSceneStepKeys>();

  // Screening questions
  const screeningQuestionsEnabled = Boolean(
    siteConfig &&
      siteConfig.quote.displayOptionalSections.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions &&
      siteConfig?.quote.wording.screeningQuestions.length > 0,
  );

  const [screeningQuestionsData, setScreeningQuestionsData] = useState<JSONObject | undefined>(screeningQuestions);

  const [screeningQuestionsValid, setScreeningQuestionsValid] = useState(
    !!screeningQuestions || !screeningQuestionsEnabled,
  );
  const [screeningQuestionsComplete, setScreeningQuestionsComplete] = useState(
    !!screeningQuestions || !screeningQuestionsEnabled,
  );
  const [screeningQuestionsModalIsOpen, setScreeningQuestionsModalIsOpen] = useState(false);

  // Quotes
  const [isLoadingCreateQuote, setLoadingCreateQuote] = useState<boolean>(false);
  const [quoteSchemaFormData, setQuoteSchemaFormData] = useState<JSONObject | undefined>();
  const [quoteSchemaFormDataPart1, setQuoteSchemaFormDataPart1] = useState<JSONObject | undefined>();
  const [quoteSchemaFormDataPart2, setQuoteSchemaFormDataPart2] = useState<JSONObject | undefined>();

  const [selectedQuote, setSelectedQuote] = useState<Quote | undefined>();
  const [quotePackages, setQuotePackages] = useState<Quote[] | undefined>();
  const [createQuoteError, setCreateQuoteError] = useState<any>(undefined);
  const [isQuoteCompleted, setIsQuoteCompleted] = useState<boolean>(false);
  const [isQuoteCompletedStep1, setIsQuoteCompletedStep1] = useState<boolean>(false);

  const [quoteToDisplayInProgressBar, setQuoteToDisplayInProgressBar] = useState<Quote | undefined>();
  useEffect(() => {
    if (selectedQuote && quotePackages) {
      const mostRecent = selectedQuote.createdAt >= quotePackages[0].createdAt ? selectedQuote : quotePackages[0];
      setQuoteToDisplayInProgressBar(mostRecent);
    } else {
      setQuoteToDisplayInProgressBar(selectedQuote || (quotePackages && quotePackages[0]));
    }
  }, [selectedQuote, quotePackages]);

  // Pre policyholder consent
  const [isPrePersonalDetailsConsentChecked, setIsPrePersonalDetailsConsentChecked] = useState<boolean>(
    !!prePersonalDetailsConsent,
  );

  // Personal details
  const [policyholder, setPolicyholder] = useState<Policyholder | undefined>();
  const [policyholderIsValid, setPolicyholderIsValid] = useState<boolean>(false);
  const [policyholderIsLoading, setPolicyholderIsLoading] = useState<boolean>(false);
  const [isPersonalDetailsCompleted, setIsPersonalDetailsCompleted] = useState<boolean>(false);
  const [policyholderLookupFormData, setPolicyholderLookupFormData] = useState<PolicyholderLookUpFormData>({});
  const [isCreatingPolicyholderFromPrefill, setIsCreatingPolicyholderFromPrefill] = useState(
    Boolean(siteConfig?.personalDetails.displayOptionalSections.skipOnPrefill),
  );

  // Applications
  const [application, setApplication] = useState<Application | undefined>();
  const [applicationSchemaFormData, setApplicationSchemaFormData] = useState<JSONObject | undefined>();
  const [createApplicationError, setCreateApplicationError] = useState<ApiError | undefined>();
  const [applicationIsLoading, setApplicationIsLoading] = useState<boolean>();
  const [isApplicationCompleted, setIsApplicationCompleted] = useState<boolean>(false);
  const [applicationIsValid, setApplicationIsValid] = useState<boolean>(false);

  // Pre payment consent
  const [isPrePaymentConsentChecked, setIsPrePaymentConsentChecked] = useState<boolean>(!!prePaymentDetailsConsent);
  const prePaymentConsentRequired = Boolean(
    siteConfig?.prePaymentCompliance &&
      siteConfig.prePaymentCompliance.displayOptionalSections.displayPrePaymentCompliance,
  );

  // Beneficiaries
  const [isBeneficiariesValid, setIsBeneficiariesValid] = useState<boolean>(true);
  const [isLoadingBeneficiaries, setIsLoadingBeneficiaries] = useState<boolean>(false);

  // Payments
  const [paymentMethodIsValid, setPaymentMethodIsValid] = useState<boolean>(false);
  const [paymentAccountPermission, setPaymentAccountPermission] = useState<boolean>(false);
  const [isLoadingCreatePaymentMethod] = useState<boolean>(false);
  const [declarationCompleted, setDeclarationCompleted] = useState<boolean>(false);
  const skipPaymentMethod = siteConfig?.payment.displayOptionalSections.displayPaymentMethod === false;

  // Button refs
  const onNextClickedRef = useRef<any>();
  const createQuoteButtonRef = useRef<any>();
  const createPolicyholderSubmitButtonRef = useRef<any>();
  const applicationSchemaFormSubmitButtonRef = useRef<any>();
  const beneficiaryFormSubmitButtonRef = useRef<any>();
  const paymentMethodSubmitButtonRef = useRef<any>();

  // Root schema refs
  const rootSchemaFormQuoteRef = useRef<any>();

  const {
    result: productModuleAndSchema,
    isLoading: isLoadingProductModule,
    error: productModuleError,
  } = usePromise(async () => {
    const { productModule } = props;

    if (!productModule.productModuleDefinition?.productModuleDefinitionId) {
      throw new Error('No product module definition exists on product module');
    }

    const productModuleDefinitionQuoteSchema = await getProductModuleDefinitionSchema({
      productModuleId: productModule.productModuleId,
      productDefinitionId: productModule.productModuleDefinition.productModuleDefinitionId,
      productModuleDefinitionSchemaId: productModule.productModuleDefinition?.quoteSchemaId,
      environment,
      auth,
      organizationId,
    });

    const productModuleDefinitionApplicationSchema = await getProductModuleDefinitionSchema({
      productModuleId: productModule.productModuleId,
      productDefinitionId: productModule.productModuleDefinition.productModuleDefinitionId,
      productModuleDefinitionSchemaId: productModule.productModuleDefinition?.applicationSchemaId,
      environment,
      auth,
      organizationId,
    });

    if (!productModuleDefinitionQuoteSchema) {
      throw new Error('No product module definition quote schema found');
    }

    const isMultiQuoteStep = !!productModuleDefinitionQuoteSchema.json.find(
      ({ sectionIndex }) => sectionIndex !== undefined,
    );

    setPolicyIssuingFlowStepOrder(getPolicyIssuingFlowStepOrder({ isMultiQuote: isMultiQuoteStep, siteConfig }));
    setFirstStep(getFirstStep({ siteConfig, isMultiQuote: isMultiQuoteStep }));

    return {
      productModuleDefinitionQuoteSchema,
      productModule,
      productModuleDefinitionApplicationSchema,
      isMultiQuote: isMultiQuoteStep,
    };
  }, []);

  useEffect(() => {
    if (siteConfig?.landing.displayOptionalSections.displayLandingPage === false) {
      if (isPersonalDetailsStartingStep) {
        if (
          siteConfig?.prePersonalDetailsCompliance?.displayOptionalSections.displayPrePersonalDetailsCompliance === true
        ) {
          return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.ConsentPrePersonalDetails);
        }
        return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.PersonalDetails);
      }

      if (siteConfig?.quote.displayOptionalSections.screeningQuestions === true) {
        return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.ScreeningQuestions);
      }
      if (isMultiQuote) {
        return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.SplitQuoteStep1);
      }

      return mixpanel.track(MixpanelEventNames.SessionInitiated, MixpanelStepNames.QuoteStep);
    }
  }, []);

  usePromise(async () => {
    if (embedSessionId && siteConfig?.settings.enableSessionPersistence) {
      const quoteSchema = productModuleAndSchema?.productModuleDefinitionQuoteSchema?.json || [];

      const quoteData = toOutputData({
        formData: isMultiQuote
          ? _.mergeWith(cloneDeep(quoteSchemaFormDataPart1), cloneDeep(quoteSchemaFormDataPart2))
          : quoteSchemaFormData || {},
        schema: quoteSchema,
      });

      const quote = quoteData ? quoteData : prefillValues.quote;

      const applicationSchema = productModuleAndSchema?.productModuleDefinitionApplicationSchema?.json || [];
      const application = applicationSchemaFormData
        ? toOutputData({ formData: applicationSchemaFormData || {}, schema: applicationSchema })
        : prefillValues.application;

      await createEmbedSessionPrefillData({
        auth,
        embedSessionId,
        environment,
        organizationId,
        embedSessionPrefillData: {
          screeningQuestions: screeningQuestionsData,
          quote,
          application,
          prePersonalDetailsConsent: isPrePersonalDetailsConsentChecked,
          personalDetails: getPrefillValuesFromPolicyholder({ policyholder }),
          prePaymentDetailsConsent: isPrePaymentConsentChecked,
          payment: undefined,
        },
      });
    }
  }, [
    screeningQuestionsData,
    quoteSchemaFormData,
    isPrePersonalDetailsConsentChecked,
    policyholder,
    applicationSchemaFormData,
    isPrePaymentConsentChecked,
    quoteSchemaFormDataPart2,
  ]);

  const onClose = () => {
    history.push(baseUrl);
  };

  const isPersonalDetailsStartingStep =
    siteConfig?.settings.issuingFlowStartingStep === ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails;

  const payment = siteConfig?.payment;
  const quote = siteConfig?.quote;
  const additionalDetails = siteConfig?.application;
  const header = siteConfig?.header;
  const prePaymentCompliance = siteConfig?.prePaymentCompliance;

  const screeningQuestionsTotal = siteConfig?.quote.wording.screeningQuestions
    ? siteConfig?.quote.wording.screeningQuestions.length
    : 0;

  const isMultiQuote = !!productModuleAndSchema?.isMultiQuote;

  const beneficiaries = props.productModule?.productModuleDefinition?.settings.beneficiaries;
  const minimumBeneficiaries = beneficiaries?.min as number | undefined;
  const maximumBeneficiaries = beneficiaries?.max as number | undefined;

  if (!policyIssuingFlowStepOrder) {
    return <PolicyFlowSiteConfigIsLoading />;
  }

  return (
    <MainLayout>
      <MainLayoutContent>
        <Content>
          <FlexContainer>
            <div className='section' id='summary'>
              <SteppedFullscreenModal
                title={header?.wording.title || ''}
                productModule={props.productModule}
                quotePackageDisplaySteps={[IssuingSceneStepKeys.QuoteInput, IssuingSceneStepKeys.QuoteInputMultiStep2]}
                headingSplashSteps={[
                  IssuingSceneStepKeys.PrePersonalDetailsConsent,
                  IssuingSceneStepKeys.Payment,
                  IssuingSceneStepKeys.PrePaymentConsent,
                ]}
                error={createApplicationError || productModuleError}
                onNextClickedRef={onNextClickedRef}
                firstStep={firstStep}
                isLoading={false}
                premiumDisplay={{
                  billingFrequency: quoteToDisplayInProgressBar?.billingFrequency,
                  currency: quoteToDisplayInProgressBar?.currency,
                  premiumValue: application?.monthlyPremium || quoteToDisplayInProgressBar?.suggestedPremium,
                  isLoading: isLoadingCreateQuote || applicationIsLoading,
                }}
                onClose={onClose}
                getSteps={(params: {
                  setCurrentStepKey: (key: SetStateAction<any | undefined>) => void;
                  setPriorStepKey: (key: SetStateAction<any | undefined>) => void;
                  getPriorStepKey: () => SetStateAction<any | undefined>;
                  onNextCompleted: (() => void | Promise<void>) | undefined;
                  onBackCompleted: (() => void | Promise<void>) | undefined;
                }) => {
                  return [
                    ...(isPersonalDetailsStartingStep
                      ? PersonalDetailsStep({
                          isCreatingPolicyholderFromPrefill,
                          setIsCreatingPolicyholderFromPrefill,
                          policyholderLookupFormData,
                          setPolicyholderLookupFormData: setPolicyholderLookupFormData,
                          applicationIsLoading,
                          applicationSchemaFormData,
                          createPolicyholderSubmitButtonRef,
                          getPriorStepKey: params.getPriorStepKey,
                          isLoadingProductModule,
                          isPersonalDetailsCompleted,
                          isPrePersonalDetailsConsentChecked,
                          onNextCompleted: params.onNextCompleted,
                          policyholder,
                          policyholderIsLoading,
                          policyholderIsValid,
                          prefillValues,
                          selectedQuote,
                          productModuleAndSchema,
                          quoteSchemaFormData,
                          setCurrentStepKey: params.setCurrentStepKey,
                          setPriorStepKey: params.setPriorStepKey,
                          setIsPersonalDetailsCompleted,
                          setIsPrePersonalDetailsConsentChecked,
                          setPolicyholder,
                          setPolicyholderIsLoading,
                          setPolicyholderIsValid,
                          stepOrder: policyIssuingFlowStepOrder,
                          isMultiQuote,
                        })
                      : []),
                    {
                      title: getWording({ wording: quote?.wording.screeningQuestionsDescription }),
                      stepName: DefaultStepKeys.ScreeningQuestions,
                      description: `Just answer these ${screeningQuestionsTotal} quick question${
                        screeningQuestionsTotal > 0 ? 's' : ''
                      }...`,
                      key: IssuingSceneStepKeys.ScreeningQuestions,
                      onBackClick: () => {
                        history.push(baseUrl);
                      },
                      body: () => (
                        <ScreeningQuestions
                          stepOrder={policyIssuingFlowStepOrder}
                          isLoading={isLoadingProductModule}
                          modalIsOpen={screeningQuestionsModalIsOpen}
                          setModalIsOpen={setScreeningQuestionsModalIsOpen}
                          prefillValues={prefillValues}
                          setCurrentStepKey={(currentStepKey) => params.setCurrentStepKey(currentStepKey)}
                          setScreeningQuestionsValid={setScreeningQuestionsValid}
                          screeningQuestionsData={screeningQuestionsData}
                          setScreeningQuestionsData={setScreeningQuestionsData}
                          setScreeningQuestionsComplete={setScreeningQuestionsComplete}
                          screeningQuestionsEnabled={screeningQuestionsEnabled}
                          onNextCompleted={() => null}
                          priorStepKey={params.getPriorStepKey()}
                          setStepProgress={(step) => params.setCurrentStepKey(step)}
                          setPriorStepKey={(step) => params.setPriorStepKey(step)}
                          step={IssuingSceneStepKeys.ScreeningQuestions}
                          isMultiQuoteStep={isMultiQuote}
                        />
                      ),
                      continueButtonProps: {
                        disabled: !screeningQuestionsComplete,
                        isNextButtonHidden: false,
                      },
                      onNextClick: () => {
                        if (!screeningQuestionsValid && screeningQuestionsComplete) {
                          setScreeningQuestionsModalIsOpen(true);
                        } else {
                          stepCompletedTrack({
                            stepName: MixpanelStepNames.ScreeningQuestions,
                          });
                          params.onNextCompleted && params.onNextCompleted();
                        }
                      },
                    },
                    ...QuoteDetailsStep({
                      ...params,
                      prefillValues,
                      isLoadingCreateQuote,
                      isQuoteCompleted,
                      isQuoteCompletedStep1,
                      productModuleAndSchema,
                      createQuoteButtonRef,
                      rootSchemaFormQuoteRef,
                      quoteSchemaFormData,
                      quoteSchemaFormDataPart1,
                      quoteSchemaFormDataPart2,
                      screeningQuestionsData,
                      selectedQuote,
                      setLoadingCreateQuote,
                      setIsQuoteCompleted,
                      setIsQuoteCompletedStep1,
                      setQuoteSchemaFormData,
                      setQuoteSchemaFormDataPart1,
                      setQuoteSchemaFormDataPart2,
                      setQuotePackages,
                      setCreateQuoteError,
                      isMultiQuote,
                    }),
                    ...(!isPersonalDetailsStartingStep
                      ? PersonalDetailsStep({
                          isCreatingPolicyholderFromPrefill,
                          setIsCreatingPolicyholderFromPrefill,
                          policyholderLookupFormData,
                          setPolicyholderLookupFormData,
                          isMultiQuote,
                          applicationIsLoading,
                          applicationSchemaFormData,
                          createPolicyholderSubmitButtonRef,
                          isLoadingProductModule,
                          isPersonalDetailsCompleted,
                          isPrePersonalDetailsConsentChecked,
                          policyholder,
                          policyholderIsLoading,
                          policyholderIsValid,
                          prefillValues,
                          productModuleAndSchema,
                          quoteSchemaFormData,
                          stepOrder: policyIssuingFlowStepOrder,
                          selectedQuote,
                          setCurrentStepKey: params.setCurrentStepKey,
                          setPriorStepKey: params.setPriorStepKey,
                          setIsPersonalDetailsCompleted,
                          setIsPrePersonalDetailsConsentChecked,
                          setPolicyholder,
                          setPolicyholderIsLoading,
                          setPolicyholderIsValid,
                          onNextCompleted: params.onNextCompleted,
                          getPriorStepKey: params.getPriorStepKey,
                        })
                      : []),
                    {
                      key: IssuingSceneStepKeys.Application,
                      stepName: DefaultStepKeys.AdditionalDetails,
                      title: additionalDetails?.wording.title,
                      description: getWording({ wording: additionalDetails?.wording.description }),
                      continueButtonProps: {
                        applicationIsLoading,
                        disabled: !!applicationIsLoading || !!createApplicationError || !applicationIsValid,
                        isNextButtonHidden: false,
                      },
                      onBackClick: () => {
                        setCreateApplicationError(undefined);
                      },
                      body: () => (
                        <CreateApplication
                          stepOrder={policyIssuingFlowStepOrder}
                          isMultiQuoteStep={!!productModuleAndSchema?.isMultiQuote}
                          prefillValues={prefillValues}
                          isLoading={!!applicationIsLoading}
                          applicationSchemaFormSubmitButtonRef={applicationSchemaFormSubmitButtonRef}
                          productModuleDefinition={props.productModule.productModuleDefinition}
                          setStepProgress={(step) => {
                            params.setCurrentStepKey(step);
                          }}
                          setApplicationIsValid={(applicationIsValid) => setApplicationIsValid(applicationIsValid)}
                          applicationSchemaFormData={{ ...prefillValues.application, applicationSchemaFormData }}
                          setApplicationSchemaFormData={(data) => setApplicationSchemaFormData(data)}
                          productModuleDefinitionApplicationSchema={
                            productModuleAndSchema?.productModuleDefinitionApplicationSchema
                          }
                          productModuleDefinitionQuoteSchema={
                            productModuleAndSchema?.productModuleDefinitionQuoteSchema
                          }
                          quoteSchemaFormData={
                            isMultiQuote
                              ? _.merge(cloneDeep(quoteSchemaFormDataPart1), cloneDeep(quoteSchemaFormDataPart2)) || {}
                              : quoteSchemaFormData || {}
                          }
                          setIsLoading={(isLoading) => setApplicationIsLoading(isLoading)}
                          policyholder={policyholder}
                          selectedQuote={selectedQuote}
                          application={application}
                          priorStepKey={params.getPriorStepKey()} //do not leave like this
                          setApplication={(application) => setApplication(application)}
                          setError={setCreateApplicationError}
                          onNextCompleted={() => params.onNextCompleted && params.onNextCompleted()}
                          isCompleted={isApplicationCompleted}
                        />
                      ),
                      onNextClick: async () => {
                        applicationSchemaFormSubmitButtonRef.current.click();
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.Application,
                        });
                        setIsApplicationCompleted(true);
                      },
                    },
                    {
                      key: IssuingSceneStepKeys.Beneficiaries,
                      title: getWording({ wording: siteConfig?.beneficiaries?.wording.title }),
                      stepName: DefaultStepKeys.Beneficiaries,
                      description: beneficiariesDescriptionWording({ minimumBeneficiaries, maximumBeneficiaries }),
                      continueButtonProps: {
                        isLoading: applicationIsLoading || isLoadingBeneficiaries,
                        disabled: !isBeneficiariesValid,
                      },
                      body: () => (
                        <BeneficiarySummary
                          type={BeneficiarySummaryType.Application}
                          policyholder={policyholder}
                          error={productModuleError}
                          setIsLoading={(isLoading) => setIsLoadingBeneficiaries(isLoading)}
                          linkedEntity={application}
                          productModule={productModuleAndSchema?.productModule}
                          setLinkedEntity={(application) => setApplication(application)}
                          setIsValid={(isBeneficiariesValid) => setIsBeneficiariesValid(isBeneficiariesValid)}
                          setStepProgress={(step) => {
                            params.onNextCompleted && params.onNextCompleted();
                            params.setCurrentStepKey(step);
                          }}
                          beneficiarySummarySceneType={EmbedFlowType.PolicyIssuing}
                          priorStepKey={params.getPriorStepKey()}
                          submitButtonRef={beneficiaryFormSubmitButtonRef}
                          isLoading={!!applicationIsLoading}
                          isMultiQuote={isMultiQuote}
                        />
                      ),
                      onNextClick: async () => {
                        beneficiaryFormSubmitButtonRef.current.click();
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.Beneficiaries,
                        });
                      },
                    },
                    {
                      key: IssuingSceneStepKeys.PrePaymentConsent,
                      stepName: DefaultStepKeys.PrePaymentDetailsConsent,
                      title: prePaymentCompliance?.wording.title,
                      description: getWording({ wording: prePaymentCompliance?.wording.description }),
                      onBackClick: () => undefined,
                      continueButtonProps: {
                        isLoading: false,
                        disabled: !isPrePaymentConsentChecked,
                        isNextButtonHidden: false,
                      },
                      body: () => (
                        <ConsentSection
                          stepOrder={policyIssuingFlowStepOrder}
                          isRequired={prePaymentConsentRequired}
                          setStepProgress={(step) => params.setCurrentStepKey(step)}
                          setPriorStepKey={(step) => params.setPriorStepKey(step)}
                          priorStepKey={params.getPriorStepKey()}
                          step={IssuingSceneStepKeys.PrePaymentConsent}
                          content={getWording({ wording: prePaymentCompliance?.wording.content })}
                          setIsConsentChecked={(isConsentChecked) => setIsPrePaymentConsentChecked(isConsentChecked)}
                          isConsentChecked={isPrePaymentConsentChecked}
                          isLoading={isLoadingProductModule}
                          isMultiQuote={isMultiQuote}
                        />
                      ),
                      onNextClick: async () => {
                        params.onNextCompleted && params.onNextCompleted();
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.ConsentPrePayment,
                        });
                      },
                    },
                    {
                      key: IssuingSceneStepKeys.Payment,
                      title: payment?.wording.title,
                      stepName: DefaultStepKeys.Payment,
                      description: getWording({ wording: payment?.wording.description }),
                      continueButtonProps: {
                        isLoading: applicationIsLoading || isLoadingCreatePaymentMethod,
                        disabled:
                          paymentAccountPermission ||
                          !paymentMethodIsValid ||
                          !declarationCompleted ||
                          isLoadingCreatePaymentMethod,
                        isNextButtonHidden: true,
                      },
                      body: () => (
                        <PaymentSection
                          skipPaymentMethod={skipPaymentMethod}
                          prefillValues={prefillValues}
                          debitAccountPermission={paymentAccountPermission}
                          policyholder={policyholder}
                          application={application}
                          productModule={productModuleAndSchema?.productModule}
                          productModuleDefinitionQuoteSchema={
                            productModuleAndSchema?.productModuleDefinitionQuoteSchema
                          }
                          setAccountPermission={setPaymentAccountPermission}
                          setPaymentMethodIsValid={(isValid) => setPaymentMethodIsValid(isValid)}
                          isLoading={isLoadingCreatePaymentMethod}
                          setDeclarationCompleted={(isCompleted) => setDeclarationCompleted(isCompleted)}
                          onNextCompleted={() => {
                            stepCompletedTrack({
                              stepName: MixpanelStepNames.Payment,
                            });
                            params.onNextCompleted && params.onNextCompleted();
                          }}
                        />
                      ),
                      onNextClick: async () => {
                        stepCompletedTrack({
                          stepName: MixpanelStepNames.Payment,
                        });
                        paymentMethodSubmitButtonRef.current.click();
                      },
                    },
                  ];
                }}
                finalStepButtonContent='Pay now'
                CustomActionBarContent={(step: any) => <></>}
                footer={(step) => (
                  <>
                    {step ===
                      (isMultiQuote ? IssuingSceneStepKeys.QuoteInputMultiStep2 : IssuingSceneStepKeys.QuoteInput) && (
                      <FullScreenModalFooterOverlay fullWidthOnMobile siteConfig={siteConfig}>
                        <SpacerLeft />
                        <div className='main'>
                          <QuoteStepActionBar
                            setSelectedQuote={setSelectedQuote}
                            quotes={quotePackages}
                            step={IssuingSceneStepKeys.QuoteInput}
                            error={createQuoteError}
                            isLoadingCreateQuote={isLoadingCreateQuote}
                            createQuoteButtonRef={createQuoteButtonRef}
                            onNextClickedRef={onNextClickedRef}
                            productModuleDefinition={props.productModule.productModuleDefinition}
                            rootSchemaFormQuoteRef={rootSchemaFormQuoteRef}
                          />
                        </div>
                        <SpacerRight />
                      </FullScreenModalFooterOverlay>
                    )}
                    <InsurerDisclaimerFooter siteConfig={siteConfig} />
                  </>
                )}
              />
            </div>
          </FlexContainer>
        </Content>
      </MainLayoutContent>
    </MainLayout>
  );
};

export const InsurerDisclaimerFooter = (params: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null }) => {
  const { siteConfig } = params;
  return (
    <FormOverlayFooterWrapper siteConfig={siteConfig} className='overlay-form-footer'>
      <div className='spacer-left' />
      <OverlayFormInnerContentExtendedWidth className={'overlay-form-inner-content-extended-width'}>
        <FormWrapperStyle>
          <InsurerDisclaimerContainer />
        </FormWrapperStyle>
      </OverlayFormInnerContentExtendedWidth>
      <div className='spacer-right' />
    </FormOverlayFooterWrapper>
  );
};

export const stepOrders = (params: { isMultiQuoteStep: boolean }) => {
  const {
    Application,
    Beneficiaries,
    Payment,
    PersonalDetails,
    PrePaymentConsent,
    PrePersonalDetailsConsent,
    QuoteInput,
    QuoteInputMultiStep1,
    QuoteInputMultiStep2,
    ScreeningQuestions,
  } = IssuingSceneStepKeys;

  const quoteInputStep = params.isMultiQuoteStep ? [QuoteInputMultiStep1, QuoteInputMultiStep2] : [QuoteInput];

  return {
    default: [
      ScreeningQuestions,
      ...quoteInputStep,
      PrePersonalDetailsConsent,
      PersonalDetails,
      Application,
      Beneficiaries,
      PrePaymentConsent,
      Payment,
    ],
    [ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails]: [
      PrePersonalDetailsConsent,
      PersonalDetails,
      ScreeningQuestions,
      ...quoteInputStep,
      Application,
      Beneficiaries,
      PrePaymentConsent,
      Payment,
    ],
  };
};

export const getPolicyIssuingFlowStepOrder = (params: {
  isMultiQuote: boolean;
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}) => {
  const { isMultiQuote: isMultiQuoteStep, siteConfig } = params;
  const stepOrder = stepOrders({ isMultiQuoteStep })[
    siteConfig?.settings.issuingFlowStartingStep || ProductModuleEmbedConfigIssuingFlowStartingStep.Default
  ];

  return stepOrder.reduce(
    (acc, step, index) => ({
      ...acc,
      [step]: index,
    }),
    {} as Record<IssuingSceneStepKeys, number>,
  );
};

export const GetPolicyIssuingFlowStepFromStepIndex = (params: {
  issuingFlowStartingStep?: ProductModuleEmbedConfigIssuingFlowStartingStep | '';
  isMultiQuoteStep: boolean;
  step: number;
}) => {
  const { issuingFlowStartingStep, step, isMultiQuoteStep } = params;
  return stepOrders({ isMultiQuoteStep })[
    issuingFlowStartingStep || ProductModuleEmbedConfigIssuingFlowStartingStep.Default
  ][step];
};

const getFirstStep = (params: { siteConfig: ProductModuleDefinitionEmbeddedConfig | null; isMultiQuote: boolean }) => {
  const { siteConfig, isMultiQuote } = params;

  const isPersonalDetailsStartingStep =
    siteConfig?.settings.issuingFlowStartingStep === ProductModuleEmbedConfigIssuingFlowStartingStep.PersonalDetails;

  if (isPersonalDetailsStartingStep) {
    return siteConfig?.prePersonalDetailsCompliance?.displayOptionalSections.displayPrePersonalDetailsCompliance
      ? IssuingSceneStepKeys.PrePersonalDetailsConsent
      : IssuingSceneStepKeys.PersonalDetails;
  }

  return siteConfig?.quote.displayOptionalSections.screeningQuestions
    ? IssuingSceneStepKeys.ScreeningQuestions
    : isMultiQuote
    ? IssuingSceneStepKeys.QuoteInputMultiStep1
    : IssuingSceneStepKeys.QuoteInput;
};
